import Paper from "@material-ui/core/Paper";
import {useDispatch, useSelector} from "react-redux";
import React, {useEffect, useState} from "react";
import Grid from "@material-ui/core/Grid";
import MUIDataTable from "mui-datatables";
import {makeStyles} from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import Fab from "@material-ui/core/Fab";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import {addBank, fetchBanks} from "../../redux/actions/prestActions";

function createData(bank) {
    return [
        bank.id,
        bank.code,
        bank.name
    ]
}

export default function Bank() {
    const dispatch=useDispatch();
    useEffect(()=>{
        dispatch(fetchBanks());
    },[])
    return (
        <Paper>
            <BankTable/>
            <BankAdd/>
        </Paper>);
}

function BankTable() {
    const {banks} = useSelector(state => state.prestataires);
    const columns = [
        {
            name: "id", label: "Id",
        },
        {
            name: "code", label: "Code",
        },
        {
            name: "name", label: "desigantion",
        }
        ,
        {
            name: "Actions",
            options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                    // const user = banks.find(o => o.email === tableMeta.rowData[2]);
                    // return (
                    //     <UpdateUser user={user}/>
                    // );
                }
            }
        }
    ];
    const [data, setData] = useState([]);
    useEffect(() => {
        banks && setData(banks.map(o => createData(o)));
    }, [banks]);
    const options = {
        pagination: false,
        filterType: "dropdown",
        responsive: "scroll",
        print: false
    };
    return (
        <Paper>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <MUIDataTable
                        title={"Liste des Banques "}
                        data={data}
                        columns={columns}
                        options={options}
                    />
                </Grid>
            </Grid>
        </Paper>
    )
}

const useStyles = makeStyles((theme) => ({
    fab: {
        margin: theme.spacing(2),
    },
    absolute: {
        position: 'absolute',
        bottom: theme.spacing(2),
        right: theme.spacing(3),
    },
}));

const fields = [
    {generated: true, id: "id", label: "Id", xs: 6},
    {generated: false, id: "code", label: "Code", xs: 6},
    {generated: false, id: "name", label: "designation", xs: 6}
]
function BankAdd() {
    const [isValid, setValid] = useState(false);
    const classes = useStyles();
    const [state, setState] = React.useState({
        id: "",
        code: "",
        name: ""
    });
    const [open, setOpen] = React.useState(false);
    const dispatch = useDispatch();
    const handleChange = (event) => {
        setState({
            ...state,
            [event.target.name]: event.target.value
        });
    };
    const handleClickOpen = () => {
        setOpen(true);
        setState({
            id: "",
            code: "",
            name: ""
        });
    }

    useEffect(() => {
        //  const x = state.rs.length > 3 & state.adresse.length > 3 & state.rc.length > 3 & state.ice.length > 3 & state.rib.length > 3 & state.bank != null & state.agence.length > 3 & state.responsable.length > 3
        setValid(true);
    }, [state]);
    const handleClose = () => {
        setOpen(false);
    };

    const handleDone = () => {
        dispatch(addBank(state));
        setOpen(false);
    }
    return (<div>

        <Tooltip title="Add" aria-label="add" onClick={handleClickOpen}>
            <Fab color="secondary" className={classes.absolute}>
                CRÉER
            </Fab>
        </Tooltip>

        <Dialog open={open}
                onClose={handleClose}
                maxWidth={"lg"}
                fullWidth
                aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Nouvelle banque</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Remplir les informations suivantes
                </DialogContentText>
                <Grid container spacing={3} justify={"center"}>
                    {
                        fields.map(o => {
                            return (!o.generated) && (
                                <Grid key={o.id} item xs={o.xs}>
                                    <FormControl fullWidth>
                                        <TextField
                                            InputProps={{
                                                readOnly: o.generated,
                                            }}
                                            onChange={handleChange}
                                            fullWidth
                                            id={o.id}
                                            name={o.id}
                                            label={o.label}
                                            value={state[o.id]}
                                            variant="outlined"
                                        />
                                    </FormControl>
                                </Grid>
                            );
                        })}

                </Grid>

            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Annuler
                </Button>
                <Button
                    onClick={handleDone}
                    color="primary"
                    variant={"contained"}
                    disabled={!isValid}>
                    Sauvgarder
                </Button>
            </DialogActions>
        </Dialog>
    </div>);
}
