import React, {useEffect} from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from "@material-ui/core/Grid";
import {useDispatch, useSelector} from "react-redux";
import FormControl from "@material-ui/core/FormControl";
import {addYear} from "../redux/actions/budgetActions";


export default function YearAddButton() {
    const [open, setOpen] = React.useState(false);
    const {years} = useSelector(state => state.budgets);
    const minYear = years.length > 0 ? years.reduce(function (a, b) {
        return Math.max(a, b);
    }) + 1 : 2022;

    const [state, setState] = React.useState(minYear);
    useEffect(() => {
        setState(minYear);
    }, [years, open])
    const dispatch = useDispatch();
    const handleChange = (event) => {
        setState(event.target.value);
    };
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    const handleDone = () => {
        dispatch(addYear(state));
        handleClose();
    }
    return (<div>

        <Button variant={"outlined"} onClick={handleClickOpen}>Ajouter</Button>
        <Dialog open={open}
                onClose={handleClose}
                maxWidth={"lg"}
                fullWidth
                aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Ajouter une nouveauvelle année</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Saisez la nouvelle annee que vous voullez ajouter
                </DialogContentText>
                <Grid container spacing={3} justify={"center"}>
                    <Grid item xs={5}>
                        <FormControl fullWidth>
                            <TextField
                                InputProps={{
                                    inputProps: {
                                        min: minYear,
                                        max: minYear + 50,
                                        onKeyDown: (event) => {
                                            event.preventDefault();
                                        }
                                    }
                                }}
                                onChange={handleChange}
                                type={"number"}
                                fullWidth
                                id="montant"
                                name="montant"
                                label="Année"
                                value={state}
                                variant="outlined"
                            />
                        </FormControl>
                    </Grid>
                </Grid>

            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Annuler
                </Button>
                <Button onClick={handleDone} color="primary" variant={"contained"}>
                    Ajouter
                </Button>
            </DialogActions>
        </Dialog>
    </div>);
}