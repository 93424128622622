import {ME_BASE_URL, PWD_BASE_URL} from "../../appConfig/apiConfig";
import axios from "axios";
import {onError, onFetch} from "./prestActions";
import {FETCH_BASE_URL_SUCCESS} from "./constants";
import {getAuth} from "./budgetActions";
import {fetchUsers} from "./userActions";


export function login(email, password) {
    return async dispatch => {
        onFetch(true);

        function onSuccess(result) {
            return dispatch({
                type: "LOGIN/SUCCESS",
                data: {token: password, email: email, role: result.data.role, user: result.data}
            });
        }

        await axios.get(ME_BASE_URL, {
            auth: {
                username: email, password: password
            }
        })
            .then(result => {
                localStorage.setItem("user", JSON.stringify({
                    user: result.data, username: email, password: password, role: result.data.role
                }))
                return onSuccess(result)
            })
            .catch(error => {
                if (localStorage.getItem("user") != null) {
                    localStorage.removeItem("user")
                }
                console.log(error);
                return onError(error)
            })
            .finally(() => {
                onFetch(false)
            });
    }
}

export function logout() {
    localStorage.removeItem("user");
    return {type: "LOGOUT/SUCCESS"};
}

export function sendResetEmail(email) {
    return async dispatch => {
        function onSuccess(result) {
            return {type: "SEND_RESET/SUCCESS"};
        }

        onFetch(true);
        try {
            const result = await axios.post(PWD_BASE_URL + "/forget", null, {
                params: {
                    email
                }
            });
            onFetch(false);
            return onSuccess(result);
        } catch (error) {
            onFetch(false);
            onSuccess();
            return onError(error);
        }
    }
}

export function sendVerificationEmail(email) {
    /*
      HERE YOU NEED TO MAKE A CALL TO YOUR API TO SEND AN EMAIL WITH A VERIFICATION CODE.
      WE FAKE THIS AND ASSUME SUCCESS..
    */
    return {type: "SEND_VERIFICATION/SUCCESS"};
}

export function sendVerificationPhone(phone) {
    /*
      HERE YOU NEED TO MAKE A CALL TO YOUR API TO SEND AN EMAIL WITH A VERIFICATION CODE.
      WE FAKE THIS AND ASSUME SUCCESS..
    */
    return {type: "SEND_VERIFICATION/SUCCESS"};
}

export function verifyEmailAddress(code) {
    /*
      HERE YOU NEED TO MAKE A CALL TO YOUR API TO MARK THE USER'S EMAIL ADDRESS AS VERIFIED.
      WE FAKE THIS AND ASSUME SUCCESS..
    */
    return {type: "VERIFY_EMAIL/SUCCESS"};
}

export function changePassword(email, newPassword, code) {
    return async dispatch => {
        function onSuccess(result) {
            dispatch(login(email, newPassword));
            return result;
        }

        onFetch(true);
        try {
            const result = await axios.post(PWD_BASE_URL + "/reset", null, {
                params: {
                    email,
                    code,
                    newPassword
                }
            });
            onFetch(false);
            return onSuccess(result);
        } catch (error) {
            onFetch(false);
            onSuccess();
            return onError(error);
        }
    }
}

export function editePassword(email, newPassword) {
    return async dispatch => {
        function onSuccess(result) {
            dispatch(fetchUsers());
            return result;
        }

        onFetch(true);
        try {
            const result = await axios.post(PWD_BASE_URL + "/edit", null, {
                params: {
                    email,
                    newPassword
                }
            });
            onFetch(false);
            return onSuccess(result);
        } catch (error) {
            onFetch(false);
            onSuccess();
            return onError(error);
        }
    }
}

export function createAccount(userObject) {
    /*
      HERE YOU NEED TO MAKE A CALL TO YOUR API TO REGISTER THE USER
      WE FAKE THIS AND ASSUME A SUCCESSFUL RESPONSE FROM THE SERVER
      YOU WILL NEED TO HANDLE THE CASES FOR FAIL AS WELL AS ERRORS
    */
    return {type: "CREATE_ACCOUNT/SUCCESS"};
}

export function fetchBaseUrl() {
    return async dispatch => {
        onFetch(true);

        function onSuccess(result) {
            return dispatch({type: FETCH_BASE_URL_SUCCESS, payload: result.data});
        }

        await axios.get("configs.json")
            .then(result => {
                return onSuccess(result)
            })
            .catch(error => {
                return onError(error)
            })
            .finally(() => {
                onFetch(false)
            });
    }
}

export const handleDownload = (url, filename) => {
    const link = document.createElement("a");
    link.target = "_blank";
    link.download = filename
    axios
        .get(url, {
            responseType: "blob", auth: getAuth().auth
        })
        .then((res) => {
            link.href = URL.createObjectURL(
                new Blob([res.data], {
                    type: "application/octet-stream"
                })
            );
            link.click();
        });
};


