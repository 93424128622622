import React from "react";
import {Redirect, Route} from "react-router-dom";

export function PublicRoute({component: Component, canActivate, ...rest}) {
    return (<Route
        {...rest}
        render={props =>
            !(canActivate && canActivate()) ? (
                <Component {...props} />
            ) : (
                <Redirect
                    to={{pathname: "/", state: {from: props.location}}}
                />
            )
        }
    />)
}

const PrivateRoute = ({component: Component, canActivate, ...rest}) => (
    <Route
        {...rest}
        render={props =>
            canActivate && canActivate() ? (
                <Component {...props} />
            ) : (
                <Redirect
                    to={{pathname: "/login", state: {from: props.location}}}
                />
            )
        }
    />
);

export default PrivateRoute;
