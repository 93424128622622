import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import Grid from "@material-ui/core/Grid";
import {FormControl, InputLabel, MenuItem, Select} from "@material-ui/core";
import {fetchTypes} from "../../redux/actions/depenseActions";

export default function TypeOptions({handleChange, typeNature}) {
    console.log(typeNature);
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(fetchTypes());
    }, []);
    const list = useSelector(state => state.depenses.types);

    const [types, setTypes] = useState([]);
    const [type, setType] = useState(null);
    const [groups, setGroups] = useState([]);
    const [group, setGroup] = useState(null);
    const [nature, setNature] = useState(null);
    const [natures, setNatures] = useState([]);
    useEffect(() => {
        const ts = list.filter(o => o.name === "type");
        setTypes(ts)
        setType(ts[0])
    }, [list]);
    useEffect(() => {
        if (type) {
            const gs = list.filter(o => o.name === "section" && o.type.id === type.id);
            setGroups(gs);
            setGroup(gs[0]);
        }
    }, [type]);
    useEffect(() => {
        if (group) {
            const ns = list.filter(o => o.name === "nature" && o.section.id === group.id);
            setNatures(ns);
            setNature(ns[0])
        }
    }, [group,type]);
    useEffect(() => {
        const na = list.filter(o => o.id === typeNature)[0]
        if (na && (!nature || typeNature !== nature.id)) {
            setType(list.filter(o => o.id === na.section.type.id)[0]);
            setGroup(list.filter(o => o.id === na.section.id)[0]);
            setNature(na);
        }
    }, [typeNature]);
    useEffect(() => {
        nature && nature.id !== typeNature && handleChange(nature)
    }, [nature]);
    return (
        <Grid container spacing={3} justify={"space-between"}>
            <Grid item xs={4}>
                <FormControl fullWidth variant={"outlined"} margin={"dense"}>
                    <InputLabel id="demo-simple-select-helper-label">Type</InputLabel>
                    <Select
                        labelId="demo-simple-select-helper-label"
                        id="depenseType"
                        name="depenseType"
                        onChange={(e) => setType(e.target.value)}
                        value={type}
                    >
                        {types.map(o => (<MenuItem value={o}>{o.label}</MenuItem>))}
                    </Select>
                </FormControl>
            </Grid>
            {groups && groups.length > 1 && (
                <Grid item xs={4}>
                    <FormControl fullWidth variant={"outlined"} margin={"dense"}>
                        <InputLabel id="demo-simple-select-helper-label">Section</InputLabel>
                        <Select
                            labelId="demo-simple-select-helper-label"
                            id="typeGroup"
                            name="typeGroup"
                            onChange={(event) => {
                                setGroup(event.target.value)
                            }}
                            value={group}
                        >
                            {groups.map(o => (<MenuItem value={o}>{o.label}</MenuItem>))}
                        </Select>
                    </FormControl>
                </Grid>)}
            {natures && natures.length > 0 && (
                <Grid item xs={4}>
                    <FormControl fullWidth variant={"outlined"} margin={"dense"}>
                        <InputLabel id="demo-simple-select-helper-label">Nature</InputLabel>
                        <Select
                            labelId="demo-simple-select-helper-label"
                            id="typeNature"
                            name="typeNature"
                            onChange={(e) => setNature(e.target.value)}
                            value={nature}
                        >
                            {natures.map(o => (<MenuItem value={o}>{o.label}</MenuItem>))}
                        </Select>
                    </FormControl>
                </Grid>
            )}
        </Grid>
    )
}

export function MarcheTypeOptions({handleChange, type}) {
    const list = useSelector(state => state.depenses.marcheTypes);
}
