import React, {useEffect, useState} from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import {useSelector} from "react-redux";
import YearSelectOption from "./YearSelectOption";
import RubricTypeSelectOption from "./RubricTypeSelectOption";

export default function SelectRubric({setRubric}) {
    const [state, setState] = React.useState({
        idRubric: null,
        idSubRubric: null,
        idDesignation: null
    });
    const [open, setOpen] = React.useState(false);
    const rows = useSelector(State => State.budgets.list);
    const [rubrics, setRubrics] = useState([]);
    const [subRubrics, setSubRubrics] = useState([]);
    const [designations, setDesignations] = useState([]);
    useEffect(() => {
        setRubrics(rows.filter(o => o.parentID === 0))
    }, [rows]);
    useEffect(() => {
        setSubRubrics(rows.filter(o => o.parentID === state.idRubric))
    }, [state.idRubric]);
    useEffect(() => {
        setDesignations(rows.filter(o => o.parentID === state.idSubRubric))
    }, [state.idSubRubric]);
    const handleChange = (event) => {
        setState({
            ...state,
            [event.target.name]: event.target.value
        });
    };

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
        setState({
            idRubric: null,
            idSubRubric: null,
            idDesignation: null
        });
    };
    const handleDone = () => {
        let rub;
        if (state.idRubric === null)
            rub = null
        else if (state.idSubRubric === null)
            rub = state.idRubric
        else if (state.idDesignation === null)
            rub = state.idSubRubric
        else
            rub = state.idDesignation
        setRubric(rub);
        console.log(rub);
        handleClose();
    }
    return (<Grid>
        <Button variant="outlined" color="primary" size={"small"} fullWidth onClick={handleClickOpen}>
            Selectionner
        </Button>
        <Dialog open={open}
                onClose={handleClose}
                maxWidth={"md"}
                fullWidth
                aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Selectionner rubrique</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Selectionner la rubrique de budget
                </DialogContentText>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Grid container>
                            <Grid item xs={12}>
                                <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                        <YearSelectOption/>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <RubricTypeSelectOption/>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl
                                    fullWidth>
                                    <InputLabel id="demo-simple-select-helper-label">Rubrique</InputLabel>
                                    <Select
                                        fullWidth
                                        labelId="demo-simple-select-helper-label"
                                        id="idRubric"
                                        name="idRubric"
                                        value={state.idRubric}
                                        onChange={handleChange}
                                    >
                                        <MenuItem value={null}> - </MenuItem>
                                        {rubrics.map(o => (<MenuItem value={o.id}>{o.name}</MenuItem>))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl
                                    fullWidth>
                                    <InputLabel id="demo-simple-select-helper-label">Sous rubrique</InputLabel>
                                    <Select
                                        fullWidth
                                        labelId="demo-simple-select-helper-label"
                                        id="idSubRubric"
                                        name="idSubRubric"
                                        value={state.idSubRubric}
                                        onChange={handleChange}
                                    >
                                        <MenuItem value={null}> - </MenuItem>
                                        {subRubrics.map(o => (<MenuItem value={o.id}>{o.name}</MenuItem>))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-helper-label">Designations</InputLabel>
                                    <Select
                                        fullWidth
                                        labelId="demo-simple-select-helper-label"
                                        id="idDesignation"
                                        name="idDesignation"
                                        value={state.idDesignation}
                                        onChange={handleChange}
                                    >
                                        <MenuItem value={null}> - </MenuItem>
                                        {designations.map(o => (<MenuItem value={o.id}>{o.name}</MenuItem>))}
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Annuler
                </Button>
                <Button onClick={handleDone} color="primary" variant={"contained"}>
                    Selectionner
                </Button>
            </DialogActions>
        </Dialog>
    </Grid>);
}
