import {Paper, TextField} from "@material-ui/core";
import Header from "../../components/general/Header";
import Grid from "@material-ui/core/Grid";
import {useDispatch, useSelector} from "react-redux";
import * as React from "react";
import {useEffect, useState} from "react";
import Box from "@material-ui/core/Box";
import {ArgumentAxis, Chart, Legend, Title, ValueAxis} from "@devexpress/dx-react-chart-material-ui";
import {Animation, BarSeries, SplineSeries} from "@devexpress/dx-react-chart";
import {withStyles} from "@material-ui/core/styles";
import {fetchPaymentsReport} from "../../redux/actions/reportingAction";
import {floatFormat, NumberFormatter} from "../../appConfig/chiffres";
import SelectRubric from "../../components/SelectRubric";
import SituationBudget from "../../components/SituationBudget";

export function Reporting() {
    return (
        <Paper>
            <Header name={"Dashboard et Reporting"}/>
            <Grid container spacing={3} justify={"center"}>
                <Grid item xs={12}>
                    {/*<YearChart/>*/}
                    <SituationBudget/>
                </Grid>
            </Grid>
        </Paper>
    )
}

const titleStyles = {
    title: {
        whiteSpace: "pre"
    }
};
const TitleText = withStyles(titleStyles)(({classes, ...props}) => (
    <Title.Text {...props} className={classes.title}/>
));
const legendStyles = () => ({
    root: {
        display: "flex",
        margin: "auto",
        flexDirection: "row"
    }
});
const legendLabelStyles = (theme) => ({
    label: {
        paddingTop: theme.spacing(1),
        whiteSpace: "nowrap"
    }
});
const legendItemStyles = () => ({
    item: {
        flexDirection: "column"
    }
});

const legendRootBase = ({classes, ...restProps}) => (
    <Legend.Root {...restProps} className={classes.root}/>
);
const legendLabelBase = ({classes, ...restProps}) => (
    <Legend.Label className={classes.label} {...restProps} />
);
const legendItemBase = ({classes, ...restProps}) => (
    <Legend.Item className={classes.item} {...restProps} />
);
const Root = withStyles(legendStyles, {name: "LegendRoot"})(legendRootBase);
const Label = withStyles(legendLabelStyles, {name: "LegendLabel"})(
    legendLabelBase
);
const Item = withStyles(legendItemStyles, {name: "LegendItem"})(
    legendItemBase
);

export function YearChart() {
    const rub = useSelector(state => state.budgets.list);
    const payments = useSelector(state => state.reporting.payments)
    const dispatch = useDispatch();
    const [state, setState] = useState({
        depense: undefined
    });
    const [rubric, setRubric] = useState(null);
    useEffect(() => {
        if (state.rubric == null) {
            setRubric(null);
        }
        state.rubric && rub && rub.length && setRubric(rub.filter(o => o.id === state.rubric)[0]);
    }, [state, rub]);
    useEffect(() => {
        dispatch(fetchPaymentsReport(2022, rubric == null ? 0 : rubric.id))
    }, [rubric])
    return (
        <Box>
            <Grid container>
                <Grid item xs={12}>
                    <Grid container spacing={3}>
                        <Grid item xs={6}>
                            <TextField
                                label={"Rubric"}
                                fullWidth
                                margin={"dense"}
                                variant={"outlined"}
                                value={rubric ? rubric.rubricType + " " + rubric.year + " : " + rubric.name : ""}
                                InputProps={{
                                    endAdornment: (
                                        <React.Fragment>
                                            <SelectRubric setRubric={(value) => {
                                                setState({...state, rubric: value});
                                            }}/>
                                        </React.Fragment>
                                    ),
                                }}/>
                        </Grid>
                        <Grid item xs={2}>
                            <TextField
                                label={"Credit disponible"}
                                fullWidth
                                margin={"dense"}
                                variant={"outlined"}
                                value={floatFormat(rubric ? rubric.totalSold - rubric.totalEngaged : 0)}/>
                        </Grid>
                        <Grid item xs={2}>
                            <TextField
                                label={"Credit engagé"}
                                fullWidth
                                margin={"dense"}
                                variant={"outlined"}
                                value={floatFormat(rubric ? rubric.totalEngaged : 0)}/>
                        </Grid>
                        <Grid item xs={2}>
                            <TextField
                                label={"Credit Total en DH"}
                                fullWidth
                                margin={"dense"}
                                variant={"outlined"}
                                value={floatFormat(rubric ? rubric.totalSold : 0)}/>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12}>
                    <Chart data={payments}>
                        <ArgumentAxis/>
                        <ValueAxis showLine showTicks/>
                        <BarSeries name="Paiements" valueField="payment" argumentField="month"/>
                        <SplineSeries name="Budget" valueField="budget" argumentField="month"/>
                        <Legend
                            position="bottom"
                            rootComponent={Root}
                            itemComponent={Item}
                            labelComponent={Label}
                        />
                        <Title
                            text={``}
                            textComponent={TitleText}
                        />
                        <Animation/>
                    </Chart>
                </Grid>
            </Grid>
        </Box>
    )
}
