import React, {useEffect, useState} from "react";
import Paper from "@material-ui/core/Paper";
import {Link, useParams} from 'react-router-dom';
import {useDispatch, useSelector} from "react-redux";
import {Button, Grid} from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import {KeyboardDatePicker, MuiPickersUtilsProvider,} from '@material-ui/pickers';
import Box from "@material-ui/core/Box";
import PrestSelectOptions from "../components/prest/PrestSelectOptions";
import {PrestSearchButton} from "../components/prest/PrestSearchButton";
import Bordereau from "../components/depense/Bordereau";
import Modality from "../components/depense/Modality";
import {fetchPRESTs} from "../redux/actions/prestActions";
import TypeOptions from "../components/depense/TypeOptions";
import {saveDep, updateDep} from "../redux/actions/depenseActions";
import {DEPENSE_BASE_URL} from "../appConfig/apiConfig";
import {floatFormat, trans} from "../appConfig/chiffres";
import {handleDownload} from "../redux/actions/actions";

export default function DepenseDetailsPage() {
    const [articles, setArticlees] = useState([]);
    const [type, setType] = useState();
    const [depense, setDepense] = useState(null);
    const [title, setTitle] = useState("");
    const [isNew, setNew] = useState(false);
    const today = new Date();
    const [firstDay, setFirstDay] = useState(new Date());
    useEffect(() => {
        const lastOne = type && type.lastOne && new Date(type.lastOne);
        if (!lastOne || lastOne.getFullYear() < today.getFullYear()) {
            const day = today;
            day.setDate(1);
            day.setMonth(0);
            setFirstDay(day);
        } else {
            setFirstDay(lastOne);
        }
    }, [type])
    const {id} = useParams();
    const dispatch = useDispatch();
    const depenses = useSelector(state => state.depenses.page.content);
    console.log(depense)

    const handleChange = (event) => {
        setDepense({
            ...depense,
            [event.target.name]: event.target.value
        });
    };
    const handleDateChange = (date, value) => {
        setDepense({
            ...depense,
            dateSignature: value
        });
    };
    const setPrest = (value) => {
        if (value !== null)
            setDepense({
                ...depense,
                prestataire: value
            })
    }
    const setArticles = (ar, num) => {
        setArticlees([...depense.articles.filter(o => o.bordereau !== num), ...ar]);
    }
    const saveEvent = () => {
        if (!isNew)
            dispatch(updateDep(depense));
        else
            dispatch(saveDep(depense));
    }
    const  editable= useSelector(state => state.authentication.role)==="USER";

    useEffect(() => {
        dispatch(fetchPRESTs());
    })
    useEffect(() => {
        const total = articles.reduce((a, b) => a + b.totalTTC, 0);
        setDepense({
            ...depense,
            articles: articles,
            montant: floatFormat(total),
            montantLitre: trans(total).trim()
        })
    }, [articles])
    useEffect(() => {
        const result = depenses.filter(o => o.id === Number(id));
        if (result.length) {
            setNew(false);
            setTitle("MODIFICATION DE DÉPENSE");
            setDepense(result[0]);
            setArticlees(result[0].articles);
        } else {
            setNew(true);
            setDepense({articles: []});
            setTitle("CRÉATION DE DÉPENSE");
        }
    }, [id, depenses]);

    return (
        <Paper p={2}>
            <Box p={2}>
                {depense &&
                    (
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <h2> {title}</h2>
                                </Grid>
                                <Grid item xs={12}>
                                    <TypeOptions
                                        handleChange={(value) => {
                                        }}
                                        typeNature={depense.typeNature}
                                    />
                                </Grid>
                                {type && type.label !== "BON DE COMMANDE" &&
                                    (<Grid item xs={4}>
                                        <FormControl fullWidth>
                                            <TextField
                                                onChange={handleChange}
                                                type={"number"}
                                                fullWidth
                                                id="duree"
                                                name="duree"
                                                label="Durée"
                                                value={depense.duree}
                                                variant="outlined"
                                            />
                                        </FormControl>
                                    </Grid>)
                                }
                                <Grid item xs={4}>
                                    <FormControl fullWidth>
                                        <KeyboardDatePicker
                                            minDate={type && type.lastOne ? type.lastOne : firstDay}
                                            maxDate={today}
                                            variant="inline"
                                            format="yyyy-MM-dd"
                                            id="date-picker-inline"
                                            label="Date signature"
                                            value={depense.dateSignature}
                                            onChange={handleDateChange}
                                            KeyboardButtonProps={{
                                                'aria-label': 'change date',
                                            }}
                                            inputVariant="outlined"
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    <PrestSelectOptions prest={depense.prestataire} handleChange={setPrest}/>
                                </Grid>
                                <Grid item xs={2}>
                                    <PrestSearchButton handleChange={setPrest}/>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControl fullWidth>
                                        <TextField
                                            onChange={handleChange}
                                            multiline
                                            rows={2}
                                            fullWidth
                                            id="objet"
                                            name="objet"
                                            label="Objet"
                                            value={depense.objet}
                                            variant="outlined"
                                        />
                                    </FormControl>
                                </Grid>
                                {type && type.label !== "BON DE COMMANDE" && (
                                    <Grid item xs={12}>
                                        <Modality
                                            setModality={(value) => {
                                                setDepense({
                                                    ...depense,
                                                    modality: value
                                                })
                                            }}
                                            modality={depense.modality}
                                        />
                                    </Grid>
                                )}
                                {type && type.label === "BON DE COMMANDE" ? (
                                    <Grid item xs={12}>
                                        <Bordereau
                                            num={1}
                                            articles={depense.articles}
                                            changeHandle={setArticles}/>
                                    </Grid>
                                ) : (
                                    <Grid item xs={12}>
                                        {[
                                            ...Array(depense.modality),
                                        ].map((o, index) => {
                                            return (
                                                <Grid item xs={12}>
                                                    <Bordereau
                                                        num={index + 1}
                                                        articles={depense.articles}
                                                        changeHandle={setArticles}/>
                                                </Grid>
                                            )
                                        })}
                                    </Grid>
                                )}


                                <Grid item xs={4}>
                                    <FormControl fullWidth>
                                        <TextField
                                            onChange={handleChange}
                                            fullWidth
                                            label="Montant en chiffres"
                                            value={depense.montant}
                                            variant="outlined"
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={8}>
                                    <FormControl fullWidth>
                                        <TextField
                                            onChange={handleChange}
                                            fullWidth
                                            label="Montant en Littres"
                                            multiline
                                            value={depense.montantLitre}
                                            variant="outlined"
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container justify={"flex-end"} spacing={5}>
                                        <Grid item>
                                            <Button color={"primary"} component={Link} to={"/depense"}>
                                                Retour
                                            </Button>
                                        </Grid>
                                        <Grid item>
                                            <Button onClick={() => handleDownload(
                                                DEPENSE_BASE_URL + "/" + depense.id + "/doc",
                                                "depense" + depense.id + ".pdf")}
                                                    color={"primary"} variant={"outlined"}>
                                                Edition
                                            </Button>
                                        </Grid>
                                        {!editable && (< Grid item>
                                            <Button color={"primary"} variant={"contained"} onClick={saveEvent}>
                                                Sauvgarder
                                            </Button>
                                        </Grid>)}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </MuiPickersUtilsProvider>
                    )}
            </Box>
        </Paper>);
}
