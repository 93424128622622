import {makeStyles} from "@material-ui/core/styles";
import {useSelector} from "react-redux";
import React, {useEffect} from "react";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import TableFooter from "@material-ui/core/TableFooter";
import {floatFormat} from "../appConfig/chiffres";
import IconButton from "@material-ui/core/IconButton";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import {RubricType} from "../redux/reducers/budgetReducer";

const types = [RubricType.FONCTIONNENT, RubricType.INVESTISSEMENT]
const useStyles = makeStyles(theme => ({
    root: {
        width: "100%", marginTop: theme.spacing(3), overflowX: "auto"
    }, table: {
        minWidth: 650
    }, selectTableCell: {
        width: 60
    }, tableCell: {
        width: 130, height: 40
    }, input: {
        width: 130, height: 40
    }
}));
export default function SituationBudget() {

    const [isExpandedI, setIsExpandedI] = React.useState(false);
    const [isExpandedF, setIsExpandedF] = React.useState(false);
    const rub = useSelector(state => state.budgets.fullList);
    const [rows, setRows] = React.useState([]);
    console.log(rows);
    const [calcule, setCalcule] = React.useState({
        credit: 0, rest: 0
    });
    const [calculeI, setCalculeI] = React.useState({
        credit: 0, rest: 0
    });
    const [calculeF, setCalculeF] = React.useState({
        credit: 0, rest: 0
    });
    useEffect(() => {
        const newRows = rub.filter(o => o.parentID === 0 && types.includes(o.rubricType));
        setCalcule({
            credit: newRows.reduce((accumulator, current) => accumulator + current.totalCredit, 0),
            engaged: newRows.reduce((accumulator, current) => accumulator + current.totalEngaged, 0),
            rest: newRows.reduce((accumulator, current) => accumulator + current.totalRest, 0),
            payment: newRows.reduce((accumulator, current) => accumulator + current.totalPayment, 0)
        })
        setCalculeI({
            credit: newRows.filter(o => o.rubricType === RubricType.INVESTISSEMENT).reduce((accumulator, current) => accumulator + current.totalCredit, 0),
            engaged: newRows.filter(o => o.rubricType === RubricType.INVESTISSEMENT).reduce((accumulator, current) => accumulator + current.totalEngaged, 0),
            rest: newRows.filter(o => o.rubricType === RubricType.INVESTISSEMENT).reduce((accumulator, current) => accumulator + current.totalRest, 0),
            payment: newRows.filter(o => o.rubricType === RubricType.INVESTISSEMENT).reduce((accumulator, current) => accumulator + current.totalPayment, 0)
        });
        setCalculeF({
            credit: newRows.filter(o => o.rubricType === RubricType.FONCTIONNENT).reduce((accumulator, current) => accumulator + current.totalCredit, 0),
            engaged: newRows.filter(o => o.rubricType === RubricType.FONCTIONNENT).reduce((accumulator, current) => accumulator + current.totalEngaged, 0),
            rest: newRows.filter(o => o.rubricType === RubricType.FONCTIONNENT).reduce((accumulator, current) => accumulator + current.totalRest, 0),
            payment: newRows.filter(o => o.rubricType === RubricType.FONCTIONNENT).reduce((accumulator, current) => accumulator + current.totalPayment, 0)
        });
        setRows(newRows);
    }, [rub]);
    const classes = useStyles();
    return (<Paper className={classes.root}>
        <Table className={classes.table} aria-label="caption table">
            <TableHead>
                <TableRow>
                    <TableCell align="left"/>
                    <TableCell align="left" style={{width: '55%'}}>Désignation</TableCell>
                    <TableCell align="right">Crédits</TableCell>
                    <TableCell align="right">Crédits Engagés</TableCell>
                    <TableCell align="right">Taux(%) Engagement</TableCell>
                    <TableCell align="right">Disponible Engagement</TableCell>
                    <TableCell align="right">Crédits Payés</TableCell>
                    <TableCell align="right">Taux(%) Paiement</TableCell>
                    <TableCell align="right">Disponible Paiement</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>

                <TableRow style={{backgroundColor: 'lightgray'}}>
                    <TableCell padding="checkbox">
                        <IconButton onClick={() => setIsExpandedF(!isExpandedF)}>
                            {isExpandedF ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
                        </IconButton>
                    </TableCell>
                    <TableCell align="left" className={classes.tableCell}>
                        {RubricType.FONCTIONNENT}
                    </TableCell>
                    <TableCell align="right">{floatFormat(calculeF.credit + calculeF.rest)}</TableCell>
                    <TableCell align="right">{floatFormat(calculeF.engaged)}</TableCell>
                    <TableCell
                        align="right">{floatFormat(calculeF.engaged / (calculeF.credit + calculeF.rest) * 100)}
                    </TableCell>
                    <TableCell
                        align="right">{floatFormat(calculeF.credit + calculeF.rest - calculeF.engaged)}
                    </TableCell>
                    <TableCell
                        align="right">{floatFormat(calculeF.payment)}
                    </TableCell>
                    <TableCell
                        align="right">{floatFormat(calculeF.payment / calculeF.engaged * 100)}
                    </TableCell>
                    <TableCell
                        align="right">{floatFormat(calculeF.engaged - calculeF.payment)}
                    </TableCell>
                </TableRow>
                {isExpandedF && rows.filter(o => o.rubricType === RubricType.FONCTIONNENT).map(row => (
                    <RubricRow key={row.id} row={row}></RubricRow>))}
                <TableRow style={{backgroundColor: 'lightgray'}}>
                    <TableCell padding="checkbox">
                        <IconButton onClick={() => setIsExpandedI(!isExpandedI)}>
                            {isExpandedI ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
                        </IconButton>
                    </TableCell>
                    <TableCell align="left" className={classes.tableCell}>
                        {RubricType.INVESTISSEMENT}
                    </TableCell>
                    <TableCell align="right">{floatFormat(calculeI.credit + calculeI.rest)}</TableCell>
                    <TableCell align="right">{floatFormat(calculeI.engaged)}</TableCell>
                    <TableCell
                        align="right">{floatFormat(calculeI.engaged / (calculeI.credit + calculeI.rest) * 100)}
                    </TableCell>
                    <TableCell
                        align="right">{floatFormat(calculeI.credit + calculeI.rest - calculeI.engaged)}
                    </TableCell>
                    <TableCell
                        align="right">{floatFormat(calculeI.payment)}
                    </TableCell>
                    <TableCell
                        align="right">{floatFormat(calculeI.payment / calculeI.engaged * 100)}
                    </TableCell>
                    <TableCell
                        align="right">{floatFormat(calculeI.engaged - calculeI.payment)}
                    </TableCell>
                </TableRow>
                {isExpandedI && rows.filter(o => o.rubricType === RubricType.INVESTISSEMENT).map(row => (
                    <RubricRow key={row.id} row={row}></RubricRow>))}
            </TableBody>
            <TableFooter>
                <TableRow style={{backgroundColor: 'lightGreen', color: "white"}}>
                    <TableCell align="left"/>
                    <TableCell align="left">Total</TableCell>
                    <TableCell align="right">{floatFormat(calcule.credit + calcule.rest)}</TableCell>
                    <TableCell align="right">{floatFormat(calcule.engaged)}</TableCell>
                    <TableCell
                        align="right">{floatFormat(calcule.engaged / (calcule.credit + calcule.rest) * 100)}
                    </TableCell>
                    <TableCell
                        align="right">{floatFormat(calcule.credit + calcule.rest - calcule.engaged)}
                    </TableCell>
                    <TableCell
                        align="right">{floatFormat(calcule.payment)}
                    </TableCell>
                    <TableCell
                        align="right">{floatFormat(calcule.payment / calcule.engaged * 100)}
                    </TableCell>
                    <TableCell
                        align="right">{floatFormat(calcule.engaged - calcule.payment)}
                    </TableCell>
                </TableRow>
            </TableFooter>
        </Table>
    </Paper>);
}

function RubricRow({row}) {
    const classes = useStyles();
    const rub = useSelector((state) => state.budgets.fullList);
    const [rows, setRows] = React.useState([]);
    useEffect(() => {
        setRows(rub.filter(o => o.parentID === row.id));
    }, [rub]);
    const [isExpanded, setIsExpanded] = React.useState(false);
    return (<React.Fragment>
        <TableRow style={{backgroundColor: 'yellow'}}>
            <TableCell padding="checkbox">
                <IconButton onClick={() => setIsExpanded(!isExpanded)}>
                    {isExpanded ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
                </IconButton>
            </TableCell>
            <TableCell align="left" className={classes.tableCell}>
                {row.name}
            </TableCell>
            <TableCell align="right" className={classes.tableCell}>
                {floatFormat(row.totalRest + +row.totalCredit)}
            </TableCell>
            <TableCell align="right" className={classes.tableCell}>
                {floatFormat(row.totalEngaged)}
            </TableCell> <TableCell
            align="right">{floatFormat(row.totalEngaged / (row.totalRest + row.totalCredit) * 100)}
        </TableCell>
            <TableCell
                align="right">{floatFormat(row.totalRest + row.totalCredit - row.totalEngaged)}
            </TableCell>
            <TableCell
                align="right">{floatFormat(row.totalPayment)}
            </TableCell>
            <TableCell
                align="right">{floatFormat(row.totalPayment / row.totalEngaged * 100)}
            </TableCell>
            <TableCell
                align="right">{floatFormat(row.totalEngaged - row.totalPayment)}
            </TableCell>
        </TableRow>
        {isExpanded && (rows.map(subRow => (<SubRubricRow key={subRow.id} row={subRow}
        >
        </SubRubricRow>)))}
    </React.Fragment>);
}

function SubRubricRow({row}) {
    const rub = useSelector((state) => state.budgets.fullList);
    const [rows, setRows] = React.useState([]);
    useEffect(() => {
        setRows(rub.filter(o => o.parentID === row.id));
    }, [rub]);
    const classes = useStyles();
    const [isExpanded, setIsExpanded] = React.useState(false);

    return (<React.Fragment>
        <TableRow key={row.id} style={{backgroundColor: 'lightBlue'}}>
            <TableCell padding="checkbox">
                <IconButton onClick={() => setIsExpanded(!isExpanded)}>
                    {isExpanded ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
                </IconButton>
            </TableCell>
            <TableCell align="left" className={classes.tableCell}>{row.name}</TableCell>

            <TableCell align="right" className={classes.tableCell}>
                {floatFormat(row.totalRest + +row.totalCredit)}
            </TableCell>
            <TableCell align="right" className={classes.tableCell}>
                {floatFormat(row.totalEngaged)}
            </TableCell>
            <TableCell
                align="right">{floatFormat(row.totalEngaged / (row.totalRest + row.totalCredit) * 100)}
            </TableCell>
            <TableCell
                align="right">{floatFormat(row.totalRest + row.totalCredit - row.totalEngaged)}
            </TableCell>
            <TableCell
                align="right">{floatFormat(row.totalPayment)}
            </TableCell>
            <TableCell
                align="right">{floatFormat(row.totalPayment / row.totalEngaged * 100)}
            </TableCell>
            <TableCell
                align="right">{floatFormat(row.totalEngaged - row.totalPayment)}
            </TableCell>
        </TableRow>
        {isExpanded && (rows.map(subRow => (<DesignationRow key={subRow.id} row={subRow}/>)))}
    </React.Fragment>)
}

function DesignationRow({row}) {
    const classes = useStyles();
    return (<TableRow>
        <TableCell padding="checkbox">
        </TableCell>
        <TableCell> {row.name}
        </TableCell>
        <TableCell align="right" className={classes.tableCell}>
            {floatFormat(Number(row.totalRest) + +Number(row.totalCredit))}
        </TableCell>
        <TableCell align="right" className={classes.tableCell}>
            {floatFormat(row.totalEngaged)}
        </TableCell>
        <TableCell
            align="right">{floatFormat(row.totalEngaged / (row.totalRest + row.totalCredit) * 100)}
        </TableCell>
        <TableCell
            align="right">{floatFormat(row.totalRest + row.totalCredit - row.totalEngaged)}
        </TableCell>
        <TableCell
            align="right">{floatFormat(row.totalPayment)}
        </TableCell>
        <TableCell
            align="right">{floatFormat(row.totalPayment / row.totalEngaged * 100)}
        </TableCell>
        <TableCell
            align="right">{floatFormat(row.totalEngaged - row.totalPayment)}
        </TableCell>
    </TableRow>)
}
