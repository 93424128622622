import {onError, onFetch} from "./prestActions";
import axios from "axios";
import {INVENTAIRE_BASE_URL, PAYMENT_BASE_URL, STOCK_BASE_URL, TRANSFER_BASE_URL} from "../../appConfig/apiConfig";
import {getAuth} from "./budgetActions";

export const CREATE_PAYMENT_SUCCESS = "CREATE_PAYMENT_SUCCESS"
export const FETCH_PAYMENT_SUCCESS = "FETCH_PAYMENT_SUCCESS"
export const FETCH_VIRMENT_SUCCESS = "FETCH_VIRMENT_SUCCESS"
export const FETCH_INVENTAIRE_SUCCESS = "FETCH_INVENTAIRE_SUCCESS"
export const FETCH_STOCK_SUCCESS = "FETCH_STOCK_SUCCESS"
export const CREATE_VIRMENT_SUCCESS = "CREATE_VIRMENT_SUCCESS"
export const SAVE_STOCK_SUCCESS = "SAVE_STOCK_SUCCESS"

export function fetchTransfers() {
    return async dispatch => {
        onFetch(true);

        function onSuccess(result) {
            return dispatch({type: FETCH_VIRMENT_SUCCESS, payload: result.data});
        }

        await axios.get(TRANSFER_BASE_URL,{  auth: getAuth().auth})
            .then(result => {
                return onSuccess(result)
            })
            .catch(error => {
                return onError(error)
            })
            .finally(() => {
                onFetch(false)
            });
    }
}

export function fetchPayments() {
    return async dispatch => {
        onFetch(true);

        function onSuccess(result) {
            return dispatch({type: FETCH_PAYMENT_SUCCESS, payload: result.data});
        }

        await axios.get(PAYMENT_BASE_URL, {auth: getAuth().auth})
            .then(result => {
                return onSuccess(result)
            })
            .catch(error => {
                return onError(error)
            })
            .finally(() => {
                onFetch(false)
            });
    }
}

export function fetchInvantaires() {
    return async dispatch => {
        onFetch(true);

        function onSuccess(result) {
            return dispatch({type: FETCH_INVENTAIRE_SUCCESS, payload: result.data});
        }

        await axios.get(INVENTAIRE_BASE_URL,{  auth: getAuth().auth})
            .then(result => {
                return onSuccess(result)
            })
            .catch(error => {
                return onError(error)
            })
            .finally(() => {
                onFetch(false)
            });
    }
}

export function savePayments(order) {
    return async dispatch => {
        onFetch(true);

        function onSuccess(result) {
            return dispatch({type: CREATE_PAYMENT_SUCCESS, payload: result.data});
        }

        await axios.post(PAYMENT_BASE_URL, order,{  auth: getAuth().auth})
            .then(result => {
                return onSuccess(result)
            })
            .catch(error => {
                return onError(error)
            })
            .finally(() => {
                onFetch(false)
            });

    }
}

export function fetchStocks(params) {
    return async dispatch => {
        onFetch(true);

        function onSuccess(result) {
            return dispatch({type: FETCH_STOCK_SUCCESS, payload: result.data});
        }

        await axios.get(STOCK_BASE_URL, {params: params,  auth: getAuth().auth})
            .then(result => {
                return onSuccess(result)
            })
            .catch(error => {
                return onError(error)
            })
            .finally(() => {
                onFetch(false)
            });
    }
}

export function saveVirments(order) {
    return async dispatch => {
        onFetch(true);

        function onSuccess(result) {
            return dispatch({type: CREATE_VIRMENT_SUCCESS, payload: result.data});
        }

        await axios.post(TRANSFER_BASE_URL, order,{  auth: getAuth().auth})
            .then(result => {
                return onSuccess(result)
            })
            .catch(error => {
                return onError(error)
            })
            .finally(() => {
                onFetch(false)
            });

    }
}

export function saveStock(stock) {
    console.log(stock);
    return async dispatch => {
        onFetch(true);

        function onSuccess(result) {
            return dispatch({type: FETCH_STOCK_SUCCESS, payload: result.data});
        }

        await axios.post(STOCK_BASE_URL, stock,{  auth: getAuth().auth})
            .then(result => {
                return onSuccess(result)
            })
            .catch(error => {
                return onError(error)
            })
            .finally(() => {
                onFetch(false)
            });

    }
}