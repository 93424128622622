import React, {useEffect} from "react";
import {makeStyles} from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import DoneIcon from "@material-ui/icons/DoneAllTwoTone";
import RevertIcon from '@material-ui/icons/KeyboardReturn';
import {useDispatch, useSelector} from "react-redux";
import {Button, FormControl, OutlinedInput} from "@material-ui/core";
import TableFooter from "@material-ui/core/TableFooter";
import {updateRubric} from "../redux/actions/budgetActions";
import DeleteRubric from "./DeleteRubric";
import {floatFormat} from "../appConfig/chiffres";

const useStyles = makeStyles(theme => ({
    root: {
        width: "100%",
        marginTop: theme.spacing(3),
        overflowX: "auto"
    },
    table: {
        minWidth: 650
    },
    selectTableCell: {
        width: 60
    },
    tableCell: {
        width: 130,
        height: 40
    },
    input: {
        width: 130,
        height: 40
    }
}));
const CustomTableCell = ({row, name, onChange}) => {
    const classes = useStyles();
    const {isEditMode} = row;
    return (
        <TableCell align="left" className={classes.tableCell}>
            {isEditMode ? (
                <FormControl fullWidth sx={{m: 1}}>
                    <OutlinedInput
                        id="outlined-adornment-amount"
                        value={row[name]}
                        name={name}
                        onChange={e => onChange(e, row)}
                        label="Amount"
                    />
                </FormControl>

            ) : (
                name === "name" ? row[name] : floatFormat(row[name])
            )}
        </TableCell>
    );
};
export default function Budget() {

    const editable = useSelector(state => state.authentication.role) === "USER";
    const rub = useSelector(state => state.budgets.list);
    const [rows, setRows] = React.useState([]);
    const [calcule, setCalcule] = React.useState({
        credit: 0, rest: 0
    });
    useEffect(() => {
        const newRows = rub.filter(o => o.parentID === 0);
        setCalcule({
            credit: newRows.reduce((accumulator, current) => accumulator + current.totalCredit, 0),
            rest: newRows.reduce((accumulator, current) => accumulator + current.totalRest, 0)
        })
        setRows(newRows);
    }, [rub]);
    const [previous, setPrevious] = React.useState({});
    const classes = useStyles();
    const onToggleEditModeFunc = id => {
        setRows(state => {
            return rows.map(row => {
                if (row.id === id) {
                    return {...row, isEditMode: !row.isEditMode};
                }
                return row;
            });
        });
    };
    const dispatch = useDispatch();
    const onChangeFunc = (e, row) => {
        if (!previous[row.id]) {
            setPrevious(state => ({...state, [row.id]: row}));
        }
        const value = e.target.value;
        const name = e.target.name;
        const {id} = row;
        const newRows = rows.map(row => {
            if (row.id === id) {
                return {...row, [name]: value};
            }
            return row;
        });
        setRows(newRows);
    };
    const onRevertFunc = id => {
        const newRows = rows.map(row => {
            if (row.id === id) {
                return previous[id] ? previous[id] : row;
            }
            return row;
        });
        setRows(newRows);
        setPrevious(state => {
            delete state[id];
            return state;
        });
        onToggleEditModeFunc(id);
    };
    const onDoneFunc = id => {
        onToggleEditModeFunc(id);
        const row = rows.filter(o => o.id === id)[0];
        dispatch(updateRubric(row));
    }
    return (
        <Paper className={classes.root}>
            <Table className={classes.table} aria-label="caption table">
                <TableHead>
                    <TableRow>
                        <TableCell align="left"/>
                        <TableCell align="left" style={{width: '55%'}}>Désignation des rubriques</TableCell>
                        <TableCell align="left">Crédits neufs</TableCell>
                        <TableCell align="left">RAP des années antérieurs </TableCell>
                        <TableCell align="left">Crédits</TableCell>
                        {!editable && (
                            <TableCell align="center" colSpan={2} style={{width: '10%'}}>Actions</TableCell>)}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map(row => (
                        <RubricRow key={row.id} row={row}
                                   onChange={onChangeFunc}
                                   onRevert={onRevertFunc}
                                   onToggleEditMode={onToggleEditModeFunc}
                                   onDone={onDoneFunc}
                        ></RubricRow>
                    ))}
                </TableBody>
                <TableFooter>
                    <TableRow style={{backgroundColor: 'lightGreen', color: "white"}}>
                        <TableCell align="left"/>
                        <TableCell align="left">Total</TableCell>
                        <TableCell align="left">{floatFormat(calcule.credit)}</TableCell>
                        <TableCell align="left">{floatFormat(calcule.rest)}</TableCell>
                        <TableCell align="left">{floatFormat(calcule.credit + calcule.rest)}</TableCell>
                        <TableCell align="left" colSpan={2} style={{width: '10%'}}>
                        </TableCell>
                    </TableRow>
                </TableFooter>
            </Table>
        </Paper>
    );
}

function RubricRow({row, onChange, onRevert, onToggleEditMode, onDone}) {

    const editable = useSelector(state => state.authentication.role) === "USER";
    const classes = useStyles();
    const rub = useSelector((state) => state.budgets.list);
    const [rows, setRows] = React.useState([]);
    useEffect(() => {
        setRows(rub.filter(o => o.parentID === row.id));
    }, [rub]);
    const dispatch = useDispatch();
    const onDoneFunc = id => {
        onToggleEditModeFunc(id);
        const row = rows.filter(o => o.id === id)[0];
        dispatch(updateRubric(row));
    }
    const onToggleEditModeFunc = id => {
        setRows(state => {
            return rows.map(row => {
                if (row.id === id) {
                    return {...row, isEditMode: !row.isEditMode};
                }
                return row;
            });
        });
    };
    const onChangeFunc = (e, row) => {
        if (!previous[row.id]) {
            setPrevious(state => ({...state, [row.id]: row}));
        }
        const value = e.target.value;
        const name = e.target.name;
        const {id} = row;
        const newRows = rows.map(row => {
            if (row.id === id) {
                return {...row, [name]: value};
            }
            return row;
        });
        setRows(newRows);
    };
    const onRevertFunc = id => {
        const newRows = rows.map(row => {
            if (row.id === id) {
                return previous[id] ? previous[id] : row;
            }
            return row;
        });
        setRows(newRows);
        setPrevious(state => {
            delete state[id];
            return state;
        });
        onToggleEditModeFunc(id);
    };
    const [previous, setPrevious] = React.useState({});
    const [isExpanded, setIsExpanded] = React.useState(false);
    return (<React.Fragment>
        <TableRow style={{backgroundColor: 'yellow'}}>
            <TableCell padding="checkbox">
                <IconButton onClick={() => setIsExpanded(!isExpanded)}>
                    {isExpanded ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
                </IconButton>
            </TableCell>
            <CustomTableCell {...{row, name: "name", onChange}} />
            <TableCell align="left" className={classes.tableCell}>
                {floatFormat(row.totalCredit)}
            </TableCell>
            <TableCell align="left" className={classes.tableCell}>
                {floatFormat(row.totalRest)}
            </TableCell>
            <TableCell align="left" className={classes.tableCell}>
                {floatFormat(row.totalRest + +row.totalCredit)}
            </TableCell>
            {!editable && (<><TableCell className={classes.selectTableCell}>
                {row.isEditMode ? (
                    <>
                        <IconButton
                            aria-label="done"
                            onClick={() => onDone(row.id)}
                        >
                            <DoneIcon/>
                        </IconButton>
                        <IconButton
                            aria-label="revert"
                            onClick={() => onRevert(row.id)}
                        >
                            <RevertIcon/>
                        </IconButton>
                    </>
                ) : (
                    <Button
                        aria-label="delete"
                        onClick={() => onToggleEditMode(row.id)}
                        variant={"contained"}
                        color={"primary"}
                    >
                        Modifier
                    </Button>
                )}
            </TableCell>

                <TableCell className={classes.selectTableCell}>
                <DeleteRubric rubric={row}/>
                </TableCell>
                </>)}
        </TableRow>
        {isExpanded && (rows.map(subRow => (<SubRubricRow key={subRow.id} row={subRow}
                                                          onToggleEditMode={onToggleEditModeFunc}
                                                          onRevert={onRevertFunc}
                                                          onChange={onChangeFunc}
                                                          onDone={onDoneFunc}
        >

        </SubRubricRow>)))}
    </React.Fragment>);
}

function SubRubricRow({row, onChange, onToggleEditMode, onRevert, onDone}) {
    const dispatch = useDispatch();
    const rub = useSelector((state) => state.budgets.list);
    const [rows, setRows] = React.useState([]);
    useEffect(() => {

        console.log(rub);
        setRows(rub.filter(o => o.parentID === row.id));
    }, [rub]);


    const onDoneFunc = id => {
        onToggleEditModeFunc(id);
        const row = rows.filter(o => o.id === id)[0];
        dispatch(updateRubric(row));
    }
    const classes = useStyles();
    const [isExpanded, setIsExpanded] = React.useState(false);
    const [previous, setPrevious] = React.useState({});
    const onToggleEditModeFunc = id => {
        setRows(state => {
            return rows.map(row => {
                if (row.id === id) {
                    return {...row, isEditMode: !row.isEditMode};
                }
                return row;
            });
        });
    };
    const onChangeFunc = (e, row) => {
        if (!previous[row.id]) {
            setPrevious(state => ({...state, [row.id]: row}));
        }
        const value = e.target.value;
        const name = e.target.name;
        const {id} = row;
        const newRows = rows.map(row => {
            if (row.id === id) {
                return {...row, [name]: value};
            }
            return row;
        });
        setRows(newRows);
    };
    const onRevertFunc = id => {
        const newRows = rows.map(row => {
            if (row.id === id) {
                return previous[id] ? previous[id] : row;
            }
            return row;
        });
        setRows(newRows);
        setPrevious(state => {
            delete state[id];
            return state;
        });
        onToggleEditModeFunc(id);
    };
    return (
        <React.Fragment>
            <TableRow key={row.id} style={{backgroundColor: 'lightBlue'}}>
                <TableCell padding="checkbox">
                    <IconButton onClick={() => setIsExpanded(!isExpanded)}>
                        {isExpanded ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
                    </IconButton>
                </TableCell>
                <CustomTableCell {...{row, name: "name", onChange}} />
                <TableCell align="left" className={classes.tableCell}>
                    {floatFormat(row.totalCredit)}
                </TableCell>
                <TableCell align="left" className={classes.tableCell}>
                    {floatFormat(row.totalRest)}
                </TableCell>
                <TableCell align="left" className={classes.tableCell}>
                    {floatFormat(row.totalRest + +row.totalCredit)}
                </TableCell>
                <TableCell className={classes.selectTableCell}>
                    {row.isEditMode ? (
                        <>
                            <IconButton
                                aria-label="done"
                                onClick={() => onDone(row.id)}
                            >
                                <DoneIcon/>
                            </IconButton>
                            <IconButton
                                aria-label="revert"
                                onClick={() => onRevert(row.id)}
                            >
                                <RevertIcon/>
                            </IconButton>
                        </>
                    ) : (
                        <Button
                            aria-label="delete"
                            onClick={() => onToggleEditMode(row.id)}
                            variant={"contained"}
                            color={"primary"}
                        >
                            Modifier
                        </Button>
                    )}
                </TableCell>
                <TableCell className={classes.selectTableCell}>
                    <DeleteRubric rubric={row}/>
                </TableCell>
            </TableRow>
            {isExpanded && (rows.map(subRow => (
                    <DesignationRow key={subRow.id} row={subRow} onChange={onChangeFunc}
                                    onRevert={onRevertFunc}
                                    onToggleEditMode={onToggleEditModeFunc}
                                    onDone={onDoneFunc}
                    />))
            )}
        </React.Fragment>
    )
}

function DesignationRow({row, onChange, onToggleEditMode, onRevert, onDone}) {
    const classes = useStyles();
    return (
        <TableRow>
            <TableCell padding="checkbox">
            </TableCell>
            <CustomTableCell {...{row, name: "name", onChange}} />
            <CustomTableCell {...{row, name: "totalCredit", onChange}} />
            <CustomTableCell {...{row, name: "totalRest", onChange}} />
            <TableCell align="left" className={classes.tableCell}>
                {floatFormat(Number(row.totalRest) + +Number(row.totalCredit))}
            </TableCell>
            <TableCell className={classes.selectTableCell}>
                {row.isEditMode ? (
                    <>
                        <IconButton
                            aria-label="done"
                            onClick={() => onDone(row.id)}
                        >
                            <DoneIcon/>
                        </IconButton>
                        <IconButton
                            aria-label="revert"
                            onClick={() => onRevert(row.id)}
                        >
                            <RevertIcon/>
                        </IconButton>
                    </>
                ) : (
                    <Button
                        aria-label="delete"
                        onClick={() => onToggleEditMode(row.id)}
                        variant={"outlined"}
                        color={"primary"}
                    >
                        Modifier
                    </Button>

                )}
            </TableCell>
            <TableCell className={classes.selectTableCell}>
                <DeleteRubric rubric={row}/>
            </TableCell>
        </TableRow>
    )
}


