import {Grid} from "@material-ui/core";
import {toTitle} from "../../appConfig/apiConfig";


export default function Header({name}) {
    return (
        <Grid container justify={"center"}>
            <Grid item>
                <h3>
                    {toTitle(name)}
                </h3>
            </Grid>
        </Grid>
    )
}