import {FETCH_INVENTAIRE_SUCCESS, FETCH_STOCK_SUCCESS} from "../actions/payamentActions";

const initState = {
    inventaires: [],
    stocks: [],
    types: [
        {
            name: "Autre",
            id: "other"
        },
        {
            name: "INVENTAIRE",
            id: "inventaire"
        }, {
            name: "STOCK",
            id: "stock"
        }
    ]
}
export const stockReducer = (state = initState, action) => {
    switch (action.type) {
        case FETCH_INVENTAIRE_SUCCESS:
            return {...state, inventaires: action.payload}
        case FETCH_STOCK_SUCCESS:
            return {...state, stocks: action.payload}
        default:
            return state;
    }
}