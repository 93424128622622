export const ME_BASE_URL = getBaseURL() + "/me"
export const PREST_BASE_URL = getBaseURL() + "/prestataire";
export const BANK_BASE_URL = getBaseURL() + "/bank";
export const DEPENSE_BASE_URL = getBaseURL() + "/depense";
export const PAYMENT_BASE_URL = getBaseURL() + "/payment";
export const TRANSFER_BASE_URL = getBaseURL() + "/transfer";
export const TYPES_BASE_URL = getBaseURL() + "/types";
export const INVENTAIRE_BASE_URL = getBaseURL() + "/store/inventaire";
export const STOCK_BASE_URL = getBaseURL() + "/store/stock";
export const PAYMENT_REPORTING_URL = getBaseURL() + "/reporting";
export const RUBRIC_BASE_URL = getBaseURL() + "/rubric"
export const YEAR_BASE_URL = getBaseURL() + "/year";
export const USERS_BASE_URL = getBaseURL() + "/user";
export const PWD_BASE_URL = getBaseURL() + "/pwd";

export function toTitle(str) {
    return str.replace(/(^|\s)\S/g, function (t) {
        return t.toUpperCase()
    });
}

export function getBaseURL() {
    return "http://10.10.10.3:8081/api";
}
