import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {Link} from "react-router-dom";

// Redux Actions
import {login as LoginAction} from "../redux/actions/actions.js";

// Material-UI components
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";

import CyberSecurityBadge from "../components/CyberSecurityBadge";

import {makeStyles} from "@material-ui/core/styles";
import ProductLogo from "../components/ProductLogo.js";
import {Auth} from "../App";

const EMAIL_REGEX = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;


const useStyles = makeStyles((theme) => ({
    card: {
        width: "600px",
        maxWidth: "100%",
        maxHeight: "100%",
        overflow: "auto",
        padding: theme.spacing.unit * 3,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        [theme.breakpoints.down("xs")]: {
            width: "100%"
        }
    },
    form: {
        maxWidth: "70%",
        [theme.breakpoints.down("xs")]: {
            maxWidth: "100%"
        }
    },
    formFields: {
        marginBottom: theme.spacing.unit * 2
    },
    flexrow: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        alignSelf: "stretch",
        marginBottom: theme.spacing.unit * 4
    },
    buttonRow: {
        marginBottom: theme.spacing.unit * 2,
        flexWrap: "nowrap",
        [theme.breakpoints.down("xs")]: {
            flexWrap: "wrap",
            flexDirection: "column-reverse",
            justifyContent: "center"
        }
    },
    loginButton: {
        [theme.breakpoints.down("xs")]: {
            width: "100%"
        }
    },
    link: {
        color: theme.palette.primary["300"],
        "&:visited": {
            color: theme.palette.primary["300"]
        }
    },
    first: {
        marginRight: theme.spacing.unit * 2
    },
    legalText: {
        textAlign: "center"
    }
}));
export default function Login() {

    const hasAuth = Auth();
    console.log(hasAuth());
    const [state, setState] = useState({email: "", password: ""});
    useEffect(() => {
        console.log(state, canLogIn());
    }, [state])
    const dispatch = useDispatch();
    const login = (email, password) => {
        console.log(email, password)
        dispatch(LoginAction(email, password))
    }
    const classes = useStyles();
    const canLogIn = () => {
        return (
            validEmail(state.email) &&
            state.email.match(EMAIL_REGEX) &&
            state.password.length > 0
        );
    }
    const validEmail = (address) => {
        return (
            address &&
            typeof address === "string" &&
            address.length > 0 &&
            address.match(EMAIL_REGEX)
        );
    }
    return (<Grid
        container
        alignItems="center"
        justify="center"
        className={"branding-panel"}
    >
        <Paper className={classes.card}>
            <ProductLogo/>
            <form
                className={classes.form}
                onSubmit={evt => {
                    login(state.email, state.password)
                    evt.preventDefault();
                }
                }
            >
                <Grid container direction="column" justify="space-between">
                    <TextField
                        error={
                            !(!state.email || validEmail(state.email))
                        }
                        label="Email"
                        className={classes.formFields}
                        value={state.username}
                        onChange={evt => setState({...state, email: evt.target.value})}
                        margin="normal"
                        required
                        inputProps={{maxLength: 256}}
                    />
                    <TextField
                        type="password"
                        label="Mot de passe"
                        className={classes.formFields}
                        value={state.password}
                        onChange={evt => {
                            setState({...state, password: evt.target.value});
                        }}
                        margin="none"
                        required
                        inputProps={{maxLength: 256}}
                    />
                    <Grid
                        container
                        direction="row"
                        alignItems="center"
                        justify="space-between"
                        className={classes.buttonRow}
                    >
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={state.remember}
                                    onChange={evt =>
                                        setState({...state, remember: evt.target.checked})
                                    }
                                />
                            }
                            label="N'oublier pas"
                        />
                        <Button
                            type={"submit"}
                            className={classes.loginButton}
                            variant={canLogIn() ? "contained" : "text"}
                            disabled={!canLogIn()}
                            color="primary"
                        >
                            Connecter
                        </Button>
                    </Grid>
                    <Grid container className={classes.flexrow}>
                        <Typography variant="body2">
                            <Link to="/forgot-password" className={classes.link}>
                                Réinitialiser un mot de passe
                            </Link>
                        </Typography>
                    </Grid>
                </Grid>
            </form>
            <CyberSecurityBadge/>
        </Paper>
    </Grid>)
}


