import React, {useEffect, useState} from "react";
import Box from "@material-ui/core/Box";
import {Grid, TextField} from "@material-ui/core";


export default function Modality({setModality, modality}) {
    const [size, setSize] = useState(modality ? modality : 1);
    useEffect(() => {
        if (!modality) {
            setSize(1);
        }
    }, [modality]);
    useEffect(() => {
        setModality(size);
    }, [size]);
    return (
        <Box>
            <Grid container spacing={3}>
                <Grid item xs={6} component="form" spacing={3}>
                    <TextField
                        label={"Modalite"}
                        type={"number"}
                        fullWidth
                        onChange={(event) => {
                            setSize(Number(event.target.value));
                        }}
                        value={size}
                        inputProps={{
                            min: 1,
                            max: 10
                        }}
                    />
                </Grid>
            </Grid>
        </Box>
    );

}