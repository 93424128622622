import React, {useEffect} from "react";
import Equilibre from "../components/Equilibre";
import {useDispatch} from "react-redux";
import {fetchYears} from "../redux/actions/budgetActions";

export default function EquilibrePage() {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(fetchYears());
    },)
    return (
        <div>
            <Equilibre/>
        </div>
    );
}