import React, {useEffect, useState} from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from "@material-ui/core/Grid";
import {useDispatch, useSelector} from "react-redux";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import {addRubric} from "../redux/actions/budgetActions";

export default function AddRubric({year, rubricType}) {
    const [state, setState] = React.useState({
        idRubric: "",
        idSubRubric: "",
        designation: ""
    })
    const [open, setOpen] = React.useState(false);
    const rows = useSelector(state => state.budgets.list);
    const [rubrics, setRubrics] = useState([]);
    const [subRubrics, setSubRubrics] = useState([]);
    useEffect(() => {
        setRubrics(rows.filter(o => o.parentID === 0));
    }, [rows]);
    useEffect(() => {
        setSubRubrics(rows.filter(o => o.parentID === state.idRubric))
    }, [state.idRubric, rows]);
    const dispatch = useDispatch();
    const handleChange = (event) => {
        setState({
            ...state,
            [event.target.name]: event.target.value
        });
    };
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    const handleDone = () => {
        let rub;
        if (state.idRubric === "")
            rub = {
                parentID: 0,
                name: state.designation,
                year: year,
                rubricType: rubricType
            }
        else if (state.idSubRubric === "")
            rub = {
                name: state.designation,
                parentID: state.idRubric,
                year: year,
                rubricType: rubricType
            }
        else
            rub = {
                name: state.designation,
                parentID: state.idSubRubric,
                year: year,
                rubricType: rubricType
            }

        dispatch(addRubric(rub));
        setOpen(false);
    }
    return (<div>
        <Button variant="outlined" color="primary" onClick={handleClickOpen}>
            ajouter nouvel élément
        </Button>
        <Dialog open={open}
                onClose={handleClose}
                maxWidth={"lg"}
                fullWidth
                aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Nouvelle rubrique</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    remplir les informations suivantes
                </DialogContentText>
                <Grid container spacing={3}>
                    <Grid item xs={6}>
                        <Grid container>
                            <Grid item xs={12}>
                                <FormControl
                                    fullWidth>
                                    <InputLabel id="demo-simple-select-helper-label">Rubrique</InputLabel>
                                    <Select
                                        style={{
                                            backgroundColor: 'yellow'
                                        }}
                                        fullWidth
                                        labelId="demo-simple-select-helper-label"
                                        id="idRubric"
                                        name="idRubric"
                                        value={state.idRubric}
                                        onChange={handleChange}
                                    >
                                        <MenuItem style={{
                                            backgroundColor: 'yellow'
                                        }} value={""}> - </MenuItem>
                                        {rubrics.map(o => (<MenuItem style={{
                                            backgroundColor: 'yellow'
                                        }} value={o.id}>{o.name}</MenuItem>))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl
                                    fullWidth>
                                    <InputLabel style={{
                                        backgroundColor: 'lightblue'
                                    }} id="demo-simple-select-helper-label">Sous rubrique</InputLabel>
                                    <Select
                                        style={{
                                            backgroundColor: 'lightblue'
                                        }}
                                        fullWidth
                                        labelId="demo-simple-select-helper-label"
                                        id="idSubRubric"
                                        name="idSubRubric"
                                        value={state.idSubRubric}
                                        onChange={handleChange}
                                    >
                                        <MenuItem value={""} style={{
                                            backgroundColor: 'lightblue'
                                        }}> - </MenuItem>
                                        {subRubrics.map(o => (<MenuItem value={o.id} style={{
                                            backgroundColor: 'lightblue'
                                        }}>{o.name}</MenuItem>))}
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={6}>
                        <FormControl fullWidth>
                            <TextField
                                style={{
                                    backgroundColor: isNaN(parseFloat(state?.idRubric)) ? "yellow" :
                                        isNaN(parseFloat(state?.idSubRubric)) ? "lightblue" : "white"
                                }}
                                onChange={handleChange}
                                fullWidth
                                id="designation"
                                name="designation"
                                label="Designation"
                                multiline
                                rows={4}
                                value={state.designation}
                                variant="outlined"
                            />
                        </FormControl>
                    </Grid>
                </Grid>

            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Annuler
                </Button>
                <Button onClick={handleDone} color="primary" variant={"contained"}>
                    Ajouter
                </Button>
            </DialogActions>
        </Dialog>
    </div>);
}
