import Grid from "@material-ui/core/Grid";
import React, {useEffect, useState} from "react";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import PrestSelectOptions from "./PrestSelectOptions";
import {Button} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";

export function PrestSearchButton({handleChange}) {
    const [field, setField] = useState("rs");
    const [prest, setPrest] = useState(null);
    useEffect(() => {
        console.log(prest)
    }, [prest]);
    const [open, setOpen] = React.useState(false);
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    const done = () => {
        handleChange(prest);
        handleClose();
    }
    return (
        <Box>
            <Button variant="outlined" color="primary" onClick={handleClickOpen}  fullWidth size={"small"}>
                Chercher
            </Button>
            <Dialog open={open}
                    onClose={handleClose}
                    maxWidth={"lg"}
                    fullWidth
                    aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Chercher Prestataire</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Selectionnez l'attribut de recherche
                    </DialogContentText>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <FormControl component="fieldset">
                                <FormLabel component="legend">Chercher Par : </FormLabel>
                                <RadioGroup row
                                            aria-label="position"
                                            name="position"
                                            value={field}
                                            onChange={(event, value) => {
                                                setField(value);
                                            }}>

                                    <FormControlLabel
                                        value="rs"
                                        control={<Radio color="primary"/>}
                                        label="RAISON SOCIAL"
                                        labelPlacement="start"
                                    />
                                    <FormControlLabel
                                        value="ice"
                                        control={<Radio color="primary"/>}
                                        label="ICE"
                                        labelPlacement="start"
                                    />
                                    <FormControlLabel
                                        value="ife"
                                        control={<Radio color="primary"/>}
                                        label="IF"
                                        labelPlacement="start"
                                    />
                                    <FormControlLabel
                                        value="rc"
                                        control={<Radio color="primary"/>}
                                        label="RC"
                                        labelPlacement="start"
                                    />
                                    <FormControlLabel
                                        value="patente"
                                        control={<Radio color="primary"/>}
                                        label="PATENTE"
                                        labelPlacement="start"
                                    />
                                    <FormControlLabel
                                        value="cnss"
                                        control={<Radio color="primary"/>}
                                        label="CNSS"
                                        labelPlacement="start"
                                    />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                        <Grid item xs={8}>
                            <PrestSelectOptions handleChange={setPrest} field={field} add={true}/>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Annuler
                    </Button>
                    <Button onClick={done} color="primary" variant={"contained"}>
                        Selectionner
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    )
}