import React, {useEffect, useState} from "react"
import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Paper, Slide} from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import Grid from "@material-ui/core/Grid";
import {useDispatch, useSelector} from "react-redux";
import Button from "@material-ui/core/Button";
import {deletePRESTs, fetchPRESTs} from "../redux/actions/prestActions";
import PrestUpdate from "../components/prest/PrestUpdate";

export const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
const ROWS_PER_PAGE_OPTIONS = [10, 20, 100];

function createData(eleve, banks) {
    //const bank = banks.filter(o => o.id === eleve.banka)[0]
    return [
        eleve.id,
        eleve.rs,
        eleve.prestations.map(o => o.label).join(" - "),
        eleve.adresse,
        eleve.patente,
        eleve.rc,
        eleve.ife,
        eleve.ice,
        eleve.cnss,
        eleve.rib,
        eleve.bank.code,
        eleve.agence,
        eleve.responsable
    ]
}

export default function EleveTable() {

    const  editable= useSelector(state => state.authentication.role)==="USER";
    const {list: content, banks} = useSelector(state => state.prestataires);
    const columns = [
        {
            name: "id", label: "ID",
        },
        {
            name: "rs", label: "Raison social",
        },
        {
            name: "rs", label: "Prestation",
        },
        {
            name: "adresse", label: "Adresse",
        },
        {
            name: "patente", label: "Patente",
        },
        {
            name: "rc", label: "RC",
        },
        {
            name: "ife", label: "IF",
        },
        {
            name: "ice", label: "ICE",
        },
        {
            name: "cnss", label: "CNSS",
        },
        {
            name: "rib", label: "RIB",
        },
        {
            name: "bank", label: "Nom bank",
        },
        {
            name: "agence", label: "Agence",
        },
        {
            name: "responsable", label: "Responsable",
        },
        {
            name: "Actions",
            options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                    const prest = content.find(o => o.id === tableMeta.rowData[0]);
                    return (
                        <PrestUpdate prest={prest}/>
                    );
                }
            }
        }
    ];

    const [data, setData] = useState([]);
    const [deleteI, setDeleteI] = useState([]);
    const [pagination, setPagination] = useState({
        page: 0,
        size: 10,
        fields: "id",
        direction: "asc"
    });
    const dispatch = useDispatch();
    useEffect(() => {
        content && setData(content.map(o => createData(o, banks)));
    }, [content]);
    useEffect(() => {
        dispatch(fetchPRESTs(pagination));
    }, [pagination]);
    const deleteHandle = (deleted) => {
        if (deleted) {
            dispatch(deletePRESTs(pagination, {ids: deleteI.map(i => content[i].id)}));
        }
        setDeleteI([]);
    }
    const options = {
        selectableRows:!editable,
        filterType: "dropdown",
        responsive: "scroll",
        print: false,
        onTableChange: (action, tableState) => {
            if (action === "sort")
                setPagination({
                    ...pagination,
                    direction: tableState.sortOrder.direction,
                    fields: tableState.sortOrder.name
                });
            if (action === "changePage") {
                setPagination({...pagination, page: tableState.page});
            }
        },
        onRowsDelete: (data) => {
            setDeleteI(data.data.map(o => o.dataIndex));
        }
    };

    return (
        <Paper>
            <EleveDelete handleDone={deleteHandle} indexList={deleteI} open={deleteI.length > 0}/>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <MUIDataTable
                        title={"Liste des prestataires "}
                        data={data}
                        columns={columns}
                        options={options}
                    />
                </Grid>
            </Grid>
        </Paper>
    )
}

export function EleveDelete({indexList, open, handleDone}) {
    const content = useSelector(state => state.prestataires.list);
    const [list, setList] = useState([]);
    useEffect(() => {
        setList(indexList.map(i => {
            const eleve = content[i];
            return eleve.id + " " + eleve.rs
        }).join(", ") + ".");
    }, [indexList]);
    return (
        <div>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={() => handleDone(false)}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle id="alert-dialog-slide-title">{"Êtes-vous sûr de vouloir supprimer ?"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        Êtes-vous sûr de supprimer ces enregistrements ?
                        En supprimant définitivement, vous ne pourrez pas les récupérer.<br/>
                        {list}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => handleDone(false)} color="primary">
                        Annuler
                    </Button>
                    <Button onClick={() => handleDone(true)} color="primary">
                        Oui, supprimer!
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
