import AppBar from "@material-ui/core/AppBar";
import React, {useState} from "react";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import {TabPanel} from "../Engagement/Payments";
import Users from "./Users";
import Bank from "./Bank";
import Section from "./Section";

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default function Administration() {

    const [value, setValue] = useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    return (
        <React.Fragment>
            <AppBar position="static">
                <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
                    <Tab label={"Users"}
                         {...a11yProps(0)} />
                    <Tab
                        label={"Banques"}
                        {...a11yProps(1)} />
                    <Tab
                        label={"Natures"}
                        {...a11yProps(2)} />
                </Tabs>
            </AppBar>
            <TabPanel value={value} index={0}>
                <Users/>
            </TabPanel>
            <TabPanel value={value} index={1}>
                <Bank/>
            </TabPanel>
            <TabPanel value={value} index={2}>
                <Section/>
            </TabPanel>
        </React.Fragment>
    );

}
