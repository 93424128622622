import {useDispatch, useSelector} from "react-redux";
import React, {useEffect, useState} from "react";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import {FormControl, InputLabel, makeStyles, MenuItem, Select} from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import Tooltip from "@material-ui/core/Tooltip";
import Fab from "@material-ui/core/Fab";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import TextField from "@material-ui/core/TextField";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import {addNature, fetchTypes} from "../../redux/actions/depenseActions";
import {fetchBanks} from "../../redux/actions/prestActions";

function createData(user) {
    return [
        user.id,
        user.label
    ]
}

export default function Section() {

    const dispatch=useDispatch();
    useEffect(()=>{
        dispatch(fetchTypes());
    },[])
    return (
        <React.Fragment>
            <SectionTable/>
            <AddSection/>
        </React.Fragment>
    )

}

export function SectionTable() {
    const list = useSelector(state => state.depenses.types)
    const [types, setTypes] = useState([]);
    const [type, setType] = useState([]);
    const [groups, setGroups] = useState([]);
    const [group, setGroup] = useState(null);

    const columns = [
        {
            name: "id", label: "Id",
        },
        {
            name: "label", label: "Designation",
        }
        ,
        {
            name: "Actions",
            options: {
                // customBodyRender: (value, tableMeta, updateValue) => {
                //     const user = users.find(o => o.email === tableMeta.rowData[2]);
                //     return (
                //         <UpdateUser user={user}/>
                //     );
                // }
            }
        }
    ];
    const [data, setData] = useState([]);
    useEffect(() => {
        list && setTypes(list.filter(o => o.name === "type"));
    }, [list]);
    useEffect(() => {
        const gs = list.filter(o => o.name === "section" && o.type.id === type.id);
        type && list && setGroups(gs);
        if (gs.length > 0)
            setGroup(gs[0]);
    }, [type]);
    useEffect(() => {
        group && list && setData(list.filter(o => o.name === "nature" && o.section.id === group.id).map(o => createData(o)));
    }, [group])
    const options = {
        pagination: false,
        filterType: "dropdown",
        responsive: "scroll",
        print: false,
    };

    return (
        <Paper>
            <Grid container spacing={3}>
                <Grid item xs={4}>
                    <FormControl fullWidth variant={"outlined"} margin={"dense"}>
                        <InputLabel id="demo-simple-select-helper-label">Type</InputLabel>
                        <Select
                            labelId="demo-simple-select-helper-label"
                            id="depenseType"
                            name="depenseType"
                            onChange={(e) => setType(e.target.value)}
                            value={type}
                        >
                            {types.map(o => (<MenuItem value={o}>{o.label}</MenuItem>))}
                        </Select>
                    </FormControl>

                </Grid>
                {groups && groups.length > 1 && (
                    <Grid item xs={4}>
                        <FormControl fullWidth variant={"outlined"} margin={"dense"}>
                            <InputLabel id="demo-simple-select-helper-label">Section</InputLabel>
                            <Select
                                labelId="demo-simple-select-helper-label"
                                id="typeGroup"
                                name="typeGroup"
                                onChange={(event) => {
                                    setGroup(event.target.value)
                                }}
                                value={group}
                            >
                                {groups.map(o => (<MenuItem value={o}>{o.label}</MenuItem>))}
                            </Select>
                        </FormControl>
                    </Grid>)}
                <Grid item xs={12}>
                    <MUIDataTable
                        title={"Liste des Natures "}
                        data={data}
                        columns={columns}
                        options={options}
                    />
                </Grid>
            </Grid>
        </Paper>
    )

}

const useStyles = makeStyles((theme) => ({
    fab: {
        margin: theme.spacing(2),
    },
    absolute: {
        position: 'absolute',
        bottom: theme.spacing(2),
        right: theme.spacing(3),
    },
}));

const fields = [
    {generated: true, id: "id", label: "Id", xs: 4},
    {generated: false, id: "label", label: "designation", xs: 10}
]

function AddSection() {
    const list = useSelector(state => state.depenses.types)
    const [types, setTypes] = useState([]);
    const [type, setType] = useState([]);
    const [groups, setGroups] = useState([]);
    const [group, setGroup] = useState(null);

    useEffect(() => {
        list && setTypes(list.filter(o => o.name === "type"));
    }, [list]);
    useEffect(() => {
        const gs = list.filter(o => o.name === "section" && o.type.id === type.id);
        type && list && setGroups(gs);
        if (gs.length > 0)
            setGroup(gs[0]);
    }, [type]);
    const [isValid, setValid] = useState(false);
    const classes = useStyles();
    const [state, setState] = React.useState({
        id: "",
        label: ""
    });
    const [open, setOpen] = React.useState(false);
    const dispatch = useDispatch();
    const handleChange = (event) => {
        setState({
            ...state,
            [event.target.name]: event.target.value
        });
    };
    const handleClickOpen = () => {
        setOpen(true);
        setState({
            id: "",
            label: ""
        });
    }

    useEffect(() => {
        //  const x = state.rs.length > 3 & state.adresse.length > 3 & state.rc.length > 3 & state.ice.length > 3 & state.rib.length > 3 & state.bank != null & state.agence.length > 3 & state.responsable.length > 3
        setValid(true);
    }, [state]);
    const handleClose = () => {
        setOpen(false);
    };

    const handleDone = () => {
        dispatch(addNature({label: state.label,sectionId:group.id}));
        setOpen(false);
    }
    return (<div>

        <Tooltip title="Add" aria-label="add" onClick={handleClickOpen}>
            <Fab color="secondary" className={classes.absolute}>
                CRÉER
            </Fab>
        </Tooltip>

        <Dialog open={open}
                onClose={handleClose}
                maxWidth={"lg"}
                fullWidth
                aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Nouvelle Nature</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Remplir les informations suivantes
                </DialogContentText>
                <Grid container spacing={3} justify={"space-between"}>
                    <Grid item xs={4}>
                        <FormControl fullWidth variant={"outlined"} margin={"dense"}>
                            <InputLabel id="demo-simple-select-helper-label">Type</InputLabel>
                            <Select
                                labelId="demo-simple-select-helper-label"
                                id="depenseType"
                                name="depenseType"
                                onChange={(e) => setType(e.target.value)}
                                value={type}
                            >
                                {types.map(o => (<MenuItem value={o}>{o.label}</MenuItem>))}
                            </Select>
                        </FormControl>

                    </Grid>
                    {groups && groups.length > 1 && (
                        <Grid item xs={4}>
                            <FormControl fullWidth variant={"outlined"} margin={"dense"}>
                                <InputLabel id="demo-simple-select-helper-label">Section</InputLabel>
                                <Select
                                    labelId="demo-simple-select-helper-label"
                                    id="typeGroup"
                                    name="typeGroup"
                                    onChange={(event) => {
                                        setGroup(event.target.value)
                                    }}
                                    value={group}
                                >
                                    {groups.map(o => (<MenuItem value={o}>{o.label}</MenuItem>))}
                                </Select>
                            </FormControl>
                        </Grid>)}
                    {
                        fields.map(o => {
                            return (!o.generated) && (
                                <Grid key={o.id} item xs={o.xs}>
                                    <FormControl fullWidth>
                                        <TextField
                                            InputProps={{
                                                readOnly: o.generated,
                                            }}
                                            onChange={handleChange}
                                            fullWidth
                                            id={o.id}
                                            name={o.id}
                                            label={o.label}
                                            value={state[o.id]}
                                            variant="outlined"
                                        />
                                    </FormControl>
                                </Grid>
                            );
                        })}

                </Grid>

            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Annuler
                </Button>
                <Button
                    onClick={handleDone}
                    color="primary"
                    variant={"contained"}
                    disabled={!isValid}>
                    Sauvgarder
                </Button>
            </DialogActions>
        </Dialog>
    </div>);
}
