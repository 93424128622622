import {DataGrid} from '@material-ui/data-grid';
import {Button} from "@material-ui/core";
import DeleteDialog from "./DeleteDialog";
import {useEffect, useState} from "react";
import {useDispatch} from "react-redux";

export default function DataTable({
                                      checkboxSelection,
                                      height,
                                      title,
                                      columns,
                                      rows,
                                      toolbar,
                                      deleteAction,
                                      selectChange
                                  }) {
    const [items, setItems] = useState(rows);
    useEffect(() => {
        setItems(rows);
    }, [rows])
    console.log(rows);
    const [del, setDel] = useState(null);
    const deleteDia = (id) => {
        const list = rows.filter(o => o.id === id);
        setDel({list: list})
    }
    const dispatch = useDispatch();
    const handleDelete = (confirm) => {
        if (confirm) {
            dispatch(deleteAction(del.list.map(o => o.id)));
        }
        setDel(null)
    }
    return (
        <div style={{height: height ? height : 520, width: '100%'}}>
            <DeleteDialog title={title}
                          open={del}
                          handleDelete={handleDelete}
                          items={del ? del.list : []}
            />
            <DataGrid
                onSelectionModelChange={(newSelection) => {
                    selectChange(newSelection.selectionModel);
                }}
                columns={deleteAction ? [...columns, {
                    field: 'id',
                    headerName: 'Actions',
                    renderCell: (params) => (
                        <strong>
                            <Button
                                variant="contained"
                                color="primary"
                                size="small"
                                onClick={() => deleteDia(params.value)}
                            >
                                Delete
                            </Button>
                        </strong>)
                }] : columns}
                rows={items}
                pageSize={10}
                checkboxSelection={checkboxSelection}
                showToolbar={toolbar}
                disableSelectionOnClick={true}
            />
        </div>);
}
