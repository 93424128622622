export const LOADING_RUBRIC_SUCCESS = "LOADING_RUBRIC_SUCCESS";
export const LOADING_RUBRIC_FAIL = "LOADING_RUBRIC_FAIL";
export const RUBRIC_TYPE_CHANGED = "RUBRIC_TYPE_CHANGED";
export const RUBRIC_SOUS_TYPE_CHANGED = "RUBRIC_SOUS_TYPE_CHANGED";
export const YEAR_CHANGED = "YEAR_CHANGED";
export const YEAR_ADD_SUCCESS = "YEAR_ADD_SUCCESS";
export const LOADING_YEARS_SUCCESS = "LOADING_YEARS_SUCCESS";
export const ERROR_GENERATED = "ERROR_GENERATED";
export const FETCH_PREST_SUCCESS = "FETCH_PREST_SUCCESS";
export const LOADING_STATUS_CHANGED = "LOADING_STATUS_CHANGED";
export const FETCH_DEPENSE_SUCCESS = "FETCH_DEPENSE_SUCCESS";
export const FETCH_TYPES_SUCCESS = "FETCH_TYPES_SUCCESS";
export const ENGAGED_ADD_SUCCESS = "ENGAGED_ADD_SUCCESS";
export const ENGAGED_DELETE_SUCCESS = "ENGAGED_DELETE_SUCCESS";
export const SAVE_FACTURE_SUCCESS = "SAVE_FACTURE_SUCCESS";
export const FETCH_BASE_URL_SUCCESS = "FETCH_BASE_URL_SUCCESS";
export const FETCH_BANK_SUCCESS = "FETCH_BANK_SUCCESS";
export const FETCH_USERS_SUCCESS = "FETCH_USERS_SUCCESS";