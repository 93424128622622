import {
    Box,
    Checkbox,
    Dialog,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    Grid,
    InputLabel,
    makeStyles,
    MenuItem,
    Select
} from "@material-ui/core";
import React, {useEffect, useState} from 'react';
import Button from '@material-ui/core/Button';
import LinearStepper from "../../components/general/LinearStepper";
import TypeOptions from "../../components/depense/TypeOptions";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import PrestSelectOptions from "../../components/prest/PrestSelectOptions";
import Bordereau from "../../components/depense/Bordereau";
import {saveDep} from "../../redux/actions/depenseActions";
import {useDispatch, useSelector} from "react-redux";
import {Transition} from "../PrestataireV2";
import Tooltip from "@material-ui/core/Tooltip";
import {floatFormat, trans} from "../../appConfig/chiffres";

const useStyles = makeStyles((theme) => ({
    fab: {
        margin: theme.spacing(5),
    },
    absolute: {
        position: 'absolute',
        bottom: theme.spacing(2),
        right: theme.spacing(3),
    },
}));

function Form({state, setState, setValid}) {
    if (state && state.typeDepense) {
        switch (state.typeDepense.label) {
            case "BON DE COMMANDE":
                return (<BCForm depense={state} setDepense={setState}/>)
            case "CONTRAT":
            case "CONVENTION DE DROIT COMMUN":
                return (<ContratForm depense={state} setDepense={setState}/>)
            case "MARCHÉ":
                return (<MarcheForm depense={state} setDepense={setState}/>)

            default :
                break;
        }
    }
    return (
        <Grid container justify={"center"} spacing={3}>
            <Grid item>
                <h2>Type not supported</h2>
            </Grid>
        </Grid>
    );
}

export default function DepenseCreate({onFinish}) {
    const dispatch = useDispatch();
    const classes = useStyles();
    const [state, setState] = useState({articles: []});
    const [valid, setValid] = useState({
        formValid: false
    })
    const [open, setOpen] = useState(false)

    const handleDone = () => {
        onFinish();
        setState({articles: []});
        setOpen(false)
    }
    const handleOpen = () => {
        setOpen(true)
    }
    const steps = [
        {
            valid: true,
            label: "Choisir Type de depense",
            content: (<TypeStep state={state} setState={setState} />)
            ,
            handleDone: () => {
                console.log("Step 1 done")
            }
        },
        {
            valid: true,
            label: "Infos",
            content: (
                <Form state={state} setState={setState}/>
            ),
            handleDone: () => {
                console.log(state)
            }
        },
        {
            valid: true,
            label: "Bordereau des prix",
            content: (
                <Grid item xs={12}>
                    <Bordereau articles={state.articles} changeHandle={(values) => setState({
                        ...state,
                        articles: values
                    })}/>
                </Grid>
            ),
            handleDone: () => {
                console.log(state)
            }
        },
        {
            valid: true,
            label: "Sauvgarder",
            content: (<SaveStep articles={state.articles} depense={state} setDepense={setState}/>),
            handleDone: () => {
                dispatch(saveDep(state))
            }
        }
    ]
    const done = () => (<h2>
        Saved
    </h2>);
    return (
        <Box>
            <Dialog
                fullWidth
                maxWidth={"lg"}
                open={open}
                TransitionComponent={Transition}
                onClose={() => handleDone(false)}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle id="alert-dialog-slide-title">{"Creation de depense"}</DialogTitle>
                <DialogContent>
                    <LinearStepper steps={steps.filter(o => o)} done={done} handleDone={handleDone}/>
                </DialogContent>
            </Dialog>
            <Tooltip title="Creation" aria-label="creation" onClick={handleOpen}>
                <Button color="secondary" className={classes.absolute} variant={"outlined"}>
                    Creation
                </Button>
            </Tooltip>
        </Box>
    )
}

function getStartDate(date) {
    if (date && new Date(date).getFullYear() === new Date().getFullYear()) {
        return date;
    } else {
        return new Date(new Date().getFullYear(), 0, 1);
    }
}

export function BCForm({depense, setDepense}) {
    const today = new Date();
    const firstDay = getStartDate(depense && depense.typeDepense ? depense.typeDepense.lastOne : null);
    useEffect(() => {
        setDepense({
            ...depense,
            dateSignature: firstDay
        })
    }, [])
    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Grid container spacing={3}>
                <Grid item xs={4}>
                    <FormControl fullWidth>
                        <KeyboardDatePicker
                            margin="dense"
                            minDate={firstDay}
                            maxDate={today}
                            variant="inline"
                            format="yyyy-MM-dd"
                            id="date-picker-inline"
                            label="Date signature"
                            value={depense.dateSignature ? depense.dateSignature : today}
                            onChange={(date) =>
                                setDepense({
                                    ...depense,
                                    dateSignature: date
                                })
                            }
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                            inputVariant="outlined"
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={8}>
                    <PrestSelectOptions
                        search={true}
                        prest={depense.prestataire}
                        handleChange={(value) => setDepense({
                            ...depense,
                            prestataire: value
                        })}/>
                </Grid>
                <Grid item xs={12}>
                    <FormControl fullWidth>
                        <TextField
                            onChange={(e) => setDepense({
                                ...depense,
                                objet: e.target.value
                            })}
                            multiline
                            rows={2}
                            fullWidth
                            id="objet"
                            name="objet"
                            label="Objet"
                            value={depense.objet}
                            variant="outlined"
                        />
                    </FormControl>
                </Grid>
            </Grid>
        </MuiPickersUtilsProvider>)
}

export function ContratForm({depense, setDepense}) {
    const today = new Date();
    const firstDay = getStartDate(depense && depense.typeDepense ? depense.lastOne : null);
    useEffect(() => {
        // setValid(!!(depense.dateSignature && depense.prestataire && depense.objet && depense.objet.length > 15))
    }, [depense])
    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Grid container justify={"center"} spacing={3}>
                <Grid item xs={5}>
                    <FormControl fullWidth>
                        <KeyboardDatePicker
                            minDate={firstDay}
                            maxDate={today}
                            variant="inline"
                            format="dd-MM-yyyy"
                            label="Date signature"
                            value={depense.dateSignature}
                            onChange={(date) => setDepense({
                                ...depense,
                                dateSignature: date
                            })}
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                            inputVariant="outlined"
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={5}>
                    <FormControl fullWidth>
                        <KeyboardDatePicker
                            minDate={depense.dateSignature ? depense.dateSignature : firstDay}
                            maxDate={today}
                            variant="inline"
                            format="dd-MM-yyyy"
                            label="Date Fin"
                            value={depense.dateFin}
                            onChange={(date) => setDepense({
                                ...depense,
                                dateFin: date
                            })}
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                            inputVariant="outlined"
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={10}>
                    <PrestSelectOptions
                        search={true}
                        prest={depense.prestataire}
                        handleChange={(value) => setDepense({
                            ...depense,
                            prestataire: value
                        })}/>
                </Grid>
                <Grid item xs={10}>
                    <FormControl fullWidth>
                        <TextField
                            onChange={(e) => setDepense({
                                ...depense,
                                objet: e.target.value
                            })}
                            multiline
                            rows={2}
                            fullWidth
                            id="objet"
                            name="objet"
                            label="Objet"
                            value={depense.objet}
                            variant="outlined"
                        />
                    </FormControl>
                </Grid>
            </Grid>
        </MuiPickersUtilsProvider>)
}

export function MarcheForm({depense, setDepense}) {
    const today = new Date();
    const firstDay = getStartDate(depense && depense.typeDepense ? depense.lastOne : null);
    const types = useSelector(state => state.depenses.marcheTypes);
    useEffect(() => {
        //     setValid(true)
    }, [depense])
    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Grid container spacing={3} justify={"center"}>
                <Grid item xs={5}>
                    <FormControl fullWidth>
                        <TextField
                            size={"small"}
                            onChange={(e) => setDepense({
                                ...depense,
                                numAF: e.target.value
                            })}
                            fullWidth
                            id="numAF"
                            name="numAF"
                            label="Appel d'Offres Nº"
                            value={depense.numAF}
                            variant="outlined"
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={5}>
                    <FormControl fullWidth variant={"outlined"} margin={"dense"}>
                        <InputLabel id="demo-simple-select-helper-label">Type de marche</InputLabel>
                        <Select
                            labelId="demo-simple-select-helper-label"
                            id="marcheType"
                            name="marcheType"
                            onChange={(e) => setDepense({
                                ...depense,
                                typeMarche: e.target.value
                            })}
                            value={depense.typeMarche}
                        >
                            {types.map(o => (<MenuItem value={o.id}>{o.name}</MenuItem>))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={5}>
                    <FormControl fullWidth size={"small"}>
                        <KeyboardDatePicker
                            minDate={firstDay}
                            maxDate={today}
                            variant="inline"
                            format="dd-MM-yyyy"
                            label="Date signature"
                            value={depense.dateSignature}
                            onChange={(date) => setDepense({
                                ...depense,
                                dateSignature: date
                            })}
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                            inputVariant="outlined"
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={5}>
                    <FormControl fullWidth size={"small"}>
                        <KeyboardDatePicker

                            minDate={firstDay}
                            maxDate={today}
                            variant="inline"
                            format="dd-MM-yyyy"
                            label="Date d'Approbation"
                            value={depense.dateApprobation}
                            onChange={(date) => setDepense({
                                ...depense,
                                dateApprobation: date
                            })}
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                            inputVariant="outlined"
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={5}>
                    <FormControl fullWidth>
                        <TextField
                            margin={"dense"}
                            autoOk
                            type={"number"}
                            onChange={(e) => setDepense({
                                ...depense,
                                num: e.target.value
                            })}
                            fullWidth
                            id="delay"
                            name="delay"
                            label="Délai"
                            value={depense.num}
                            variant="outlined"
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={5}>
                    <PrestSelectOptions
                        search={true}
                        prest={depense.prestataire}
                        handleChange={(value) => setDepense({
                            ...depense,
                            prestataire: value
                        })}/>
                </Grid>
                <Grid item xs={10}>
                    <FormControl fullWidth>
                        <TextField
                            onChange={(e) => setDepense({
                                ...depense,
                                objet: e.target.value
                            })}
                            multiline
                            rows={2}
                            fullWidth
                            id="objet"
                            name="objet"
                            label="Objet"
                            value={depense.objet}
                            variant="outlined"
                        />
                    </FormControl>
                </Grid>
            </Grid>
        </MuiPickersUtilsProvider>

    )
}

export function FactureForm({depense, setDepense}) {

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Grid container spacing={3}>
                <Grid item xs={4}>
                    <FormControl fullWidth>
                        <KeyboardDatePicker
                            margin="dense"
                            variant="inline"
                            format="yyyy-MM-dd"
                            id="date-picker-inline"
                            label="Date D'execution"
                            onChange={(date) =>
                                setDepense({
                                    ...depense,
                                    dateExecution: date
                                })
                            }
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                            inputVariant="outlined"
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={4}>
                    <FormControl fullWidth>
                        <TextField
                            margin={"dense"}
                            onChange={(e) => setDepense({
                                ...depense,
                                num: e.target.value
                            })}
                            fullWidth
                            id="num"
                            name="num"
                            label="Nº facture"
                            value={depense.num}
                            variant="outlined"
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={4}>
                    <FormControl fullWidth>
                        <TextField
                            defaultValue={1}
                            margin={"dense"}
                            onChange={(e) => setDepense({
                                ...depense,
                                nbr: e.target.value
                            })}
                            InputProps={{inputProps: {min: 1, max: 11}}}
                            type={"number"}
                            fullWidth
                            id="nbr"
                            name="nbr"
                            label="nombre des mois"
                            value={depense.nbr}
                            variant="outlined"
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <Bordereau articles={depense.articles} changeHandle={(values) => setDepense({
                        ...depense,
                        articles: values
                    })}/>
                </Grid>
            </Grid>
        </MuiPickersUtilsProvider>)
}

function SaveStep({articles, depense, setDepense}) {
    useEffect(() => {
        const tot = articles ? articles.map(o => o.totalTTC).reduce((a, b) => a + b, 0) : 0
        setDepense({
            ...depense,
            montant: floatFormat(tot),
            montantLitre: trans(tot)
        })
    }, [articles])
    return (
        <Grid container justify={"center"} spacing={3}>
            <Grid item xs={4}>
                <FormControl fullWidth>
                    <TextField
                        fullWidth
                        label="Montant total TTC en DH"
                        value={depense.montant}
                        variant="outlined"
                    />
                </FormControl>
            </Grid>
            <Grid item xs={10}>
                <FormControl fullWidth>
                    <TextField
                        fullWidth
                        value={depense.montantLitre}
                        variant="outlined"
                    />
                </FormControl>
            </Grid>
        </Grid>
    )
}

function TypeStep({state, setState}) {
    return (
        <Box>
            <Grid container spacing={3} justify={"center"}>
                <Grid item xs={4}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={state.additional}
                                onChange={(e) => {
                                    setState({
                                        ...state,
                                        additional: e.target.checked
                                    })
                                }
                                }
                                color="primary"
                            />
                        }
                        label="Variable"
                    />
                </Grid>
                {/*<Grid item xs={4}>*/}
                {/*    <FormControlLabel*/}
                {/*        control={*/}
                {/*            <Checkbox*/}
                {/*                checked={state.permanent}*/}
                {/*                onChange={(e) => {*/}
                {/*                    setState({*/}
                {/*                        ...state,*/}
                {/*                        permanent: e.target.checked*/}
                {/*                    })*/}
                {/*                }*/}
                {/*                }*/}
                {/*                color="primary"*/}
                {/*            />*/}
                {/*        }*/}
                {/*        label="Permanent"*/}
                {/*    />*/}
                {/*</Grid>*/}
            </Grid>
            <TypeOptions
                handleChange={(value) => {
                    setState({
                        ...state,
                        typeNature: value.id,
                        typeDepense: value.section.type
                    });
                }}
                typeNature={state.typeNature}
            />
        </Box>
    )
}
