import FormControl from "@material-ui/core/FormControl";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import React, {useState} from "react";
import {useSelector} from "react-redux";
import {PrestSearchButton} from "./PrestSearchButton";
import PrestAdd from "./PrestAdd";

export default function PrestSelectOptions({prest, handleChange, field,search,add}) {
    const [prestataire, setPrestataire] = useState(prest);
    const list = useSelector(state => state.prestataires.list);
    return (
        <FormControl fullWidth>
            <Autocomplete
                fullWidth
                value={prestataire}
                onChange={(event, newValue) => {
                    setPrestataire(newValue);
                    handleChange(newValue);
                }}

                options={list}
                getOptionLabel={(option) => {
                    return field !== undefined && field !== "" ? option[field] : option.id + " " + option.rs
                }}
                renderInput={(params) =>
                    <TextField {...params}
                               margin={"dense"}
                               fullWidth
                               label="Prestataire"
                               variant="outlined"
                               InputProps={{
                                   ...params.InputProps,
                                   endAdornment: (
                                       <React.Fragment>
                                           {params.InputProps.endAdornment}
                                           {search&&<PrestSearchButton handleChange={setPrestataire}/>}
                                           {add&&<PrestAdd simple={true}/>}
                                       </React.Fragment>
                                   ),
                               }}
                    />}
            />
        </FormControl>
    )
}
