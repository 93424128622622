import {
    LOADING_RUBRIC_FAIL,
    LOADING_RUBRIC_SUCCESS,
    LOADING_YEARS_SUCCESS,
    RUBRIC_TYPE_CHANGED,
    RUBRIC_SOUS_TYPE_CHANGED,
    YEAR_ADD_SUCCESS,
    YEAR_CHANGED
} from "./constants";
import {store} from "../store/store";
import {RUBRIC_BASE_URL, YEAR_BASE_URL} from "../../appConfig/apiConfig";

export const changeType = (type) => ({
    type: RUBRIC_TYPE_CHANGED,
    payload: type
})
export const changeSousType = (sousType) => ({
    type: RUBRIC_SOUS_TYPE_CHANGED,
    payload: sousType
})
export const changeYear = (year) => ({
    type: YEAR_CHANGED,
    payload: year
})
export const getHeaders = () => {
    const auth = store.getState().authentication;
    let headers = new Headers();
    headers.set('Authorization', 'Basic ' + Buffer.from(auth.email + ":" + auth.token).toString('base64'));
    return headers;
}

export function getAuth() {
    const auth = store.getState().authentication;
    return {
        auth: {
            username: auth.email, password: auth.token
        }
    }
}

export const updateRubric = (rubric) => dispatch => {
    const headers = getHeaders();
    headers.append('Accept', 'application/json')
    headers.append('Content-Type', 'application/json')
    fetch(RUBRIC_BASE_URL + "/" + rubric.id,
        {
            method: 'PUT',
            headers: headers,
            body: JSON.stringify({
                name: rubric.name,
                credit: rubric.totalCredit,
                rest: rubric.totalRest
            })
        })
        .then(res => dispatch(fetchRubric(rubric.year)))
        .then((result) => {

        }, (error) => {
            //
        })
}
export const addRubric = (rubric) => dispatch => {
    const headers = getHeaders();
    headers.append('Accept', 'application/json')
    headers.append('Content-Type', 'application/json')
    fetch(RUBRIC_BASE_URL,
        {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(rubric)
        })
        .then(res => res.json())
        .then((result) => {
            dispatch(fetchRubric(rubric.year));
        }, (error) => {
            //
        })
}
export const deleteRubric = (id, year) => dispatch => {
    fetch(RUBRIC_BASE_URL + "/" + +id, {
        headers: getHeaders(),
        method: 'DELETE'
    }).then(res => {
    }).then((result) => {
            dispatch(fetchRubric(year));
        },
        (error) => {
            //
        }
    )
}
export const fetchRubric = (year) => dispatch => {

    fetch(YEAR_BASE_URL + "/" + year + "/rubrics", {headers: getHeaders()})
        .then(res => res.json())
        .then((result) => {
                dispatch({
                    type: LOADING_RUBRIC_SUCCESS,
                    payload: result
                })
            },
            (error) => {
                dispatch({
                    type: LOADING_RUBRIC_FAIL,
                    payload: error
                })
            }
        )
}
export const addYear = (year) => dispatch => {
    fetch(YEAR_BASE_URL + "/" + year,
        {
            headers: getHeaders(),
            method: 'POST'
        })
        .then(res => res.json())
        .then((result) => {
            dispatch({
                type: YEAR_ADD_SUCCESS,
                payload: {
                    year: year,
                    rubrics: result
                }
            });
        }, (error) => {
        })
}
export const fetchYears = () => dispatch => {
    console.log(getHeaders())
    fetch(YEAR_BASE_URL, {
        headers: getHeaders()
    })
        .then(res => res.json())
        .then((result) => {
                console.log(result)
                dispatch({
                    type: LOADING_YEARS_SUCCESS,
                    payload: result
                })
            },
            (error) => {
                dispatch({
                    type: LOADING_YEARS_SUCCESS,
                    payload: []
                })
            }
        )
}
