import {Box, Grid} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Stepper from "@material-ui/core/Stepper";
import React from "react";
import Button from "@material-ui/core/Button";

export default function LinearStepper({steps, done, handleDone}) {

    const [activeStep, setActiveStep] = React.useState(0);
    const [skipped, setSkipped] = React.useState(new Set());
    const isStepSkipped = (step) => {
        return skipped.has(step);
    }
    const handleNext = () => {
        let newSkipped = skipped;
        if (isStepSkipped(activeStep)) {
            newSkipped = new Set(newSkipped.values());
            newSkipped.delete(activeStep);
        }
        if (activeStep < steps.length) {
            steps[activeStep] && steps[activeStep].handleDone && steps[activeStep].handleDone();
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
        setSkipped(newSkipped);
        if (activeStep > steps.length - 1) {

        }
    }
    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };
    const handleReset = () => {
        setActiveStep(0);
    };
    return (
        <Grid container justify={"center"} spacing={3}>
            {[...steps, {
                content: done()
            }].map((step, index) => {
                return (
                    <Grid key={index}
                          item xs={10}
                          hidden={activeStep !== index}
                    >
                        <Box height={400}>
                            {step.content}
                        </Box>
                    </Grid>)
            })}

            <Grid item xs={12}>
                <Stepper activeStep={activeStep}>
                    {steps.map((step, index) => {
                        const stepProps = {};
                        const labelProps = {};
                        if (step.optional) {
                            labelProps.optional = <Typography variant="caption">Optional</Typography>;
                        }
                        if (isStepSkipped(index)) {
                            stepProps.completed = false;
                        }
                        return (
                            <Step key={index} {...stepProps}>
                                <StepLabel {...labelProps}>{step.label}</StepLabel>
                            </Step>
                        );
                    })}
                </Stepper>
            </Grid>
            <Grid item xs={12}>
                <Grid container justify={"center"} spacing={3}>
                    <Grid item>
                        <Button onClick={handleReset} variant={"outlined"}>
                            Reset
                        </Button>
                    </Grid>
                    {activeStep < steps.length && (<Grid item>
                        <Button disabled={activeStep === 0} onClick={handleBack} variant={"outlined"}>
                            Retour
                        </Button>
                    </Grid>)}
                    <Grid item>
                        <Button
                            disabled={activeStep < steps.length && !steps[activeStep].valid}
                            variant="contained"
                            color="primary"
                            onClick={activeStep > steps.length - 1 ? handleDone : handleNext}
                        >
                            {activeStep < steps.length - 1 ? "Suivant" : activeStep > steps.length - 1 ? "Terminer" : "Sauvgarder"}
                        </Button>
                    </Grid>
                    {activeStep < steps.length - 1 && steps[activeStep].optional && (
                        <Grid item>
                            <Button
                                variant="contained"
                                color="primary"

                            >
                                Skip
                            </Button>
                        </Grid>
                    )}
                </Grid>
            </Grid>
        </Grid>
    )
}