import Grid from "@material-ui/core/Grid";
import MUIDataTable from "mui-datatables";
import React, {useEffect, useState} from "react";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import {Button} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import {UNITES} from "../../redux/reducers/reducers";
import {floatFormat} from "../../appConfig/chiffres";

export function BordereauCadre() {
    const [rows, setRows] = useState([
        {
            num: "C12318",
            designation: "X1 carbon",
            unite: "Piece",
            quantiteMin: 2,
            quantiteMax: 100,
            prixUnit: 15000,
            tva: 14,
        }, {
            num: "W421",
            designation: "HP scanner",
            unite: "Piece",
            quantiteMin: 35,
            quantiteMax: 100,
            prixUnit: 3000,
            tva: 20,
        }
    ]);
    const columns = [
        {
            name: "Num Article",
            options: {
                filter: false,
                customBodyRender: (value, tableMeta, updateValue) => (
                    <FormControlLabel
                        value={value}
                        control={<TextField value={value}/>}
                        onChange={event => updateValue(event.target.value)}
                    />
                )
            }
        }, {
            name: "Designation",
            options: {
                filter: false,
                customBodyRender: (value, tableMeta, updateValue) => (
                    <FormControlLabel
                        value={value}
                        control={<TextField value={value}/>}
                        onChange={event => updateValue(event.target.value)}
                    />
                )
            }
        }, {
            name: "Unité",
            options: {
                filter: false,
                customBodyRender: (value, tableMeta, updateValue) => (
                    <FormControlLabel
                        value={value}
                        control={<TextField value={value}/>}
                        onChange={event => updateValue(event.target.value)}
                    />
                )
            }
        }, {
            name: "Quantite Min",
            options: {
                filter: false,
                customBodyRender: (value, tableMeta, updateValue) => (
                    <FormControlLabel
                        value={value}
                        control={<TextField type={"number"} value={value}/>}
                        onChange={event => updateValue(event.target.value)}
                    />
                )
            }
        }, {
            name: "Quantite Max",
            options: {
                filter: false,
                customBodyRender: (value, tableMeta, updateValue) => (
                    <FormControlLabel
                        value={value}
                        control={<TextField type={"number"} value={value}/>}
                        onChange={event => updateValue(event.target.value)}
                    />
                )
            }
        },
        {
            name: "Prix Unit",
            options: {
                filter: false,
                customBodyRender: (value, tableMeta, updateValue) => (
                    <FormControlLabel
                        value={value}
                        control={<TextField type={"number"} value={value}/>}
                        onChange={event => updateValue(event.target.value)}
                    />
                )
            }
        }, {
            name: "TVA",
            options: {
                filter: false,
                customBodyRender: (value, tableMeta, updateValue) => (
                    <FormControlLabel
                        value={value}
                        control={
                            <Select value={value}>
                                <MenuItem value={0}>-</MenuItem>
                                <MenuItem value={7}>7%</MenuItem>
                                <MenuItem value={10}>10%</MenuItem>
                                <MenuItem value={14}>14%</MenuItem>
                                <MenuItem value={20}>20%</MenuItem>
                            </Select>
                    }
                        onChange={event => updateValue(event.target.value)}
                    />
                )
            }
        }, {
            name: "Prix Min total HT",
            options: {
                filter: false,
                customBodyRender: (value, tableMeta, updateValue) => (tableMeta.rowData[3] * tableMeta.rowData[5])
            }
        }, {
            name: "Prix Min total HT",
            options: {
                filter: false,
                customBodyRender: (value, tableMeta, updateValue) => (tableMeta.rowData[3] * tableMeta.rowData[5])
            }
        }, {
            name: "Prix Min total HT",
            options: {
                filter: false,
                customBodyRender: (value, tableMeta, updateValue) => (tableMeta.rowData[4] * tableMeta.rowData[5])
            }
        }, {
            name: "Prix Min total TTC",
            options: {
                filter: false,
                customBodyRender: (value, tableMeta, updateValue) => ((tableMeta.rowData[3] * tableMeta.rowData[5]) + (tableMeta.rowData[3] * tableMeta.rowData[5] * tableMeta.rowData[6] / 100))
            }
        }, {
            name: "Prix Max total TTC",
            options: {
                filter: false,
                customBodyRender: (value, tableMeta, updateValue) => ((tableMeta.rowData[4] * tableMeta.rowData[5]) + (tableMeta.rowData[4] * tableMeta.rowData[5] * tableMeta.rowData[6] / 100))
            }
        }
    ]
    const data = rows.map(obj => Object.keys(obj)
        .map(function (key) {
            return obj[key];
        }));
    console.log(data);
    const options = {
        filterType: "dropdown",
        responsive: "scroll",
        print: false
    }

    const addNew = () => {
        setRows([...rows, {
            num: "",
            designation: "",
            unite: "",
            quantite: 0,
            prixUnit: 0,
            tva: 0
        }])
    }
    return (<Grid container spacing={2}>
        <Grid item xs={12}>
            <h2>Bordereau des prix</h2>
        </Grid>
        <Grid item xs={12}>
            <MUIDataTable
                title={"Bordereau des prix"}
                data={data}
                columns={columns}
                options={options}

            />
        </Grid>
        <Grid item xs={12}>
            <Button variant={"outlined"} onClick={addNew}>
                Ajouter une Ligne
            </Button>
        </Grid>
    </Grid>)
}

const createData = (row) => {
    return [row.num,
        row.designation,
        row.unite,
        row.quantite,
        floatFormat(row.prix),
        row.tva,
        floatFormat(row.totalHT),
        floatFormat(row.totalTTC)
    ]
}
export default function Bordereau({articles, changeHandle}) {
    const [valid,setValid]=useState(false);
    const columns = [
        "Num Article",
        "Designation",
        "Unité",
        "Quantite",
        "Prix Unit HT en DH",
        "% TVA",
        "Prix total HT en DH",
        "Prix total TTC en DH",
    ]
    const [open, setOpen] = React.useState(false);
    const handleChange = (event) => {
        setState({
            ...state,
            [event.target.name]: event.target.value
        });
    };
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    const [rows, setRows] = useState([]);
    useEffect(() => {
        setRows(articles ? articles : []);
    }, [articles]);
    const data = rows.map(obj => createData(obj));
    const options = {
        resizableColumns: true,
        filterType: "dropdown",
        responsive: "standard  ",
        pagination: false,
        print: false,
        onRowsDelete: (data) => {
            const indices = data.data.map(o => o.dataIndex);
            const fine = articles.filter((v, i) => {
                return !indices.includes(i);
            })
            fine.forEach((item, i) => {
                item.num = i + 1;
            })
            changeHandle(fine)
        }
    }

    const [state, setState] = useState(
        {
            designation:"",
            tva: 0,
            num: data.length + 1
        });
    useEffect(()=>{
        const x = state.designation.length>3 &&
            state.unite!=null &&
            state.quantite>0 &&
            state.prix>0
        setValid(x);
    },[state])
    useEffect(() => {
        if (open) {
            setState({
                designation:"",
                num: data.length + 1,
                tva: 0
            });
        }
    }, [open])
    const handleDone = () => {
        const rs = rows.map(o => ({
            ...o,
            num: o.num < state.num ? o.num : o.num + 1
        }));
        rs.splice(state.num - 1, 0, {
            ...state,
            num: Number(state.num),
            totalHT: state.quantite * state.prix,
            totalTTC: (state.quantite * state.prix) + (state.quantite * state.prix) * state.tva / 100,
        });
        changeHandle(rs);
        handleClose();
    }
    return (
        <Grid container spacing={2}>
            <Dialog open={open}
                    onClose={handleClose}
                    maxWidth={"lg"}
                    fullWidth
                    aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Ajouter Article</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        remplir les informations suivantes
                    </DialogContentText>
                    <Grid container spacing={3}>
                        <Grid item xs={8}>
                            <FormControl fullWidth variant={"outlined"}>
                                <InputLabel id="demo-simple-select-helper-label">Num</InputLabel>
                                <Select
                                    labelId="demo-simple-select-helper-label"
                                    id="num"
                                    name="num"
                                    onChange={handleChange}
                                    value={state.num}
                                >
                                    {[
                                        ...Array(data.length + 1),
                                    ].map((o, index) => <MenuItem key={index} value={index + 1}>{index + 1}</MenuItem>)}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={8}>
                            <FormControl fullWidth>
                                <TextField
                                    onChange={handleChange}
                                    fullWidth
                                    id="designation"
                                    name="designation"
                                    label="Designation"
                                    value={state.designation}
                                    variant="outlined"
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={8}>
                            <FormControl fullWidth variant={"outlined"}>
                                <InputLabel id="demo-simple-select-helper-label">UNITE</InputLabel>
                                <Select
                                    labelId="demo-simple-select-helper-label"
                                    id="unite"
                                    name="unite"
                                    onChange={handleChange}
                                    value={state.unite}
                                >
                                    {UNITES.map(o => (<MenuItem value={o}>{o}</MenuItem>))}
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item xs={8}>
                            <FormControl fullWidth>
                                <TextField
                                    onChange={handleChange}
                                    fullWidth
                                    type={"number"}
                                    id="Quantite"
                                    name="quantite"
                                    label="quantite"
                                    value={state.quantite}
                                    variant="outlined"
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={8}>
                            <FormControl fullWidth>
                                <TextField
                                    onChange={handleChange}
                                    fullWidth
                                    type={"number"}
                                    id="prix"
                                    name="prix"
                                    label="Prix Unit"
                                    value={state.prix}
                                    variant="outlined"
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={8}>
                            <FormControl fullWidth variant={"outlined"}>
                                <InputLabel id="demo-simple-select-helper-label">TVA</InputLabel>
                                <Select
                                    labelId="demo-simple-select-helper-label"
                                    id="tva"
                                    name="tva"
                                    onChange={handleChange}
                                    value={state.tva}
                                >
                                    <MenuItem value={0}> - </MenuItem>
                                    <MenuItem value={7}>{"7%"}</MenuItem>
                                    <MenuItem value={10}>{"10%"}</MenuItem>
                                    <MenuItem value={14}>{"14%"}</MenuItem>
                                    <MenuItem value={20}>{"20%"}</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Annuler
                    </Button>
                    <Button onClick={handleDone} color="primary" variant={"contained"} disabled={!valid}>
                        Ajouter
                    </Button>
                </DialogActions>
            </Dialog>

            <Grid item xs={12}>
                <MUIDataTable
                    title={"Bordereau des prix"}
                    data={data}
                    columns={columns}
                    options={options}

                />
            </Grid>
            <Grid item xs={12}>
                <Button variant={"outlined"} onClick={handleClickOpen}>
                    Ajouter une Ligne
                </Button>
            </Grid>

        </Grid>)
}
