import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Budget from "../components/Budget";
import AddRubric from "../components/AddRubric";
import React, {useEffect} from "react";
import {fetchRubric} from "../redux/actions/budgetActions";
import {useDispatch, useSelector} from "react-redux";
import YearSelectOption from "../components/YearSelectOption";
import RubricTypeSelectOption from "../components/RubricTypeSelectOption";


export default function BudgetPage() {
    const dispatch = useDispatch();
    const  editable= useSelector(state => state.authentication.role)==="USER";
    const {rubricType, year} = useSelector(state => state.budgets);
    useEffect(() => {
        dispatch(fetchRubric(year));
    }, [year]);
    return (
        <Grid container pacing={2}>
            <Grid item xs={12}>
                <Paper>
                    <Grid container justify="space-evenly" spacing={2}>
                        <Grid item xs={3}>
                            <RubricTypeSelectOption/>
                        </Grid>
                        <Grid item xs={1}>
                            <YearSelectOption/>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
            <Grid item xs={12}>
                <Budget/>
            </Grid>
            {!editable && (<Grid item xs={12}>
                <Paper>
                    <Grid container justify="space-evenly" spacing={2}>
                        <Grid item>
                            <AddRubric year={year} rubricType={rubricType}/>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>)}
        </Grid>)
}
