import {FETCH_BANK_SUCCESS, FETCH_PREST_SUCCESS} from "../actions/constants";

const initPrest = {
    banks:[],
    list:[]
}

export const prestRuducer = (state = initPrest, action) => {
    switch (action.type) {
        case FETCH_PREST_SUCCESS:
            return {
                ...state,
                list: action.payload
            }
        case FETCH_BANK_SUCCESS:
            return {
                ...state,
                banks: action.payload
            }
        default:
            return state;
    }
};
