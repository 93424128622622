import {useDispatch, useSelector} from "react-redux";
import React, {useEffect, useState} from "react";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import MUIDataTable from "mui-datatables";
import {makeStyles} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import Fab from "@material-ui/core/Fab";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import DialogActions from "@material-ui/core/DialogActions";
import {addUser, deleteUser, fetchUsers, updateUser} from "../../redux/actions/userActions";
import {Transition} from "../../components/DeleteRubric";
import {editePassword} from "../../redux/actions/actions";


function createData(user, roles) {
    const role = roles.find(x => x.id === user.role);
    return [
        user.nom,
        user.prenom,
        user.email,
        role.label
    ]
}

export default function Users() {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(fetchUsers());
    }, [])
    return (
        <Paper>
            <UserTable/>
            <UserAdd/>
        </Paper>
    );
}

export function UserTable() {
    const {email} = useSelector(state => state.authentication);
    const {roles, users} = useSelector(state => state.admin);
    const columns = [
        {
            name: "nom", label: "Nom",
        },
        {
            name: "prenom", label: "Prenom",
        },
        {
            name: "mail", label: "Email",
        },
        {
            name: "role", label: "Role",
        },
        {
            name: "Mot de passe",
            options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                    const user = users.find(o => o.email === tableMeta.rowData[2]);
                    return (<UpdateUserPassword user={user}/>

                    );
                }
            }
        },
        {
            name: "Actions",
            options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                    const user = users.find(o => o.email === tableMeta.rowData[2]);
                    return (
                        <Grid container>
                            <Grid item xs={6}> <UpdateUser user={user}/></Grid>
                            <Grid item xs={6}> <DeleteUser user={user}/></Grid>
                        </Grid>
                    );
                }
            }
        }
    ];
    const [pagination, setPagination] = useState({
        page: 0,
        size: 10,
        fields: "id",
        direction: "asc"
    });
    const [data, setData] = useState([]);
    useEffect(() => {
        users && setData(users.filter(o => o.email !== email).map(o => createData(o, roles)));
    }, [users, email,roles]);
    const options = {
        selectableRows: false,
        filterType: "dropdown",
        responsive: "scroll",
        print: false,
        onTableChange: (action, tableState) => {
            if (action === "sort")
                setPagination({
                    ...pagination,
                    direction: tableState.sortOrder.direction,
                    fields: tableState.sortOrder.name
                });
            if (action === "changePage") {
                setPagination({...pagination, page: tableState.page});
            }
        }
    };

    return (
        <Paper>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <MUIDataTable
                        title={"Liste des Utilisateurs "}
                        data={data}
                        columns={columns}
                        options={options}
                    />
                </Grid>
            </Grid>
        </Paper>
    )
}

export function DeleteUser({user}) {
    const [open, setOpen] = React.useState(false);
    const dispatch = useDispatch();
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    const handleDone = () => {
        dispatch(deleteUser(user.email));
        setOpen(false);
    }

    return (
        <div>
            <Button variant="contained" color="primary" onClick={handleClickOpen}>
                Supprimer
            </Button>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle id="alert-dialog-slide-title">{"Êtes-vous sûr de vouloir supprimer?"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">

                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Annuler
                    </Button>
                    <Button onClick={handleDone} color="primary" variant={"contained"}>
                        Approuver
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export function UpdateUserPassword({user}) {
    const [valid, setValid] = useState(false);
    const [state, setState] = useState({
        password: "",
        confirm: ""
    });

    const [open, setOpen] = React.useState(false);
    const dispatch = useDispatch();
    const handleClickOpen = () => {
        setOpen(true);

    };
    const handleChange = (event) => {
        setState({
            ...state, [event.target.name]: event.target.value
        });
    };
    const handleClose = () => {
        setOpen(false);
    };
    const handleDone = () => {
        dispatch(editePassword(user.email, state.password));
        setOpen(false);
    }
    useEffect(() => {
        if (!open)
            setState({
                password: "",
                confirm: ""
            })
    }, [open])
    useEffect(() => {
        setValid(state.password.length > 5 && state.password === state.confirm);
    }, [state])
    return (
        <div>
            <Button variant="contained" color="primary" onClick={handleClickOpen}>
                Modifier Mot De Passe
            </Button>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle id="alert-dialog-slide-title">{"Modifier Mot de pass"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        <Grid container spacing={3}>
                            <Grid item xs={12} spacing={3}>
                                <FormControl fullWidth>
                                    <TextField
                                        type="password"
                                        onChange={handleChange}
                                        fullWidth
                                        id={"password"}
                                        name={"password"}
                                        label={"Mot De passe"}
                                        value={state.password}
                                        variant="outlined"
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} spacing={3}>
                                <FormControl fullWidth>
                                    <TextField
                                        type="password"
                                        onChange={handleChange}
                                        fullWidth
                                        id={"confirm"}
                                        name={"confirm"}
                                        label={"Confirmation Mot De passe"}
                                        value={state.confirm}
                                        variant="outlined"
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>

                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Annuler
                    </Button>
                    <Button disabled={!valid} onClick={handleDone} color="primary" variant={"contained"}>
                        Sauvgarder
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

const fields = [
    {generated: false, id: "nom", label: "Nom", xs: 6},
    {generated: false, id: "prenom", label: "Prenom", xs: 6},
    {generated: false, id: "email", label: "Email", xs: 6}
]
const useStyles = makeStyles((theme) => ({
    fab: {
        margin: theme.spacing(2),
    },
    absolute: {
        position: 'absolute',
        bottom: theme.spacing(2),
        right: theme.spacing(3),
    },
}));

export function UserAdd() {
    const {roles} = useSelector(state => state.admin);
    const [isValid, setValid] = useState(false);
    const classes = useStyles();
    const [state, setState] = React.useState({
        nom: "",
        prenom: "",
        email: "",
        role: "USER"
    });
    const [open, setOpen] = React.useState(false);
    const dispatch = useDispatch();
    const handleChange = (event) => {
        setState({
            ...state,
            [event.target.name]: event.target.value
        });
    };
    const handleClickOpen = () => {
        setOpen(true);
        setState({
            nom: "",
            prenom: "",
            email: "",
            role: "USER"
        });
    }

    useEffect(() => {
        //  const x = state.rs.length > 3 & state.adresse.length > 3 & state.rc.length > 3 & state.ice.length > 3 & state.rib.length > 3 & state.bank != null & state.agence.length > 3 & state.responsable.length > 3
        setValid(true);
    }, [state]);
    const handleClose = () => {
        setOpen(false);
    };

    const handleDone = () => {
        dispatch(addUser(state));
        setOpen(false);
    }
    return (<div>

        <Tooltip title="Add" aria-label="add" onClick={handleClickOpen}>
            <Fab color="secondary" className={classes.absolute}>
                CRÉER
            </Fab>
        </Tooltip>

        <Dialog open={open}
                onClose={handleClose}
                maxWidth={"lg"}
                fullWidth
                aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Nouveau Utilisateur</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Remplir les informations suivantes
                </DialogContentText>
                <Grid container spacing={3} justify={"center"}>
                    {fields.map(o => {
                        return (!o.generated) && (
                            <Grid key={o.id} item xs={o.xs}>
                                <FormControl fullWidth>
                                    <TextField
                                        InputProps={{
                                            readOnly: o.generated,
                                        }}
                                        onChange={handleChange}
                                        fullWidth
                                        id={o.id}
                                        name={o.id}
                                        label={o.label}
                                        value={state[o.id]}
                                        variant="outlined"
                                    />
                                </FormControl>
                            </Grid>
                        );
                    })}
                    <Grid item xs={6}>
                        <FormControl fullWidth>
                            <Autocomplete
                                fullWidth
                                value={roles.find(x => x.id === state.role)}
                                onChange={(event, newValue) => {
                                    setState({...state, role: newValue.id});
                                }}
                                options={roles}
                                getOptionLabel={(option) => {
                                    return option != null ? option.label : ""
                                }}
                                renderInput={(params) =>
                                    <TextField {...params}
                                               fullWidth
                                               label="Role"
                                               variant="outlined"
                                    />}
                            />
                        </FormControl>
                    </Grid>

                </Grid>

            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Annuler
                </Button>
                <Button
                    onClick={handleDone}
                    color="primary"
                    variant={"contained"}
                    disabled={!isValid}>
                    Sauvgarder
                </Button>
            </DialogActions>
        </Dialog>
    </div>);
}

export function UpdateUser({user}) {
    const {roles} = useSelector(state => state.admin);
    const [isValid, setValid] = useState(false);
    const [state, setState] = React.useState(user);
    const [open, setOpen] = React.useState(false);
    const dispatch = useDispatch();
    const handleChange = (event) => {
        setState({
            ...state,
            [event.target.name]: event.target.value
        });
    };
    const handleClickOpen = () => {
        setOpen(true);
        setState({...user, old: user.email});
    }

    useEffect(() => {
        setValid(true);
    }, [state]);
    const handleClose = () => {
        setOpen(false);
    };

    const handleDone = () => {
        dispatch(updateUser(state.old, state));
        setOpen(false);
    }
    return (<div>
        <Button color={"primary"} variant={"outlined"} onClick={handleClickOpen}>Modifier</Button>
        <Dialog open={open}
                onClose={handleClose}
                maxWidth={"lg"}
                fullWidth
                aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Nouveau Utilisateur</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Remplir les informations suivantes
                </DialogContentText>
                <Grid container spacing={3} justify={"center"}>
                    {fields.map(o => {
                        return (!o.generated) && (
                            <Grid key={o.id} item xs={o.xs}>
                                <FormControl fullWidth>
                                    <TextField
                                        InputProps={{
                                            readOnly: o.generated,
                                        }}
                                        onChange={handleChange}
                                        fullWidth
                                        id={o.id}
                                        name={o.id}
                                        label={o.label}
                                        value={state[o.id]}
                                        variant="outlined"
                                    />
                                </FormControl>
                            </Grid>
                        );
                    })}
                    <Grid item xs={6}>
                        <FormControl fullWidth>
                            <Autocomplete
                                fullWidth
                                value={roles.find(x => x.id === state.role)}
                                onChange={(event, newValue) => {
                                    setState({...state, role: newValue.id});
                                }}
                                options={roles}
                                getOptionLabel={(option) => {
                                    return option != null ? option.label : ""
                                }}
                                renderInput={(params) =>
                                    <TextField {...params}
                                               fullWidth
                                               label="Role"
                                               variant="outlined"
                                    />}
                            />
                        </FormControl>
                    </Grid>

                </Grid>

            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Annuler
                </Button>
                <Button
                    onClick={handleDone}
                    color="primary"
                    variant={"contained"}
                    disabled={!isValid}>
                    Sauvgarder
                </Button>
            </DialogActions>
        </Dialog>
    </div>);
}
