import React, {useEffect, useState} from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from "@material-ui/core/Grid";
import {useDispatch, useSelector} from "react-redux";
import FormControl from "@material-ui/core/FormControl";
import AddIcon from '@material-ui/icons/Add';
import Tooltip from "@material-ui/core/Tooltip";
import Fab from "@material-ui/core/Fab";
import {makeStyles} from "@material-ui/core";
import {addPrest} from "../../redux/actions/prestActions";
import Autocomplete from "@material-ui/lab/Autocomplete";

const fields = [
    {generated: true, id: "id", label: "ID", xs: 6},
    {generated: false, id: "rs", label: "RS", xs: 6},
    {generated: false, id: "adresse", label: "ADRESSE", xs: 6},
    {generated: false, id: "patente", label: "PATENTE", xs: 6},
    {generated: false, id: "rc", label: "RC", xs: 6},
    {generated: false, id: "ife", label: "IF", xs: 6},
    {generated: false, id: "ice", label: "ICE", xs: 6},
    {generated: false, id: "cnss", label: "CNSS", xs: 6},
    {generated: false, id: "rib", label: "RIB", xs: 6},
    {generated: false, id: "responsable", label: "RESPONSABLE", xs: 12},
]
const useStyles = makeStyles((theme) => ({
    fab: {
        margin: theme.spacing(2),
    },
    absolute: {
        position: 'absolute',
        bottom: theme.spacing(2),
        right: theme.spacing(3),
    },
}));
export default function PrestAdd({simple}) {
    const {types: natures} = useSelector(state => state.depenses)
    const {banks} = useSelector(state => state.prestataires);
    const [isValid, setValid] = useState(false);
    const classes = useStyles();
    const [state, setState] = React.useState({
        id: "",
        rs: "",
        adresse: "",
        patente: "",
        rc: "",
        ife: "",
        ice: "",
        cnss: "",
        rib: "",
        bank: "",
        agence: "",
        responsable: "",
    });
    const [open, setOpen] = React.useState(false);
    const dispatch = useDispatch();
    const handleChange = (event) => {
        setState({
            ...state,
            [event.target.name]: event.target.value
        });
    };
    const handleClickOpen = () => {
        setOpen(true);
        setState({
            id: "",
            rs: "",
            adresse: "",
            patente: "",
            rc: "",
            ife: "",
            ice: "",
            cnss: "",
            rib: "",
            bank: null,
            prestations: [],
            agence: "",
            responsable: "",
        });
    }

    useEffect(() => {
        const x = state.rs.length > 3 & state.adresse.length > 3 & state.rc.length > 3 & state.ice.length > 3 & state.rib.length > 3 & state.bank != null & state.agence.length > 3 & state.responsable.length > 3
        setValid(x);
    }, [state]);
    const handleClose = () => {
        setOpen(false);
    };

    const handleDone = () => {
        dispatch(addPrest(state));
        setOpen(false);
    }
    return (<div>
        {simple ? (
                <Button onClick={handleClickOpen} variant={"outlined"}>
                    Ajouter
                </Button>) :
            (<Tooltip title="Add" aria-label="add" onClick={handleClickOpen}>
                <Fab color="secondary" className={classes.absolute}>
                    <AddIcon/>
                </Fab>
            </Tooltip>)
        }
        <Dialog open={open}
                onClose={handleClose}
                maxWidth={"lg"}
                fullWidth
                aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Nouveau prestataire</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Remplir les informations suivantes
                </DialogContentText>
                <Grid container spacing={3} justify={"center"}>
                    <Grid item xs={12}>
                        <Autocomplete
                            getOptionDisabled={() => (state.prestations.length > 1)}
                            multiple
                            id="tags-outlined"
                            options={natures}
                            getOptionLabel={(option) => option.label}
                            defaultValue={[]}
                            onChange={(event, newValue) => {
                                setState({
                                    ...state,
                                    prestations: newValue
                                });
                            }}
                            filterSelectedOptions
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="outlined"
                                    label="Prestations"
                                    placeholder="Nature"
                                />
                            )}
                        />
                    </Grid>
                    {fields.map(o => {
                        return (!o.generated) && (
                            <Grid key={o.id} item xs={o.xs}>
                                <FormControl fullWidth>
                                    <TextField
                                        InputProps={{
                                            readOnly: o.generated,
                                        }}
                                        onChange={handleChange}
                                        fullWidth
                                        id={o.id}
                                        name={o.id}
                                        label={o.label}
                                        value={state[o.id]}
                                        variant="outlined"
                                    />
                                </FormControl>
                            </Grid>
                        );
                    })}
                    <Grid item xs={6}>
                        <FormControl fullWidth>
                            <Autocomplete
                                fullWidth
                                value={state.bank}
                                onChange={(event, newValue) => {
                                    setState({...state, bank: newValue});
                                }}
                                options={banks}
                                getOptionLabel={(option) => {
                                    return option != null ? "(" + option.code + ") - " + option.name : ""
                                }}
                                renderInput={(params) =>
                                    <TextField {...params}
                                               fullWidth
                                               label="Banque"
                                               variant="outlined"
                                    />}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl fullWidth>
                            <TextField
                                onChange={handleChange}
                                fullWidth
                                id={"agence"}
                                name={"agence"}
                                value={state.agence}
                                variant="outlined"
                                label={"Agence Bancaire"}
                            />
                        </FormControl>
                    </Grid>
                </Grid>

            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Annuler
                </Button>
                <Button
                    onClick={handleDone}
                    color="primary"
                    variant={"contained"}
                    disabled={!isValid}>
                    Ajouter
                </Button>
            </DialogActions>
        </Dialog>
    </div>);
}
