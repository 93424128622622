import {Button, Grid, Paper} from "@material-ui/core";
import Header from "../../components/general/Header";
import React, {useEffect, useState} from "react";
import {fetchDEPENSEs, updateDep} from "../../redux/actions/depenseActions";
import {useDispatch, useSelector} from "react-redux";
import DataTable from "../../components/app/DataTable";
import {floatFormat, NumberFormatter} from "../../appConfig/chiffres";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import FormControl from "@material-ui/core/FormControl";
import Box from "@material-ui/core/Box";
import {DEPENSE_BASE_URL} from "../../appConfig/apiConfig";
import {handleDownload} from "../../redux/actions/actions";

function createData(o) {
    return {
        ...o,
        prix: floatFormat(o.price),
        engagement: floatFormat(o.price + o.price / 100)
    }
}

export default function Suivi() {
    const dispatch = useDispatch();
    const [rows, setRows] = useState([]);
    const depenses = useSelector(state => state.depenses.page.content)
    useEffect(() => {
        dispatch(fetchDEPENSEs({}));
    }, []);
    useEffect(() => {
        console.log(depenses)
        setRows(depenses.filter(o => o.rubricID ));
    }, [depenses])
    return (
        <Paper>
            <Header name={"Suivi d'execution "}/>
            <Grid item xs={12}>
                <DataTable
                    title={"Actions sur les Depenses"}
                    height={300}
                    columns={[
                        {field: "id", hide: true},
                        {
                            field: "num",
                            headerName: "Nº",
                            width: 80
                        }, {
                            field: "objet",
                            headerName: "Objet",
                            width: 300
                        }, {
                            field: "prix",
                            headerName: "Mantant",
                            type: 'number',
                            width: 200

                        }, {
                            field: "dateDebut",
                            headerName: "Date Debut",
                            sortable: false,
                            width: 200,
                            renderCell: (row) => {
                                return row.value ? (new Date(row.value).toLocaleDateString()) : "-";
                            }
                        }, {
                            field: "q",
                            headerName: "Actions",
                            sortable: false,
                            width: 350,
                            renderCell: (row) => {
                                return row.row.dateDebut ? (
                                    <Grid container>
                                        <Grid item xs={6}>
                                            <Button variant={"outlined"} color={"primary"}
                                                    onClick={() => handleDownload(DEPENSE_BASE_URL + "/" + row.row.id + "/service?service=true", "Ordre_service" + row.row.num + ".pdf")}>
                                                Ordre service
                                            </Button>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Button
                                                variant={"outlined"}
                                                color={"primary"}
                                                onClick={() => handleDownload(DEPENSE_BASE_URL + "/" + row.row.id + "/service?service=false", "notifications_" + row.row.num + ".pdf")}>
                                                Notification
                                            </Button>
                                        </Grid>
                                    </Grid>

                                ) : <ServiceDialog depense={row.row}/>;
                            }
                        }
                    ]}
                    toolbar={true}
                    rows={rows.map(o => createData(o))}/>
            </Grid>
        </Paper>)
}

function ServiceDialog({depense}) {
    const [open, setOpen] = useState(false);
    const dispatch = useDispatch();
    const handleOpen = () => {
        setOpen(true)
    }
    const [state, setState] = useState({});
    useEffect(() => {
        setState({
            ...depense,
            dateDebut: new Date()
        });
    }, [depense])

    const handleClose = () => {
        setOpen(false);
    }
    const orderService = () => {
        dispatch(updateDep(state))
    }
    return (
        <Box>
            <Button onClick={() => handleOpen()} variant={"outlined"} color={"primary"}>
                Confirmation OS</Button>
            <Dialog
                open={open}
                keepMounted
                onClose={handleClose}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle id="alert-dialog-slide-title">{"Validation"}</DialogTitle>
                <DialogContent>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <Grid container justify={"center"}>
                            <Grid item xs={10}>
                                <FormControl fullWidth size={"small"}>
                                    <KeyboardDatePicker
                                        variant="inline"
                                        format="dd-MM-yyyy"
                                        label="Date debut"
                                        value={state.dateDebut}
                                        onChange={(date) => setState({
                                            ...state,
                                            dateDebut: date
                                        })}
                                        KeyboardButtonProps={{
                                            'aria-label': 'change date',
                                        }}
                                        inputVariant="outlined"
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                    </MuiPickersUtilsProvider>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Annuler
                    </Button>
                    <Button onClick={orderService} color="primary" variant={"contained"}>
                        Confirmer
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>)
}

function PauseDialog({depense}) {

}
