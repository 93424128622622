import {onError, onFetch} from "./prestActions";
import axios from "axios";
import {PAYMENT_REPORTING_URL} from "../../appConfig/apiConfig";
import {getAuth} from "./budgetActions";

export const FETCH_PAYMENT_REPORTING_SUCCESS = "FETCH_PAYMENT_REPORTING_SUCCESS"

export function fetchPaymentsReport(year, rubric) {
    return async dispatch => {
        onFetch(true);

        function onSuccess(result) {
            return dispatch({type: FETCH_PAYMENT_REPORTING_SUCCESS, payload: result.data});
        }

        await axios.get(`${PAYMENT_REPORTING_URL}/${year}/payments/${rubric}`, {auth: getAuth().auth})
            .then(result => {
                return onSuccess(result)
            })
            .catch(error => {
                return onError(error)
            })
            .finally(() => {
                onFetch(false)
            });
    }
}
