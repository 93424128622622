import React, {useEffect} from "react";
import {Button, Grid} from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import Paper from "@material-ui/core/Paper";
import {Link} from 'react-router-dom';
import {fetchRubric} from "../redux/actions/budgetActions";
import {useDispatch, useSelector} from "react-redux";
import YearSelectOption from "./YearSelectOption";
import {floatFormat, NumberFormatter} from "../appConfig/chiffres";

function EquilibreRow({rub}) {
    return (
        <TableRow>
            <TableCell align="left">
                {rub.name}
            </TableCell>
            <TableCell colSpan={1} align="right">{floatFormat(rub.totalCredit + rub.totalRest)}</TableCell>
        </TableRow>
    )
}

export default function Equilibre() {
    const dispatch = useDispatch();
    const year = useSelector(state => state.budgets.year);
    const  editable= useSelector(state => state.authentication.role)==="USER";
    const rubrics = useSelector(state => state.budgets.fullList.filter(o => o.parentID === 0));
    console.log(rubrics)
    useEffect(() => {
        dispatch(fetchRubric(year));
    }, [year]);

    return (
        <Paper>
            <Grid container spacing={1} justify={"center"}>
                <Grid item xs={8}>
                    <h2 align="center">Tableau Récapitulatif des Prévisions des Emplois et Ressources du Budget de
                        l'INCVT au titre de {year}</h2>
                </Grid>
                <Grid item xs={4}>
                    <YearSelectOption editable={!editable}/>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <Paper>
                                <Table aria-label="caption table">
                                    <TableHead>
                                        <TableRow style={{backgroundColor: 'lightBlue'}}>
                                            <TableCell align="left">Emplois</TableCell>
                                            <TableCell align="right">Crédits de paiement</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell colSpan={2} align="center" style={{backgroundColor: 'yellow'}}>CHARGES
                                                D'EXPLOITATION</TableCell>
                                        </TableRow>
                                        {rubrics.filter(o => o.rubricType === "FONCTIONNENT").map(o =>
                                            (<EquilibreRow key={o.id} rub={o}/>))}
                                        <TableRow style={{backgroundColor: 'lightBlue'}}>
                                            <TableCell align="left">Total</TableCell>
                                            <TableCell colSpan={1}
                                                       align="right">
                                                {floatFormat(
                                                    rubrics
                                                        .filter(o => o.rubricType === "FONCTIONNENT")
                                                        .map(o => o.totalCredit + o.totalRest).reduce((a, b) => a + b, 0))}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell colSpan={2} align="center" style={{backgroundColor: 'yellow'}}>CHARGES
                                                D'INVESTISSEMENT</TableCell>
                                        </TableRow>
                                        {rubrics.filter(o => o.rubricType === "INVESTISSEMENT").map(o =>
                                            (<EquilibreRow key={o.id} rub={o}/>))}
                                        <TableRow style={{backgroundColor: 'lightBlue'}}>
                                            <TableCell align="left">Total</TableCell>
                                            <TableCell colSpan={1}
                                                       align="right">
                                                {floatFormat(
                                                    rubrics
                                                        .filter(o => o.rubricType === "INVESTISSEMENT")
                                                        .map(o => o.totalCredit + o.totalRest).reduce((a, b) => a + b, 0))}</TableCell>
                                        </TableRow>
                                        <TableRow style={{backgroundColor: 'yellow'}}>
                                            <TableCell align="left">Total budget {year}</TableCell>
                                            <TableCell colSpan={1} align="right">
                                                {floatFormat(
                                                    rubrics
                                                        .filter(o => o.rubricType === "INVESTISSEMENT" || o.rubricType === "FONCTIONNENT")
                                                        .map(o => o.totalCredit + o.totalRest).reduce((a, b) => a + b, 0))}
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </Paper>
                        </Grid>
                        <Grid item xs={6}>
                            <Paper>
                                <Table aria-label="caption table">
                                    <TableHead>
                                        <TableRow style={{backgroundColor: 'lightBlue'}}>
                                            <TableCell align="left">Ressources</TableCell>
                                            <TableCell align="right">Crédits de paiement</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {rubrics.filter(o => o.rubricType === "RESOURCE").map(o =>
                                            (<EquilibreRow key={o.id} rub={o}/>))}
                                        <TableRow style={{backgroundColor: 'yellow'}}>
                                            <TableCell align="left">Total budget {year}</TableCell>
                                            <TableCell colSpan={1} align="right"> {floatFormat(
                                                rubrics
                                                    .filter(o => o.rubricType === "RESOURCE")
                                                    .map(o => o.totalCredit + o.totalRest).reduce((a, b) => a + b, 0))}</TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </Paper>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container justify={"flex-end"}>
                        <Grid item>
                            <Button variant={"outlined"} color={"primary"} component={Link}
                                    to={"/budget/details"}>Details</Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Paper>
    )
}
