import {ERROR_GENERATED, FETCH_USERS_SUCCESS, LOADING_STATUS_CHANGED} from "./constants";
import axios from "axios";
import {getBaseURL, USERS_BASE_URL} from "../../appConfig/apiConfig";
import {getAuth} from "./budgetActions";
import {onError, onFetch} from "./prestActions";
import {login} from "./actions";

export function fetchUsers() {
    return async dispatch => {
        function onSuccess(success) {
            const object = {
                type: FETCH_USERS_SUCCESS, payload: success
            };
            dispatch(object);
            return success;
        }

        function onError(error) {
            dispatch({type: ERROR_GENERATED, error});
            return error;
        }

        function onFetch(loading) {
            dispatch({type: LOADING_STATUS_CHANGED, payload: loading});
        }

        onFetch(true);
        try {
            const success = await axios.get(USERS_BASE_URL, {auth: getAuth().auth});
            onFetch(false);
            return onSuccess(success.data);

        } catch (error) {
            onFetch(false);
            return onError(error);
        }
    }
}

export function addUser(data) {
    return async dispatch => {
        function onSuccess(result) {
            dispatch(fetchUsers());
            return result;
        }

        onFetch(true);
        try {
            console.log(data)
            const result = await axios.post(USERS_BASE_URL, JSON.stringify(data), {
                auth: getAuth().auth,
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            onFetch(false);
            return onSuccess(result);
        } catch (error) {
            onFetch(false);
            onSuccess();
            return onError(error);
        }
    }
}

export function updateUser(username, data) {
    return async dispatch => {
        function onSuccess(result) {
            dispatch(fetchUsers());
            return result;
        }

        onFetch(true);
        try {
            const result = await axios.post(USERS_BASE_URL + '/' + username, JSON.stringify(data), {
                auth: getAuth().auth,
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            onFetch(false);
            return onSuccess(result);
        } catch (error) {
            onFetch(false);
            onSuccess();
            return onError(error);
        }
    }
}
export function updatePassword(email, newpassword) {
    return async dispatch => {
        function onSuccess(result) {
            dispatch(login(email, newpassword));
            return result;
        }

        onFetch(true);
        try {
            const result = await axios.post(
                getBaseURL() + '/password',
                null,
                {
                    auth: getAuth().auth,
                    params: {
                        newpassword
                    }
                });
            onFetch(false);
            return onSuccess(result);
        } catch (error) {
            onFetch(false);
            onSuccess();
            return onError(error);
        }
    }
}
export function deleteUser(username){
        return async dispatch => {
            function onSuccess(result) {
                dispatch(fetchUsers());
                return result;
            }

            onFetch(true);
            try {
                const result = await axios.delete(USERS_BASE_URL + '/' + username, {
                    auth: getAuth().auth
                });
                onFetch(false);
                return onSuccess(result);
            } catch (error) {
                onFetch(false);
                onSuccess();
                return onError(error);
            }
        }

}

