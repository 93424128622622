export const DepenseType = [
    {
        id: "BC",
        label: "BON DE COMMANDE",
        groups: [
            {id: 1, label: "Travaux"},
            {id: 2, label: "Fournitures"},
            {id: 3, label: "Services"},
        ],
        natures: [
            {
                group: 1,
                groupLabel: "1",
                id: 1,
                label: "Travaux d'aménagement, d'entretien et de réparation des bâtiments administratifs"
            },
            {group: 2, groupLabel: "1", id: 2, label: "Fournitures de bureau"},
            {group: 2, groupLabel: "1", id: 3, label: "Fournitures pour matériel technique et informatique"},
            {
                group: 2,
                groupLabel: "1",
                id: 4,
                label: "Imprimés, prestations d'impression, de reproduction et de photographie"
            },
            {group: 2, groupLabel: "1", id: 5, label: "Matériel de bureau"},
            {group: 2, groupLabel: "1", id: 6, label: "Matériel informatique, pièces de rechange et logiciels"},
            {group: 2, groupLabel: "1", id: 7, label: "Matériel technique"},
            {group: 2, groupLabel: "1", id: 8, label: "Mobilier du bureau"},
            {group: 2, groupLabel: "1", id: 9, label: "Outillage et quincaillerie"},
            {group: 3, groupLabel: "1", id: 10, label: "Entretien des logiciels et pro-logiciels"},
            {group: 3, groupLabel: "1", id: 11, label: "Entretien et réparation de matériel et de mobilier"},
            {group: 3, groupLabel: "1", id: 12, label: "Etudes, conseil et formation"},
            {group: 3, groupLabel: "1", id: 13, label: "Hôtellerie, hébergement, réception et restauration"},
            {
                group: 3,
                groupLabel: "1",
                id: 14,
                label: "Organisation de manifestations culturelles, scientifiques et sportives"
            },
            {group: 3, groupLabel: "1", id: 15, label: "prestations de gardiennage des bâtiments administratifs}"}

        ]

    },
    {
        id: "CONTRAT", label: "CONTRAT", groups: [{id: 1, label: "*"}], natures: [
            {group: 1, groupLabel: "*", id: 1, label: "Abonnement aux réseaux de télécommunication"},
            {group: 1, groupLabel: "*", id: 2, label: "Abonnement aux services internet"},
            {group: 1, groupLabel: "*", id: 3, label: "Hébergement et infogérance des systèmes d’information"},
            {group: 1, groupLabel: "*", id: 4, label: "Gestion du paiement multicanal"},
            {
                group: 1,
                groupLabel: "*",
                id: 5,
                label: "Complété par arrêté du ministre de l’économie et des finances n° 1002-15 du 20 joumada I 1436 (11 mars 2015) – achat, développement, production ou coproduction de programmes destinés à la diffusion par des organismes de radiodiffusion et de télévision et du temps de diffusion"
            },
            {group: 1, groupLabel: "*", id: 6, label: "Mandats légaux"},
            {
                group: 1,
                groupLabel: "*",
                id: 7,
                label: "Prestations de formation nécessitant des compétences ou expertises particulières"
            },
            {group: 1, groupLabel: "*", id: 8, label: "Achat de véhicules et d’engins"},
            {
                group: 1,
                groupLabel: "*",
                id: 9,
                label: "Acquisition de vignettes pour l'achat de carburant, lubrifiant et réparation du parc automobile de l'Etat"
            },
            {
                group: 1,
                groupLabel: "*",
                id: 10,
                label: "Acquisition des vignettes pour frais de transport du personnel à l'intérieur du Royaume du Maroc "
            },
            {group: 1, groupLabel: "*", id: 11, label: "Prestations postales et frais d'affranchissement"},
            {group: 1, groupLabel: "*", id: 12, label: "Actes d'achat ou de location d'immeubles"},
            {
                group: 1,
                groupLabel: "*",
                id: 13,
                label: "Assurance des véhicules du parc automobile des administrations publiques"
            },
            {group: 1, groupLabel: "*", id: 14, label: "Hôtellerie et restauration"},
            {
                group: 1,
                groupLabel: "*",
                id: 15,
                label: "Prestations de branchement d’électricité, d’eau potable et d’assainissement effectuées par l’Office national de l’électricité et de l’eau potable, les régies de distribution d’eau et de l’électricité et les sociétés délégataires du service public relatif aux prestations précitées."
            },
        ]
    },
    {
        id: "CDC", label: "CONVENTION DROIT COMMUN", groups: [{id: 1, label: "*"}], natures: [
            {group: 1, groupLabel: "*", id: 1, label: "Abonnement aux réseaux de télécommunication"},
            {group: 1, groupLabel: "*", id: 2, label: "Abonnement aux services internet"},
            {group: 1, groupLabel: "*", id: 3, label: "Hébergement et infogérance des systèmes d’information"},
            {group: 1, groupLabel: "*", id: 4, label: "Gestion du paiement multicanal"},
            {
                group: 1,
                groupLabel: "*",
                id: 5,
                label: "Complété par arrêté du ministre de l’économie et des finances n° 1002-15 du 20 joumada I 1436 (11 mars 2015) – achat, développement, production ou coproduction de programmes destinés à la diffusion par des organismes de radiodiffusion et de télévision et du temps de diffusion"
            },
            {group: 1, groupLabel: "*", id: 6, label: "Mandats légaux"},
            {
                group: 1,
                groupLabel: "*",
                id: 7,
                label: "Prestations de formation nécessitant des compétences ou expertises particulières"
            },
            {group: 1, groupLabel: "*", id: 8, label: "Achat de véhicules et d’engins"},
            {
                group: 1,
                groupLabel: "*",
                id: 9,
                label: "Acquisition de vignettes pour l'achat de carburant, lubrifiant et réparation du parc automobile de l'Etat"
            },
            {
                group: 1,
                groupLabel: "*",
                id: 10,
                label: "Acquisition des vignettes pour frais de transport du personnel à l'intérieur du Royaume du Maroc "
            },
            {group: 1, groupLabel: "*", id: 11, label: "Prestations postales et frais d'affranchissement"},
            {group: 1, groupLabel: "*", id: 12, label: "Actes d'achat ou de location d'immeubles"},
            {
                group: 1,
                groupLabel: "*",
                id: 13,
                label: "Assurance des véhicules du parc automobile des administrations publiques"
            },
            {group: 1, groupLabel: "*", id: 14, label: "Hôtellerie et restauration"},
            {
                group: 1,
                groupLabel: "*",
                id: 15,
                label: "Prestations de branchement d’électricité, d’eau potable et d’assainissement effectuées par l’Office national de l’électricité et de l’eau potable, les régies de distribution d’eau et de l’électricité et les sociétés délégataires du service public relatif aux prestations précitées."
            },
        ]
    },
    {id: "Marchée", label: "MARCHÉ PUBLIC", groups: [], natures: []},
    {id: "Autre", label: "", groups: [], natures: []},
]
export const UNITES = ["U", "F", "JOUR", "HEUR", "M²"]
export const initialStore = {
    token: null,
    email: null
};

export const authReducer = (state = initialStore, action) => {
    switch (action.type) {
        case "LOGIN/SUCCESS":
            return {
                ...state,
                token: action.data.token,
                email: action.data.email,
                role: action.data.role,
                user: action.data.user
            };
        case "LOGOUT/SUCCESS":
            return {...state, token: null, email: null};
        case "REGISTER":
            return {
                ...state,
                firstName: action.data.firstName,
                lastName: action.data.lastName,
                email: action.data.email,
                role: action.data.role,
                phone: action.data.phone,
                password: action.data.password
            };
        default:
            return state;
    }
};




