import {useDispatch, useSelector} from "react-redux";
import React, {useEffect, useState} from "react";
import {fetchInvantaires} from "../../redux/actions/payamentActions";
import {Button, Grid, Paper} from "@material-ui/core";
import DataTable from "../../components/app/DataTable";
import {floatFormat} from "../../appConfig/chiffres";
import Header from "../../components/general/Header";

export function Inventaire() {
    const rows = useSelector(state => state.stock.inventaires)
    const [data, setData] = useState([]);
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(fetchInvantaires())
    }, [])
    useEffect(() => {
        setData(
            rows.map(o => {
                const dep = o.depense.num + "/" + new Date(o.depense.dateSignature).getFullYear()
                return {
                    ...o,
                    dep: dep,
                    code: o.id + "BC" + dep,
                    price: floatFormat(o.article.totalTTC),
                    dateBuy: new Date(o.buyDate).toLocaleDateString()
                }
            }));
    }, [rows])
    return (
        <Paper>
            <Header name={"Inventaires"}/>
            <Grid container justify={"center"} spacing={3}>
                <Grid item xs={10}>
                    <DataTable
                        title={"Selectionner des article pour decompte"}
                        height={650}
                        columns={
                            [
                                {
                                    field: "id",
                                    hide: true
                                },
                                {
                                    field: "code",
                                    headerName: "Code",
                                    width: 350
                                },
                                {
                                    field: "dateBuy",
                                    type: "Date",
                                    headerName: "Date d'achat",
                                    width: 200
                                },
                                {
                                    field: "price",
                                    type: "number",
                                    headerName: "Prix en DH",
                                    width: 200
                                },
                                {
                                    field: "dep",
                                    headerName: "Depense",
                                    width: 200
                                }, {
                                field: "affecter",
                                width: 200,
                                headerName: "Action",
                                renderCell: () => {
                                    return (
                                        <Button variant={"outlined"} color={"primary"}>Affecter</Button>)
                                }
                            }
                            ]
                        }
                        toolbar={true}
                        rows={data}
                    />
                </Grid>
            </Grid>
        </Paper>
    );
}
