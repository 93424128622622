import {
    Box,
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormControlLabel,
    Grid,
    Paper,
    TextField
} from "@material-ui/core";
import SelectRubric from "../../components/SelectRubric";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {fetchRubric} from "../../redux/actions/budgetActions";
import Header from "../../components/general/Header";
import {floatFormat} from "../../appConfig/chiffres";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {cancelEngage, engageDep, fetchDEPENSEs} from "../../redux/actions/depenseActions";
import DataTable from "../../components/app/DataTable";
import {Transition} from "../PrestataireV2";
import PaymentType from "./PaymentType";

export function EngagementCreate() {
    const editable = useSelector(state => state.authentication.role) === "USER";

    const [problem, setProblem] = useState(true);
    const year = useSelector(state => state.budgets.year);
    const rub = useSelector(state => state.budgets.list);
    const [state, setState] = useState({
        depense: undefined,
        os: false
    });
    const dispatch = useDispatch();
    const rows = useSelector(state => state.depenses.page.content.filter(o => o.rubricID === 0))
    const [rubric, setRubric] = useState(null);
    useEffect(() => {
        state.rubric && rub && rub.length && setRubric(rub.filter(o => o.id === state.rubric)[0]);
    }, [state, rub]);
    const [open, setOpen] = useState(false)
    useEffect(() => {
        dispatch(fetchRubric(year));
        dispatch(fetchDEPENSEs({}));
    }, [year, open]);
    useEffect(() => {
        setProblem(state.depense === undefined || state.depense == null || rubric === undefined || rubric == null ||
            state.depense.price > (rubric.credit - rubric.engaged))
    }, [state, rubric])
    const handleDone = (result) => {
        if (result)
            dispatch(engageDep(state.depense.id, {
                rubricID: state.rubric,
                payment: state.payment,
                os: state.payment === "ordinaire" && state.os
            }))
        setOpen(false)
    }
    const handleOpen = () => {
        setOpen(true)
    }
    return (
        <Box>
            {!editable && (<Grid container justify={"flex-end"} spacing={3}>
                <Grid item xs={2}>
                    <Button fullWidth size={"small"} color={"primary"} variant={"outlined"}
                            onClick={handleOpen}>
                        Engager
                    </Button>
                </Grid>
            </Grid>)}
            <Dialog
                fullWidth
                maxWidth={"lg"}
                open={open}
                TransitionComponent={Transition}
                onClose={() => handleDone(false)}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle id="alert-dialog-slide-title">{"Engager une depense"}</DialogTitle>
                <DialogContent>
                    <Grid container spacing={3}>
                        <Grid item xs={4}>
                            <FormControl fullWidth>
                                <Autocomplete
                                    fullWidth
                                    value={state.depense}
                                    onChange={(event, newValue) => {
                                        setState({
                                            ...state,
                                            depense: newValue
                                        })
                                    }}
                                    options={rows}
                                    getOptionLabel={(option) => {
                                        return option.depenseType + " : " +
                                            option.num + "/" + new Date(option.dateSignature).getFullYear()
                                    }}
                                    renderInput={(params) =>
                                        <TextField {...params}
                                                   margin={"dense"}
                                                   fullWidth
                                                   label="Depense"
                                                   variant="outlined"
                                        />}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={2}>
                            <TextField
                                label={"Montant"}
                                fullWidth
                                margin={"dense"}
                                variant={"outlined"}
                                value={floatFormat(state.depense ? state.depense.price : 0)}/>
                        </Grid>
                        <Grid item xs={4}>
                            <PaymentType handleChange={t => {
                                if (t)
                                    setState({
                                        ...state,
                                        payment: t.value
                                    });
                                else
                                    setState({
                                        ...state,
                                        payment: undefined
                                    });

                            }}/>
                        </Grid>
                        {state.payment === "ordinaire" && (<Grid item xs={2}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={state.os}
                                        onChange={(e) => {
                                            setState({
                                                ...state,
                                                os: e.target.checked
                                            })
                                        }
                                        }
                                        color="primary"
                                    />
                                }
                                label="OS"
                            />
                        </Grid>)}
                        <Grid item xs={6}>
                            <TextField
                                label={"Rubric"}
                                fullWidth
                                margin={"dense"}
                                variant={"outlined"}
                                value={rubric ? rubric.rubricType + " " + rubric.year + " : " + rubric.name : ""}
                                InputProps={{
                                    endAdornment: (
                                        <React.Fragment>
                                            <SelectRubric setRubric={(value) => {
                                                setState({...state, rubric: value});
                                            }}/>
                                        </React.Fragment>
                                    ),
                                }}/>
                        </Grid>
                        <Grid item xs={2}>
                            <TextField
                                label={"Credit disponible"}
                                fullWidth
                                margin={"dense"}
                                variant={"outlined"}
                                value={floatFormat(rubric ? rubric.credit - rubric.engaged : 0)}/>
                        </Grid>
                        <Grid item xs={2}>
                            <TextField
                                label={"Credit engagé"}
                                fullWidth
                                margin={"dense"}
                                variant={"outlined"}
                                value={floatFormat(rubric ? rubric.engaged : 0)}/>
                        </Grid>
                        <Grid item xs={2}>
                            <TextField
                                label={"Credit Total en DH"}
                                fullWidth
                                margin={"dense"}
                                variant={"outlined"}
                                value={floatFormat(rubric ? rubric.credit : 0)}/>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => handleDone(false)} color="primary">
                        Annuler
                    </Button>
                    <Button disabled={problem} onClick={() => handleDone(true)} color="primary" variant={"contained"}>
                        Engager
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    )
}

export default function Engagement() {
    const year = useSelector(state => state.budgets.year);
    const dispatch = useDispatch();
    const depense = useSelector(state => state.depenses.page.content)
    const rub = useSelector(state => state.budgets.list);
    const [rows, setRows] = useState([]);
    useEffect(() => {
        setRows(depense.filter(o => o.rubricID !== 0).map(o => ({
            id: o.id,
            objet: o.objet,
            dateSignature: new Date(o.dateSignature),
            num: o.num + "/" + new Date(o.dateSignature).getFullYear(),
            prest: o.prestataire.rs,
            rubric: rub.filter(r => r.id === o.rubricID)[0]?.name,
            totalTTC: floatFormat(o.price)

        })))
    }, [depense, rub])
    const cancel = (id) => {
        dispatch(cancelEngage(id));
    }
    useEffect(() => {
        dispatch(fetchRubric(year));
        dispatch(fetchDEPENSEs({}));
    }, [year]);
    return (
        <Paper>
            <Header name={"Engagement des depense"}/>
            <Grid container justify={"center"} spacing={3}>
                <Grid item xs={11}>
                    <EngagementCreate/>
                </Grid>
                <Grid item xs={11}>
                    <box padding={3}>
                        <DataTable
                            checkboxSelection={false}
                            title={"Selectionner des article pour decompte"}
                            height={300}
                            columns={[
                                {
                                    field: "num",
                                    headerName: "Nº"
                                }, {
                                    field: "objet",
                                    headerName: "Objet",
                                    width: 300
                                }, {
                                    field: "dateSignature",
                                    headerName: "Date de signature",
                                    type: "date",
                                    width: 130

                                }, {
                                    field: "prest",
                                    headerName: "Prestataire",
                                    width: 200
                                }, {
                                    field: "rubric",
                                    headerName: "Rubrique",
                                    width: 200
                                }, {
                                    field: "totalTTC",
                                    headerName: "Montant",
                                    type: 'number',
                                    width: 200

                                },
                                {
                                    field: "id",
                                    headerName: "Action",
                                    sortable: false,
                                    width: 200,
                                    renderCell: (row) => {

                                        return <Button variant={"outlined"} color={"primary"}
                                                       onClick={() => cancel(row.value)}>Annuler</Button>;
                                    }
                                }
                            ]}
                            toolbar=
                                {
                                    true
                                }
                            rows=
                                {
                                    rows
                                }
                        />
                    </box>
                </Grid>
            </Grid>
        </Paper>
    )
}
