import React, {useEffect, useState} from "react";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import {useDispatch, useSelector} from "react-redux";
import MUIDataTable from "mui-datatables";
import Button from "@material-ui/core/Button";
import {Link} from "react-router-dom";
import {deleteDEPENSEs, fetchDEPENSEs, fetchTypes} from "../redux/actions/depenseActions";
import DepenseCreate from "./depense/DepenseCreate";
import {fetchPRESTs} from "../redux/actions/prestActions";


const getData = (depense) => {
    return [depense.id,
        depense.num,
        depense.depenseType,
        depense.prestataire.rs,
        depense.objet,
        depense.dateSignature.toLocaleString(),
        depense.articles.length + " Articles",
        depense.montant,
        depense.status
    ];

}
export default function DepensePage() {
    const role = useSelector(state => state.authentication.role)
    const editable = role === "USER";

    const [pagination, setPagination] = useState({
        page: 0,
        size: 10,
        fields: "id",
        direction: "asc"
    });
    const [data, setData] = useState([]);
    const columns = [
        {
            name: "id", label: "ID", options: {
                display: false,
            }
        },
        "num",
        "depenseType",
        "prestataire",
        "objet",
        "dateSignature",
        "articles",
        "montant",
        "status",
        {
            name: "Actions",
            options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <Button variant="outlined" color="secondary"
                                component={Link} to={"/depense/" + tableMeta.rowData[0]}>
                            {`Details`}
                        </Button>
                    );
                }
            }
        }];
    const dispatch = useDispatch();
    const rows = useSelector(state => state.depenses.page.content)
    useEffect(() => {
        dispatch(fetchPRESTs(pagination));
        dispatch(fetchTypes());
        dispatch(fetchDEPENSEs(pagination));
    }, [pagination,role]);
    const deleteHandle = (indeces) => {
        if (indeces.length > 0) {
            dispatch(deleteDEPENSEs(pagination, {ids: indeces.map(i => rows[i].id)}));
        }
    }
    useEffect(() => {
        setData(rows.map(p => getData(p)));
    }, [rows]);
    const options = {
        filterType: "dropdown",
        responsive: "scroll",
        print: false,
        onTableChange: (action, tableState) => {
            if (action === "sort")
                setPagination({
                    ...pagination,
                    direction: tableState.sortOrder.direction,
                    fields: tableState.sortOrder.name
                });
            if (action === "changePage") {
                setPagination({...pagination, page: tableState.page});
            }
        },
        onRowsDelete: (data) => {
            deleteHandle(data.data.map(o => o.dataIndex));
        }
    };
    return (<Paper>
        <Grid container>
            <Grid item xs={12}>
                <MUIDataTable
                    title={"LISTE DES DÉPENSES"}
                    data={data}
                    columns={columns}
                    options={options}
                />
            </Grid>
        </Grid>
        {!editable && (<DepenseCreate onFinish={() => dispatch(fetchDEPENSEs(pagination))}/>)}
    </Paper>);
}