import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    makeStyles,
    Paper,
    TextField
} from "@material-ui/core";
import Header from "../../components/general/Header";
import React, {useEffect, useState} from "react";
import Grid from "@material-ui/core/Grid";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {useDispatch, useSelector} from "react-redux";
import DataTable from "../../components/app/DataTable";
import {FactureForm} from "./DepenseCreate";
import {EleveDelete, Transition} from "../PrestataireV2";
import Tooltip from "@material-ui/core/Tooltip";
import {deleteFactures, fetchDEPENSEs, saveFacture} from "../../redux/actions/depenseActions";
import {floatFormat} from "../../appConfig/chiffres";
import MUIDataTable from "mui-datatables";

function createData(eleve) {
    return [
        eleve.id,
        eleve.num,
        eleve.dateExecution,
        eleve.price,
        eleve.articles.length
    ]
}

export function FactureTable({depense}) {
    const dispatch = useDispatch();
    const editable = useSelector(state => state.authentication.role) === "USER";
    const [data, setData] = useState([]);
    const [deleteI, setDeleteI] = useState([]);
    useEffect(() => {
        depense && depense.factures && setData(depense.factures.map(o => createData(o)));
    }, [depense])
    const columns = [
        {
            name: "id",
            label: "Id",
            hide: true
        },
        {
            name: "num",
            label: "Nº",
        }, {
            name: "dateExecution",
            label: "Date",
        }, {
            name: "price",
            label: "Montant",
        },
        {
            name: "nbr",
            label: "Articles"
            // },
            // {
            //     name: "Actions",
            //     options: {
            //         customBodyRender: (value, tableMeta, updateValue) => {
            //             const prest = content.find(o => o.id === tableMeta.rowData[0]);
            //             return (
            //                 <PrestUpdate prest={prest}/>
            //             );
            //         }
            //     }
        }
    ];

    const deleteHandle = (deleted) => {
        if (deleted) {
            dispatch(deleteFactures( {ids: deleteI.map(i => depense.factures[i].id)}));
        }
        setDeleteI([]);
    }
    const options = {
        selectableRows: !editable,
        filterType: "dropdown",
        responsive: "scroll",
        print: false,
        onRowsDelete: (data) => {
            setDeleteI(data.data.map(o => o.dataIndex));
        }
    };
    return (
        <Paper>
            <EleveDelete handleDone={deleteHandle} indexList={deleteI} open={deleteI.length > 0}/>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <MUIDataTable
                        title={"Liste des factures "}
                        data={data}
                        columns={columns}
                        options={options}
                    />
                </Grid>
            </Grid>
        </Paper>
    )
}

export function FactureList({depense}) {
    const [rows, setRows] = useState([])
    useEffect(() => {
        let data = []
        if (depense && depense.id)
            data = depense.factures.map(o => ({
                ...o,
                dateExecution: new Date(o.dateExecution).toLocaleDateString(),
                price: floatFormat(o.price),
                nbr: o.articles.length
            }))
        setRows(data);
    }, [depense]);
    return (
        <Grid container spacing={3}>
            {depense && depense.id &&
                (<Grid item xs={12}>
                    <DataTable
                        title={"Selectionner des article pour decompte"}
                        height={300}
                        columns={[
                            {
                                field: "id",
                                hide: true
                            },
                            {
                                field: "num",
                                headerName: "Nº",
                                width: 120
                            }, {
                                field: "dateExecution",
                                headerName: "Date",
                                width: 120
                            }, {
                                field: "price",
                                headerName: "Montant",
                                type: 'number',
                                width: 200
                            },
                            {
                                field: "nbr",
                                headerName: "Articles",
                                type: 'number',
                                width: 200
                            }
                        ]}
                        toolbar={true}
                        rows={rows}
                    />
                </Grid>)
            }
        </Grid>
    )
}

export default function Facture() {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(fetchDEPENSEs());
    }, [])
    const [depenses, setDepenses] = useState([]);
    const rows = useSelector(state => state.depenses.page.content)

    useEffect(() => {
        setDepenses(rows.filter(o => o.additional))
        // setState()
    }, [rows])
    const [state, setState] = useState({});
    console.log(state, depenses);
    return (
        <Paper>
            <Header name={"Factures"}/>
            <Grid container spacing={3} justify={"center"}>
                <Grid item xs={4}>
                    <FormControl fullWidth>
                        <Autocomplete
                            fullWidth
                            value={state.depense}
                            onChange={(event, newValue) => {
                                setState({
                                    ...state,
                                    depense: newValue
                                })
                            }}
                            options={depenses}
                            getOptionLabel={(option) => {
                                return option.depenseType + " : "
                                    + option.num + "/"
                                    + new Date(option.dateSignature).getFullYear()
                            }}
                            renderInput={(params) =>
                                <TextField {...params} margin={"dense"} fullWidth label="Depense"
                                           variant="outlined"/>}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={3}>
                    <TextField
                        label={"Prestataire"}
                        fullWidth
                        margin={"dense"}
                        variant={"outlined"}
                        value={state.depense ? state.depense.prestataire.rs : ""}/>
                </Grid>
                <Grid item xs={10}>
                    <TextField
                        label={"Objet"}
                        fullWidth
                        multiline
                        rows={2}
                        margin={"dense"}
                        variant={"outlined"}
                        value={state.depense ? state.depense.objet : ""}/>
                </Grid>
                <Grid item xs={10}>
                    <FactureTable depense={state.depense}/>
                </Grid>
            </Grid>
            {state && state.depense && state.depense.id && (<NewFactureDialog depense={state.depense}/>)}
        </Paper>
    )
}
const useStyles = makeStyles((theme) => ({
    fab: {
        margin: theme.spacing(5),
    },
    absolute: {
        position: 'absolute',
        bottom: theme.spacing(2),
        right: theme.spacing(3),
    },
}));


export function NewFactureDialog({depense}) {
    const dispatch = useDispatch();
    const classes = useStyles();
    const [open, setOpen] = useState(false)
    const [state, setState] = useState({
        facture: {
            nbr: 1,
            dateExecution: new Date()
        }
    });
    const handleDone = (save) => {
        if (save) {
            dispatch(saveFacture({...state.facture, depenseID: depense.id}))
        }
        setState({
            facture: {
                nbr: 1,
                dateExecution: new Date()
            }
        });
        setOpen(false)
    }
    const handleOpen = () => {
        setOpen(true)
    }
    return (
        <Box>
            <Dialog
                fullWidth
                maxWidth={"lg"}
                open={open}
                TransitionComponent={Transition}
                onClose={() => handleDone(false)}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle id="alert-dialog-slide-title">{"Creation de Facture"}</DialogTitle>
                <DialogContent>

                    <FactureForm depense={state.facture} setDepense={(value) => setState({
                        ...state,
                        facture: value
                    })}/>

                </DialogContent>
                <DialogActions>
                    <Button onClick={() => handleDone(false)} color="primary">
                        Annuler
                    </Button>
                    <Button onClick={() => handleDone(true)} color="primary">
                        Ajouter
                    </Button>
                </DialogActions>
            </Dialog>
            <Tooltip title="Creation" aria-label="creation" onClick={handleOpen}>
                <Button color="secondary" className={classes.absolute} variant={"outlined"}>
                    Creation
                </Button>
            </Tooltip>
        </Box>
    )
}
