import React, {useEffect} from 'react';
import PrestAdd from "../components/prest/PrestAdd";
import PrestataireV2 from "./PrestataireV2";
import Paper from "@material-ui/core/Paper";
import {useDispatch, useSelector} from "react-redux";
import {fetchBanks, fetchPRESTs} from "../redux/actions/prestActions";

export default function Prestataire() {

    const  editable= useSelector(state => state.authentication.role)==="USER";
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(fetchPRESTs());
        dispatch(fetchBanks());
    }, [])
    return (
        <Paper>
            <PrestataireV2/>
            {!editable && (<PrestAdd/>)}
        </Paper>
    );
}
