import {CREATE_PAYMENT_SUCCESS} from "../actions/payamentActions";
import {
    ENGAGED_ADD_SUCCESS,
    ENGAGED_DELETE_SUCCESS,
    FETCH_DEPENSE_SUCCESS,
    FETCH_TYPES_SUCCESS,
    SAVE_FACTURE_SUCCESS
} from "../actions/constants";

const initDepense = {
    page: {
        "content": [],
        "pageable": {
            "sort": {
                "sorted": false,
                "unsorted": false,
                "empty": false
            },
            "offset": 0,
            "pageNumber": 0,
            "pageSize": 2147483647,
            "paged": true,
            "unpaged": false
        },
        "last": true,
        "totalElements": 3,
        "totalPages": 1,
        "number": 0,
        "size": 2147483647,
        "sort": {
            "sorted": true,
            "unsorted": false,
            "empty": false
        },
        "first": true,
        "numberOfElements": 3,
        "empty": false
    },
    types: [{
        "id": 50,
        "createAt": "2021-01-26T15:13:38.402277",
        "updateAt": "2021-01-26T15:13:38.402277",
        "label": "prestations de gardiennage des bâtiments administratifs}",
        "section": {
            "id": 35,
            "createAt": "2021-01-26T15:13:38.125066",
            "updateAt": "2021-01-26T15:13:38.125066",
            "label": "SERVICES",
            "type": {
                "id": 32,
                "createAt": "2021-01-26T15:13:38.077092",
                "updateAt": "2021-01-26T15:13:38.077092",
                "label": "BON DE COMMANDE",
                "lastOne": "2021-01-01",
                "name": "type"
            },
            "name": "section"
        },
        "name": "nature"
    }],
    marcheTypes: [
        {name: 'UNIQUE', id: 'UNIQUE'},
        {name: 'RECONDUCTIBLE', id: 'RECONDUCTIBLE'},
        {name: 'NEGOCIE', id: 'NEGOCIE'},
        {name: 'ALLOTI', id: 'ALLOTI'},
        {name: 'CADRE', id: 'CADRE'}
    ],
}
export const depensesReducer = (state = initDepense, action) => {
    switch (action.type) {
        case ENGAGED_DELETE_SUCCESS:
            return {
                ...state, page: {
                    ...state.page,
                    content: [...state.page.content.map(o => {
                        return o.id === action.payload ? {...o, rubricID: 0} : o
                    })]
                }
            }
        case CREATE_PAYMENT_SUCCESS:
            return {
                ...state,
                page: {
                    ...state.page,
                    content: [...state.page.content.filter(o => o.id !== action.payload.depense.id), action.payload.depense]
                }
            }
        case ENGAGED_ADD_SUCCESS:
            return {
                ...state,
                page: {
                    ...state.page,
                    content: [...state.page.content.filter(o => o.id !== action.payload.id), action.payload]
                }
            }
        case SAVE_FACTURE_SUCCESS:
            let dep = state.page.content.filter(o => o.id === action.payload.depenseID)[0]
            dep.factures.push(...action.payload.data)
            //dep = {...dep, factures: [...dep.factures, ...action.payload.data]}
            console.log(dep);
            return {
                ...state,
                page: {
                    ...state.page,
                    content: [...state.page.content.filter(o => o.id !== action.payload.depenseID), dep]
                }
            }
        case FETCH_DEPENSE_SUCCESS:
            return {
                ...state,
                page: action.payload
            }
        case FETCH_TYPES_SUCCESS:
            return {
                ...state,
                types: action.payload
            }
        default:
            return state;
    }
}
