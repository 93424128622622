import axios from "axios";
import {DEPENSE_BASE_URL, TYPES_BASE_URL} from "../../appConfig/apiConfig";
import qs from "qs";
import {onError, onFetch} from "./prestActions";
import {
    ENGAGED_ADD_SUCCESS,
    ENGAGED_DELETE_SUCCESS,
    ERROR_GENERATED,
    FETCH_DEPENSE_SUCCESS,
    FETCH_TYPES_SUCCESS,
    LOADING_STATUS_CHANGED,
    SAVE_FACTURE_SUCCESS
} from "./constants";
import {getAuth} from "./budgetActions";

export function fetchTypes() {
    return async dispatch => {
        function onSuccess(success) {
            dispatch({type: FETCH_TYPES_SUCCESS, payload: success});
            return success;
        }

        function onError(error) {
            dispatch({type: ERROR_GENERATED, error});
            return error;
        }

        function onFetch(loading) {
            dispatch({type: LOADING_STATUS_CHANGED, payload: loading});
        }

        onFetch(true);
        try {
            const success = await axios.get(TYPES_BASE_URL, {
                auth: getAuth().auth
            });
            onFetch(false);
            return onSuccess(success.data);
        } catch (error) {
            onFetch(false);
            return onError(error);
        }
    }
}

export function deleteFactures(ids) {
    return async dispatch => {
        function onSuccess() {
            return dispatch(fetchDEPENSEs());
        }

        onFetch(true);
        try {
            await axios.delete(DEPENSE_BASE_URL + "/facture", {
                params: ids,
                auth: getAuth().auth
                ,
                paramsSerializer: ids => {
                    return qs.stringify(ids, {arrayFormat: 'repeat'})
                }
            });
            onFetch(false);
            return onSuccess();
        } catch (error) {
            onFetch(false);
            onSuccess();
            return onError(error);
        }
    }
}

export function fetchDEPENSEs(params) {
    return async dispatch => {
        function onSuccess(success) {
            dispatch({type: FETCH_DEPENSE_SUCCESS, payload: success});
            return success;
        }

        function onError(error) {
            dispatch({type: ERROR_GENERATED, error});
            return error;
        }

        function onFetch(loading) {
            dispatch({type: LOADING_STATUS_CHANGED, payload: loading});
        }

        onFetch(true);
        try {
            const success = await axios.get(DEPENSE_BASE_URL, {auth: getAuth().auth});
            onFetch(false);
            return onSuccess(success.data);
        } catch (error) {
            onFetch(false);
            return onError(error);
        }
    }
}

export function updateDep(data) {
    return async dispatch => {
        function onSuccess(result) {
            return dispatch({type: ENGAGED_ADD_SUCCESS, payload: result.data});
        }
        // createFiles(data.id);
        
        onFetch(true);
        try {
            const result = await axios.put(DEPENSE_BASE_URL, JSON.stringify({
                ...data
            }), {
                headers: {
                    'Content-Type': 'application/json'
                },
                auth: getAuth().auth
            });
            onFetch(false);
            // TODO add uploadfile and test
            //uploadFiles(data.id, {...data}, true);
            return onSuccess(result);
        } catch (error) {
            onFetch(false);
            onSuccess();
            return onError(error);
        }
    }
}

export function addNature(data) {
    return async dispatch => {
        function onSuccess(result) {
            dispatch(fetchTypes());
            return result;
        }

        onFetch(true);
        try {
            const result = await axios.post(TYPES_BASE_URL, JSON.stringify({
                ...data,
            }), {
                auth: getAuth().auth,
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            onFetch(false);
            return onSuccess(result);
        } catch (error) {
            onFetch(false);
            onSuccess();
            return onError(error);
        }
    }
}

export function saveDep(data) {
    return async dispatch => {
        function onSuccess(result) {
            return result;
        }

        onFetch(true);
        try {
            const result = await axios.post(DEPENSE_BASE_URL, JSON.stringify({
                ...data,
            }), {
                auth: getAuth().auth,
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            onFetch(false);
            return onSuccess(result);
        } catch (error) {
            onFetch(false);
            onSuccess();
            return onError(error);
        }
    }
}

export function saveFacture(facture) {
    return async dispatch => {
        function onSuccess(result) {
            return dispatch({
                type: SAVE_FACTURE_SUCCESS, payload: {
                    depenseID: facture.depenseID,
                    data: result.data
                }
            });
        }

        onFetch(true);
        try {
            const result = await axios.post(`${DEPENSE_BASE_URL}/${facture.depenseID}/facture`, JSON.stringify({
                ...facture,
            }), {
                headers: {
                    'Content-Type': 'application/json'
                },
                auth: getAuth().auth
            });
            onFetch(false);
            return onSuccess(result);
        } catch (error) {
            onFetch(false);
            return onError(error);
        }
    }
}

export function deleteDEPENSEs(params, ids) {
    return async dispatch => {
        function onSuccess() {
            return dispatch(fetchDEPENSEs(params));
        }

        onFetch(true);
        try {
            await axios.delete(DEPENSE_BASE_URL, {
                params: ids,
                auth: getAuth().auth,
                paramsSerializer: ids => {
                    return qs.stringify(ids, {arrayFormat: 'repeat'})
                }
            });
            onFetch(false);
            return onSuccess();
        } catch (error) {
            onFetch(false);
            onSuccess();
            return onError(error);
        }
    }
}

export function uploadFiles(num, formData, uploadProgress) {
    return axios.post(DEPENSE_BASE_URL + "/" + num + "/upload", formData, {
        headers: {
            "Content-Type": "multipart/form-data",
        },
        auth: getAuth().auth,
        onUploadProgress: uploadProgress
    });
}

export function createFiles(num) {
    return 
    const success =  axios.get(DEPENSE_BASE_URL + "/" + num + "/service", {auth: getAuth().auth});
   /*  axios.get(DEPENSE_BASE_URL + "/" + num + "/service", formData, {
        headers: {
            "Content-Type": "multipart/form-data",
        },
        auth: getAuth().auth,
        onUploadProgress: uploadProgress
    }); */
}

export function engageDep(idDep, data) {
    return async dispatch => {
        onFetch(true);

        function onSuccess(result) {
            return dispatch({type: ENGAGED_ADD_SUCCESS, payload: result.data});
        }

        await axios.post(DEPENSE_BASE_URL + `/${idDep}`, data, {
            auth: getAuth().auth
        }).then(result => {
            return onSuccess(result)
        }).catch(error => {
            return onError(error)
        }).finally(() => {
            onFetch(false)
        });

    }
}

export function cancelEngage(idDep) {
    return async dispatch => {
        onFetch(true);

        function onSuccess() {
            return dispatch({type: ENGAGED_DELETE_SUCCESS, payload: idDep});
        }

        await axios.delete(DEPENSE_BASE_URL + `/${idDep}/engage`, {
            auth: getAuth().auth
        })
            .then(result => {
                return onSuccess(result)
            })
            .catch(error => {
                return onError(error)
            })
            .finally(() => {
                onFetch(false)
            });

    }
}
