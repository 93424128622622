import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    Grid,
    MenuItem,
    Select,
    TextField
} from "@material-ui/core";
import DataTable from "../../components/app/DataTable";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Transition} from "../PrestataireV2";
import {fetchDEPENSEs} from "../../redux/actions/depenseActions";
import {Autocomplete} from "@material-ui/lab";
import {
    fetchPayments,
    fetchStocks,
    fetchTransfers,
    savePayments,
    saveStock,
    saveVirments
} from "../../redux/actions/payamentActions";
import {floatFormat, trans} from "../../appConfig/chiffres";
import DateFnsUtils from "@date-io/date-fns";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import {PAYMENT_BASE_URL, TRANSFER_BASE_URL} from "../../appConfig/apiConfig";
import {handleDownload} from "../../redux/actions/actions";


function OpDialog({type}) {
    const depenses = useSelector(state => state.depenses.page.content);
    const [rows, setRows] = useState([]);
    useEffect(() => {
        const oldArray = depenses
            .filter(o => o.payment && o.payment === type)
            .map(o => ([...o.factures.map(f => ({
                ...f,
                depNum: o.depenseType + " : " +
                    o.num + "/" + new Date(o.dateSignature).getFullYear() + " - " + f.num
            })), o]))
        const data = Array.prototype.concat.apply([], oldArray);
        setRows(data.filter(o => {
            const result = o.articles.reduce((a, b) => {
                return a + b.quantite - b.delivered
            }, 0) > 0;
            return result;
        }));
    }, [depenses, type]);
    const dispatch = useDispatch();
    const [state, setState] = useState({
        dateExecution: new Date(),
        depense: {}
    });
    const [articles, setArticles] = useState([]);
    useEffect(() => {
        state.depense && console.table(state.depense.articles);
        setArticles(state.depense && state.depense.articles ? state.depense.articles
            .filter(o => o.delivered < o.quantite)
            .map(o => ({
                ...o,
                qtt: o.quantite - o.delivered,
                unitPrice: o.prix + o.prix * o.tva / 100
            })) : []);
        if (state && state.depense)
            dispatch(fetchStocks({natureID: state.depense.typeNature}))


    }, [state]);
    const storedChangeHandle = (id, value) => {
        setArticles(articles.map(o => ({
                ...o,
                stored: id === o.id ? value : o.stored
            })
        ))
    }


    const stockChange = (id, value) => {
        setArticles(articles.map(o => ({
                ...o,
                qtt: id === o.id ? value : o.qtt
            })
        ))
    }
    const [open, setOpen] = useState(false)
    useEffect(() => {
        dispatch(fetchDEPENSEs({}));
    }, []);
    const handleDone = (result) => {
        if (result) {
            const price = articles.filter(o => o.qtt && o.qtt > 0).map(o => "permanent" === state.depense.payment ? o.prix + o.prix * o.tva / 100 : o.qtt * (o.prix + o.prix * o.tva / 100)).reduce((a, b) => {
                return a + b;
            }, 0);
            const order = {
                dateExecution: state.dateExecution,
                price: price,
                priceChars: trans(price),
                facture: state.facture,
                depenseID: state.depense.depenseID || state.depense.id,
                details: articles.filter(o => o.qtt && o.qtt > 0).map(o => ({
                    stored: o.stored,
                    articleID: o.id,
                    articleQTT: o.qtt
                })),
            }
            dispatch(savePayments(order))
        }
        setOpen(false)
    }
    const handleOpen = () => {
        setState({
            dateExecution: new Date(),
            depense: {}
        });
        setOpen(true)
    }
    return (
        <Box>
            <Dialog
                fullWidth
                maxWidth={"lg"}
                open={open}
                TransitionComponent={Transition}
                onClose={() => handleDone(false)}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle id="alert-dialog-slide-title">{"Creation d ordre de paiement"}</DialogTitle>
                <DialogContent>
                    <Grid container spacing={3}>
                        <Grid item xs={4}>
                            <FormControl fullWidth>
                                <Autocomplete
                                    fullWidth
                                    value={state.depense}
                                    onChange={(event, newValue) => {
                                        setState({
                                            ...state,
                                            depense: newValue,
                                            facture: newValue && newValue.depenseID && newValue.num
                                        })
                                    }}
                                    options={rows}
                                    getOptionLabel={(option) => {
                                        if (option.dateSignature)
                                            return option.depenseType + " : " +
                                                option.num + "/" + new Date(option.dateSignature).getFullYear()
                                        else return option.depNum

                                    }}
                                    renderInput={(params) =>
                                        <TextField {...params}
                                                   margin={"dense"}
                                                   fullWidth
                                                   label="Depense / facture"
                                                   variant="outlined"
                                        />}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={4}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <FormControl fullWidth size={"small"}>
                                    <KeyboardDatePicker
                                        variant="inline"
                                        format="dd-MM-yyyy"
                                        label="Date d'Execution"
                                        value={state.dateExecution}
                                        onChange={(date) => setState({
                                            ...state,
                                            dateExecution: date
                                        })}
                                        KeyboardButtonProps={{
                                            'aria-label': 'change date',
                                        }}
                                        inputVariant="outlined"
                                    />
                                </FormControl>
                            </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid item xs={4}>
                            <TextField
                                label={"Nº Facture"}
                                fullWidth
                                margin={"dense"}
                                variant={"outlined"}
                                value={state.facture}
                                onChange={event => setState({...state, facture: event.target.value})}
                            />


                        </Grid>

                        <Grid item xs={12}>
                            <DataTable
                                title={"Selectionner des article pour decompte"}
                                selectChange={array => {
                                    setState(
                                        {
                                            ...state,
                                            articles: array.map(id => state.depense.articles.filter(o => o.id === +id)[0])
                                        }
                                    )
                                }}
                                height={300}
                                columns={[
                                    {
                                        field: "id", hide: true
                                    }, {
                                        field: "num",
                                        headerName: "Nº",
                                        width: 80
                                    }, {
                                        field: "designation",
                                        headerName: "Designation",
                                        width: 300
                                    }, {
                                        field: "unitPrice",
                                        headerName: "Prix U TTC en DH",
                                        type: 'number',
                                        width: 200

                                    },
                                    {
                                        field: "delivered",
                                        headerName: "Qtt",
                                        width: 100,
                                        renderCell: (props) => {
                                            return (
                                                <FormControl fullWidth>
                                                    <Select
                                                        variant={"outlined"}
                                                        fullWidth
                                                        value={props.row.qtt ? props.row.qtt : 0}
                                                        onChange={(e) => stockChange(props.row.id, e.target.value)}
                                                    >
                                                        {Array.apply(null, {length: 1 + props.row.quantite - props.row.delivered}).map(Number.call, Number)
                                                            .map(item => <MenuItem value={item}> {item} </MenuItem>)}
                                                    </Select>
                                                </FormControl>)
                                        }
                                    },
                                    {
                                        field: "stored",
                                        headerName: "Stock",
                                        width: 222,
                                        renderCell: (props) => {
                                            return (
                                                <StockOptions state={props.row.stored}
                                                              setState={(value) => storedChangeHandle(props.row.id, value)}/>
                                            )
                                        }
                                    }
                                ]}
                                toolbar=
                                    {
                                        true
                                    }
                                rows=
                                    {
                                        articles
                                    }
                            />
                        </Grid>

                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => handleDone(false)} color="primary">
                        Annuler
                    </Button>
                    <Button onClick={() => handleDone(true)} color="primary">
                        Ajouter
                    </Button>
                </DialogActions>
            </Dialog>
            <Grid container justify={"flex-end"} spacing={3}>
                <Grid item xs={3}>
                    <Button variant={"outlined"} onClick={handleOpen} fullWidth color={"primary"}>
                        Creer
                    </Button>
                </Grid>
            </Grid>
        </Box>

    )
}

function OvDialog({type}) {
    const rows = useSelector(state => state.payments.ops)
    const dispatch = useDispatch();
    const [prests, setPrests] = useState([])
    const [ops, setOps] = useState([])
    const [payments, setPayments] = useState([])
    const [state, setState] = useState({dateExecution: new Date()});
    useEffect(() => {
        setState({...state, objet: payments?.[0]?.depense?.objet ? payments?.[0]?.depense?.objet : ""});
    }, [payments])
    const [prestataire, setPrestataire] = useState({
        prestataire: null
    });
    useEffect(() => {
        setPrests(rows.filter(o => !o.treated && o.depense.payment === type).map(o => o.depense.prestataire).filter((v, i, a) => a.findIndex(t => (t.id === v.id)) === i))
    }, [rows, type]);
    useEffect(() => {
        if (prestataire && prestataire.id)
            setOps(rows.filter(o => {
                return o.depense.prestataire.id === prestataire.id && !o.treated
            }).map(o => ({
                ...o, montant: floatFormat(o.price)
            })))
        else {
            setOps([])
        }
    }, [prestataire])
    const [open, setOpen] = useState(false)

    const handleDone = (result) => {
        if (result) {
            const total = payments.map(o => o.price).reduce((a, b) => a + b, 0)
            const o = {
                year: state.dateExecution.getFullYear(),
                dateExecution: state.dateExecution,
                prestataire: prestataire,
                price: total,
                priceChars: trans(total),
                payments: payments,
                objet: state.objet
            }
            dispatch(saveVirments(o))
        }
        setOpen(false)
    }
    const handleOpen = () => {
        setOpen(true)
    }
    return (
        <Box>
            <Dialog
                fullWidth
                maxWidth={"lg"}
                open={open}
                TransitionComponent={Transition}
                onClose={() => handleDone(false)}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle id="alert-dialog-slide-title">{"Creation d ordre de virment"}</DialogTitle>
                <DialogContent>
                    <Grid container spacing={3}>
                        <Grid item xs={6}>
                            <FormControl fullWidth>
                                <Autocomplete
                                    fullWidth
                                    value={prestataire}
                                    onChange={(event, newValue) => {
                                        setPrestataire(newValue)
                                    }}
                                    options={prests}
                                    getOptionLabel={(option) => {
                                        return option.rs
                                    }}
                                    renderInput={(params) =>
                                        <TextField {...params}
                                                   margin={"dense"}
                                                   fullWidth
                                                   label="Prestataire"
                                                   variant="outlined"
                                        />}
                                />
                            </FormControl>
                        </Grid>

                        <Grid item xs={6}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <FormControl fullWidth size={"small"}>
                                    <KeyboardDatePicker
                                        variant="inline"
                                        format="dd-MM-yyyy"
                                        label="Date d'Execution"
                                        value={state.dateExecution}
                                        onChange={(date) => setState({
                                            ...state,
                                            dateExecution: date
                                        })}
                                        KeyboardButtonProps={{
                                            'aria-label': 'change date',
                                        }}
                                        inputVariant="outlined"
                                    />
                                </FormControl>
                            </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label={"Objet"}
                                fullWidth
                                multiline
                                rows={2}
                                margin={"dense"}
                                variant={"outlined"}
                                value={state.objet}
                                onChange={event => setState({...state, objet: event.target.value})}
                            />


                        </Grid>
                        <Grid item xs={12}>
                            <DataTable
                                checkboxSelection={true}
                                title={"Selectionner des article pour decompte"}
                                selectChange={array => {
                                    setPayments(array.map(id => ops.filter(o => o.id === +id)[0]))
                                }}
                                height={300}
                                columns={
                                    [
                                        {
                                            field: "id",
                                            hide: true
                                        },
                                        {
                                            field: "num",
                                            headerName: "Nº",
                                            width: 80
                                        },
                                        {
                                            field: "montant",
                                            headerName: "Montant",
                                            width: 220
                                        },
                                    ]}
                                toolbar={true}
                                rows={ops}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => handleDone(false)} color="primary">
                        Annuler
                    </Button>
                    <Button onClick={() => handleDone(true)} color="primary">
                        Ajouter
                    </Button>
                </DialogActions>
            </Dialog>
            <Grid container justify={"flex-end"} spacing={3}>
                <Grid item xs={3}>
                    <Button variant={"outlined"} onClick={handleOpen} fullWidth color={"primary"}>
                        Creer
                    </Button>
                </Grid>
            </Grid>
        </Box>
    )
}

export default function Op({type}) {

    const editable = useSelector(state => state.authentication.role) === "USER";
    const payments = useSelector(state => state.payments.ops)

    const [ops, setOps] = useState([]);
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(fetchPayments())
    }, [])
    useEffect(() => {
        setOps(payments.filter(o => o.depense && o.depense.payment === type).map(
            o => {
                return {
                    ...o,
                    prestataire: o.depense.prestataire.rs,
                    totalTTC: floatFormat(o.price)
                }
            }
        ));
    }, [payments, type])
    return (
        <Grid container spacing={3}>
            {!editable && (<Grid item xs={12}>
                <OpDialog type={type}/>
            </Grid>)}
            <Grid item xs={12}>
                <DataTable
                    title={"Selectionner des article pour decompte"}
                    height={300}
                    columns={
                        [
                            {field: "id", hide: true},
                            {field: "num", headerName: "Nº", width: 80},
                            {field: "prestataire", headerName: "Prestataire", width: 300},
                            {field: "totalTTC", headerName: "Montant En DH", type: 'number', width: 200},
                            {field: "facture", headerName: "Nº Facture", width: 120},
                            {
                                field: "q",
                                headerName: "Actions",
                                sortable: false,
                                width: 350,
                                renderCell: (row) => {
                                    return (
                                        <Button variant={"outlined"} color={"primary"}
                                                onClick={() =>
                                                    handleDownload(PAYMENT_BASE_URL + "/" + row.row.id + "/doc",
                                                        "OP" + row.row.id + ".pdf")
                                                }>
                                            Edition Ordre de paiement
                                        </Button>
                                    )
                                }
                            }
                        ]
                    }
                    toolbar={true}
                    rows={ops}
                />
            </Grid>
        </Grid>)
}

export function Ov({type}) {
    const  editable= useSelector(state => state.authentication.role)==="USER";
    const payments = useSelector(state => state.payments.ovs)
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(fetchTransfers())
    }, [])
    const [ops, setOps] = useState([]);
    useEffect(() => {
        setOps(payments.filter(o => o.payments[0] && o.payments[0].depense && o.payments[0].depense.payment === type).map(
            o => {
                return {
                    ...o,
                    totalTTC: floatFormat(o.price),
                    prest: o.prestataire.rs
                }
            }
        ));
    }, [payments, type])
    return (
        <Grid container spacing={3}>
            {!editable&&(<Grid item xs={12}>
                <OvDialog type={type}/>
            </Grid>)}
            <Grid item xs={12}>
                <DataTable
                    title={"Selectionner des article pour decompte"}
                    height={300}
                    columns={[
                        {
                            field: "id",
                            hide: true
                        },
                        {
                            field: "num",
                            headerName: "Nº",
                            width: 80
                        }, {
                            field: "prest",
                            headerName: "Prestataire",
                            width: 300
                        }, {
                            field: "totalTTC",
                            headerName: "Montant",
                            type: 'number',
                            width: 200
                        },
                        {field: "facture", headerName: "Nº Facture", width: 250},
                        {
                            field: "q",
                            headerName: "Actions",
                            sortable: false,
                            width: 350,
                            renderCell: (row) => {
                                return (
                                    <Button variant={"outlined"} color={"primary"}

                                            onClick={() => handleDownload(TRANSFER_BASE_URL + "/" + row.row.id + "/doc",
                                                "OV" + row.row.id + ".pdf")}>
                                        Edition Ordre de virment
                                    </Button>
                                )
                            }
                        }
                    ]}
                    toolbar={true}
                    rows={ops}
                />
            </Grid>
        </Grid>
    )
}

export function StockOptions({state, setState}) {
    const list = useSelector(state => state.stock.types);
    const [type, setType] = useState({
        name: "Autre",
        id: "other"
    });
    useEffect(() => {
        if ((!state || !state?.trim()) && setState)
            setState("other")
        else
            setType(list.filter(o => o.id === state)[0])
    }, [state])
    return (
        <FormControl fullWidth>
            <Autocomplete
                fullWidth
                value={type}
                onChange={(event, newValue) => {
                    setState(newValue?.id);
                }}
                options={list}
                getOptionLabel={(option) => {
                    return option.name
                }}
                renderInput={(params) =>
                    <TextField {...params}

                               margin={"dense"}
                               fullWidth
                               label="Stock"
                               variant="outlined"
                    />}
            />
        </FormControl>)
}

export function GroupAdd({typeNature, unite}) {

    const editable = useSelector(state => state.authentication.role) === "USER";
    const dispatch = useDispatch();
    const [state, setState] = useState({
        unite: unite,
        typeNature: typeNature
    });
    const [open, setOpen] = useState(false);
    const handleClose = () => {
        dispatch(fetchStocks({natureID: typeNature}))
        setOpen(false);
    }
    const done = () => {
        dispatch(saveStock(state));
        handleClose();
    }
    return (
        <Box>
            {!editable && (<Button variant={"outlined"} color={"primary"} size={"small"}
                                   onClick={() => setOpen(true)}>Creer</Button>)}
            <Dialog open={open}
                    onClose={handleClose}
                    maxWidth={"lg"}
                    fullWidth
                    aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Ajouter Reference</DialogTitle>
                <DialogContent>
                    <Grid container spacing={3}>

                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <TextField
                                    onChange={
                                        e => setState({...state, name: e.target.value})
                                    }
                                    id={"name"}
                                    name={"name"}
                                    fullWidth
                                    label={"designation"}
                                    value={state.name}
                                    variant="outlined"
                                />
                            </FormControl>
                        </Grid>

                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <TextField
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    fullWidth
                                    label={"unite"}
                                    value={state.unite}
                                    variant="outlined"
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Annuler
                    </Button>
                    <Button onClick={done} color="primary" variant={"contained"}>
                        Sauvgarder
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    )
}

export function GroupOptions({state, setState, typeNature, unite}) {
    const list = useSelector(state => state.stock.stocks);
    const [type, setType] = useState(null);
    useEffect(() => {
        setType(list.filter(o => o.id === state)[0])
    }, [state])
    return (
        <FormControl fullWidth>
            <Autocomplete
                fullWidth
                value={type}
                onChange={(event, newValue) => {
                    setState(newValue?.id);
                }}
                options={list}
                getOptionLabel={(option) => {
                    return option.name
                }}
                renderInput={(params) =>
                    <TextField {...params}
                               margin={"dense"}
                               fullWidth
                               label="Group"
                               variant="outlined"
                               InputProps={{
                                   ...params.InputProps,
                                   endAdornment: (
                                       <React.Fragment>
                                           {params.InputProps.endAdornment}
                                           {<GroupAdd typeNature={typeNature} unite={unite}/>}
                                       </React.Fragment>
                                   ),
                               }}
                    />}
            />
        </FormControl>)
}
