import {applyMiddleware, combineReducers, compose, createStore} from "redux";
import {authReducer} from "../reducers/reducers.js";
import thunk from "redux-thunk";
import {budgetReducer} from "../reducers/budgetReducer";
import {prestRuducer} from "../reducers/prestReducer";
import {depensesReducer} from "../reducers/depenseReducer";
import {paymentReducer} from "../reducers/paymentReducer";
import {stockReducer} from "../reducers/stockReducer";
import {reportingReducer} from "../reducers/reportReducer";
import {adminReducer} from "../reducers/adminReducer";


const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
export const Reducer = combineReducers({
    authentication: authReducer,
    budgets: budgetReducer,
    payments: paymentReducer,
    reporting:reportingReducer,
    stock: stockReducer,
    prestataires: prestRuducer,
    depenses: depensesReducer,
    admin:adminReducer
});

export const store = createStore(Reducer, composeEnhancers(
    applyMiddleware(thunk)
));
