import {Button, Paper} from "@material-ui/core";
import Header from "../../components/general/Header";
import Grid from "@material-ui/core/Grid";
import TypeOptions from "../../components/depense/TypeOptions";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {fetchStocks} from "../../redux/actions/payamentActions";
import DataTable from "../../components/app/DataTable";

export function Stock() {
    const [state, setState] = useState({});
    const list = useSelector(state => state.stock.stocks)
    const dispatch = useDispatch();
    useEffect(() => {
        if (state.typeNature)
            dispatch(fetchStocks({natureID: state.typeNature}))
    }, [state,dispatch])
    return (
        <Paper>
            <Header name={"Stock"}/>
            <Grid container justify={"center"} spacing={3}>
                <Grid item xs={10}>
                    <TypeOptions
                        handleChange={(value) => {
                            setState({
                                ...state,
                                typeNature: value.id,
                                typeDepense: value.section.type
                            });
                        }}
                        typeNature={state.typeNature}
                    />
                </Grid>
                <Grid item xs={10}>
                    <DataTable
                        title={"Selectionner des article pour decompte"}
                        height={650}
                        columns={
                            [
                                {
                                    field: "id",
                                    hide: true
                                },
                                {
                                    field: "name",
                                    headerName: "Designation",
                                    width: 350
                                },
                                {
                                    field: "quantite",
                                    type: "number",
                                    headerName: "Quantite",
                                    width: 200
                                },
                                {
                                    field: "unite",
                                    headerName: "Unite",
                                    width: 200
                                }, {
                                field:"affecter",
                                width: 200,
                                headerName: "Action",
                                renderCell: (props) => {
                                    return (
                                        <Button variant={"outlined"} color={"primary"}>Decharger</Button>)
                                }
                            }
                            ]
                        }
                        toolbar={true}
                        rows={list}
                    />
                </Grid>
            </Grid>
        </Paper>
    )
}
