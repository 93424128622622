import React from "react";
import {BrowserRouter as Router, Link, Switch} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
// Pages
import Login from "./pages/Login";
import ForgotPassword from "./pages/Forgot";
import PrivateRoute, {PublicRoute} from "./components/PrivateRoute";
import Prestataire from "./pages/Prestataire";
import EquilibrePage from "./pages/EquilibrePage";
import CssBaseline from "@material-ui/core/CssBaseline";
import AppBar from "@material-ui/core/AppBar";
import clsx from "clsx";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import Typography from "@material-ui/core/Typography";
import Drawer from "@material-ui/core/Drawer";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import BubbleChartIcon from "@material-ui/icons/BubbleChart";
import ListItemText from "@material-ui/core/ListItemText";
import {makeStyles, useTheme} from "@material-ui/core/styles";
import BudgetPage from "./pages/BudgetPage";
import DepensePage from "./pages/DepensePage";
import DepenseDetailsPage from "./pages/DepenseDetailsPage";
import Engagement from "./pages/Engagement/Engagement";
import Suivi from "./pages/Engagement/Suivi";
import Payments from "./pages/Engagement/Payments";
import {Inventaire} from "./pages/stock/Inventaire";
import {Stock} from "./pages/stock/Stock";
import {Reporting} from "./pages/dashbord/Reporting";
import Facture from "./pages/depense/Facture";
import {login, logout as logoutAction} from "./redux/actions/actions";
import Administration from "./pages/management/Administration";
import Profile from "./pages/management/Profile";

const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    appBar: {
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: drawerWidth,
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
        justifyContent: 'flex-end',
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: -drawerWidth,
    },
    contentShift: {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
    },
}));

export function Auth() {
    const dispatch = useDispatch();
    const token = useSelector(state => state.authentication.token)
    return () => {
        if (token && token.length > 0) {
        } else {
            if (localStorage.getItem('user') !== null) {
                const user = JSON.parse(localStorage.getItem('user'));
                console.log(user)
                dispatch(login(user.username, user.password))
                return true;
            }
        }
        return token && token.length > 0;
    };
}

export default function App() {

    const user = useSelector(state => state.authentication);
    const hasAuth = Auth();
    const dispatch = useDispatch();
    const logout = () => {
        dispatch(logoutAction());
    }
    const classes = useStyles();
    const theme = useTheme();
    const [open, setOpen] = React.useState(false);

    const handleDrawerOpen = () => {
        setOpen(hasAuth());
    };
    const handleDrawerClose = () => {
        setOpen(false);
    };
    return (<Router>
            <div className={classes.root}>
                <CssBaseline/>
                <AppBar
                    position="fixed"
                    className={clsx(classes.appBar, {
                        [classes.appBarShift]: open,
                    })}
                >
                    <Toolbar>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            onClick={handleDrawerOpen}
                            edge="start"
                            className={clsx(classes.menuButton, open && classes.hide)}
                        >
                            <MenuIcon/>
                        </IconButton>
                        <Typography variant="h6" noWrap>
                            Gestion des Ressources Financières
                        </Typography>
                    </Toolbar>
                </AppBar>
                <Drawer
                    className={classes.drawer}
                    variant="persistent"
                    anchor="left"
                    open={open}
                    classes={{
                        paper: classes.drawerPaper,
                    }}
                >
                    <div className={classes.drawerHeader}>
                        <IconButton onClick={handleDrawerClose}>
                            {theme.direction === 'ltr' ? <ChevronLeftIcon/> : <ChevronRightIcon/>}
                        </IconButton>
                    </div>
                    <Divider/>
                    <List>
                        <ListItem button component={Link} to="/budget" selected>
                            <ListItemIcon> <BubbleChartIcon/></ListItemIcon>
                            <ListItemText primary={'Budget'}/>
                        </ListItem>
                        <ListItem button component={Link} to="/prestataires">
                            <ListItemIcon> <BubbleChartIcon/></ListItemIcon>
                            <ListItemText primary={'Prestataires'}/>
                        </ListItem>
                        <ListItem button component={Link} to="/depense">
                            <ListItemIcon> <BubbleChartIcon/></ListItemIcon>
                            <ListItemText primary={'Dépenses'}/>
                        </ListItem>
                        <ListItem button component={Link} to="/facture">
                            <ListItemIcon> <BubbleChartIcon/></ListItemIcon>
                            <ListItemText primary={"Facture"}/>
                        </ListItem>
                        <ListItem button component={Link} to="/engagement">
                            <ListItemIcon> <BubbleChartIcon/></ListItemIcon>
                            <ListItemText primary={'Engagement'}/>
                        </ListItem>
                        <ListItem button component={Link} to="/suivi">
                            <ListItemIcon> <BubbleChartIcon/></ListItemIcon>
                            <ListItemText primary={"Suivi d'exécution"}/>
                        </ListItem>
                        <ListItem button component={Link} to="/payments">
                            <ListItemIcon> <BubbleChartIcon/></ListItemIcon>
                            <ListItemText primary={"Paiement"}/>
                        </ListItem>
                        <ListItem button component={Link} to="/inventaires">
                            <ListItemIcon> <BubbleChartIcon/></ListItemIcon>
                            <ListItemText primary={"Inventaire"}/>
                        </ListItem>
                        <ListItem button component={Link} to="/stock">
                            <ListItemIcon> <BubbleChartIcon/></ListItemIcon>
                            <ListItemText primary={"Stock"}/>
                        </ListItem>
                        <ListItem button component={Link} to="/reporting">
                            <ListItemIcon> <BubbleChartIcon/></ListItemIcon>
                            <ListItemText primary={"Reporting"}/>
                        </ListItem>
                        {user.role === 'SUPER_ADMIN' && (<ListItem button component={Link} to="/admin">
                            <ListItemIcon> <BubbleChartIcon/></ListItemIcon>
                            <ListItemText primary={"Administration"}/>
                        </ListItem>)}
                        <ListItem button component={Link} to="/me">
                            <ListItemIcon> <BubbleChartIcon/></ListItemIcon>
                            <ListItemText primary={"Profile"}/>
                        </ListItem>
                        <ListItem button onClick={logout}>
                            <ListItemIcon> <BubbleChartIcon/></ListItemIcon>
                            <ListItemText primary={"Deconnecter"}/>
                        </ListItem>
                    </List>
                    <Divider/>
                </Drawer>
                <main
                    className={clsx(classes.content, {
                        [classes.contentShift]: open,
                    })}
                >
                    <div className={classes.drawerHeader}/>

                    <Switch className="full">
                        <PrivateRoute
                            exact
                            path="/"
                            component={EquilibrePage}
                            canActivate={hasAuth}
                        />
                        <PrivateRoute
                            exact
                            path="/budget"
                            component={EquilibrePage}
                            canActivate={hasAuth}
                        />
                        <PrivateRoute
                            exact
                            path="/budget/details"
                            component={BudgetPage}
                            canActivate={hasAuth}
                        />
                        <PrivateRoute
                            exact
                            path="/prestataires"
                            component={Prestataire}
                            canActivate={hasAuth}
                        />
                        <PrivateRoute
                            exact
                            path="/depense"
                            component={DepensePage}
                            canActivate={hasAuth}
                        />
                        <PrivateRoute
                            exact
                            path="/facture"
                            component={Facture}
                            canActivate={hasAuth}
                        />
                        <PrivateRoute
                            exact
                            path="/depense/:id"
                            component={DepenseDetailsPage}
                            canActivate={hasAuth}
                        />
                        <PrivateRoute
                            exact
                            path="/engagement"
                            component={Engagement}
                            canActivate={hasAuth}
                        />
                        <PrivateRoute
                            exact
                            path="/suivi"
                            component={Suivi}
                            canActivate={hasAuth}
                        />
                        <PrivateRoute
                            exact
                            path="/payments"
                            component={Payments}
                            canActivate={hasAuth}
                        />
                        <PrivateRoute
                            exact
                            path="/inventaires"
                            component={Inventaire} canActivate={hasAuth}
                        />
                        <PrivateRoute
                            exact
                            path="/stock"
                            component={Stock} canActivate={hasAuth}
                        />
                        <PrivateRoute
                            exact
                            path="/reporting"
                            component={Reporting} canActivate={hasAuth}
                        />
                        <PrivateRoute
                            exact
                            path="/me"
                            component={Profile} canActivate={hasAuth}
                        />
                        {user.role === 'SUPER_ADMIN' && (<PrivateRoute
                            exact
                            path="/admin"
                            component={Administration} canActivate={hasAuth}
                        />)}
                        <PublicRoute
                            exact path="/login"
                            component={Login}
                            canActivate={hasAuth}/>
                        <PublicRoute exact path="/forgot-password" component={ForgotPassword} canActivate={hasAuth}/>

                    </Switch>
                </main>
            </div>
        </Router>
    );

}

