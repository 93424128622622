import {useDispatch, useSelector} from "react-redux";
import React, {useEffect, useState} from "react";
import {changeType, changeSousType, fetchYears} from "../redux/actions/budgetActions";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import {RubricType} from "../redux/reducers/budgetReducer";
import {Grid} from "@material-ui/core";

export default function RubricTypeSelectOption() {
    const dispatch = useDispatch();
    const {rubricType} = useSelector(state => state.budgets);
    // const rubricSousType =  useSelector(event => event.target);
    const rubricSousType =  useSelector(state => state.budgets.rubricSousType);
    
    useEffect(() => {
        dispatch(fetchYears());
    }, []);
    const handleTypeChange = (event) => {
        dispatch(changeType(event.target.value));
    }
    const handleSousTypeChange = (event) => {
        dispatch(changeSousType(event.target.value));
    }
    const [showSousRubric, setShowSousRubric] = useState(false);
    const showSousType = () => setShowSousRubric(true);
    const hideSousType = () => setShowSousRubric(false);
    
    return (
        <Grid item xs={12}>      
            <Grid container>
                <FormControl fullWidth>
            
                    <InputLabel id="demo-simple-select-helper-label">Type</InputLabel>
                    <Select
                        labelId="demo-simple-select-helper-label"
                        id="type"
                        name="type"
                        value={rubricType}
                        onChange={handleTypeChange}>
                        <MenuItem onClick={showSousType} value={RubricType.FONCTIONNENT}>{RubricType.FONCTIONNENT}</MenuItem>
                        <MenuItem onClick={hideSousType} value={RubricType.INVESTISSEMENT}>{RubricType.INVESTISSEMENT}</MenuItem>
                        <MenuItem onClick={hideSousType} value={RubricType.RESOURCE}>{RubricType.RESOURCE}</MenuItem>
                    </Select>
                
                </FormControl>
            </Grid>

            {showSousRubric && (<Grid container>
                <FormControl fullWidth>

                    <InputLabel id="demo-simple-select-helper-label">Sous-type</InputLabel>
                    <Select
                        labelId="demo-simple-select-helper-label"
                        id="sousType"
                        name="sousType"
                        value={rubricSousType}
                        onChange={handleSousTypeChange}>
                        <MenuItem value={RubricType.DEPENSES_MDD}>DEPENSES MDD</MenuItem>
                        <MenuItem value={RubricType.DEPENSES_PERSONNEL}>DEPENSES DU PERSONNEL</MenuItem>
                    </Select>

                </FormControl>
            </Grid>)}

        </Grid>
     
    );
}