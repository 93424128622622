import axios from "axios";
import {BANK_BASE_URL, PREST_BASE_URL} from "../../appConfig/apiConfig";
import qs from "qs";
import {getAuth} from "./budgetActions";
import {ERROR_GENERATED, FETCH_BANK_SUCCESS, FETCH_PREST_SUCCESS, LOADING_STATUS_CHANGED} from "./constants";

export const onError = (error) => dispatch => {
    dispatch({type: ERROR_GENERATED, error});
    return error;
};
export const onFetch = (loading) => dispatch => {
    dispatch({type: LOADING_STATUS_CHANGED, payload: loading});
}

export function fetchPRESTs() {

    return async dispatch => {
        function onSuccess(success) {
            const object = {
                type: FETCH_PREST_SUCCESS, payload: success
            };
            dispatch(object);
            return success;
        }

        function onError(error) {
            dispatch({type: ERROR_GENERATED, error});
            return error;
        }

        function onFetch(loading) {
            dispatch({type: LOADING_STATUS_CHANGED, payload: loading});
        }

        onFetch(true);
        try {
            const success = await axios.get(PREST_BASE_URL, {auth: getAuth().auth});
            onFetch(false);
            return onSuccess(success.data);

        } catch (error) {
            console.log(error);
            onFetch(false);
            return onError(error);
        }
    }
}

export function deletePRESTs(params, ids) {
    return async dispatch => {
        function onSuccess() {
            return dispatch(fetchPRESTs(params));
        }

        onFetch(true);
        try {
            await axios.delete(PREST_BASE_URL, {
                params: ids,
                auth: getAuth().auth
                ,
                paramsSerializer: ids => {
                    return qs.stringify(ids, {arrayFormat: 'repeat'})
                }
            });
            onFetch(false);
            return onSuccess();
        } catch (error) {
            onFetch(false);
            onSuccess();
            return onError(error);
        }
    }
}

export function addPrest(data) {
    return async dispatch => {
        function onSuccess(result) {
            dispatch(fetchPRESTs());
            return result;
        }
        onFetch(true);
        try {
            const result = await axios.post(PREST_BASE_URL, JSON.stringify(data), {
                auth: getAuth().auth,
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            onFetch(false);
            return onSuccess(result);
        } catch (error) {
            onFetch(false);
            onSuccess();
            return onError(error);
        }
    }
}

export function updatePrest(data) {
    return async dispatch => {
        function onSuccess(result) {
            dispatch(fetchPRESTs());
            return result;
        }

        onFetch(true);
        try {
            const result = await axios.put(PREST_BASE_URL, JSON.stringify(data), {
                auth: getAuth().auth,
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            onFetch(false);
            return onSuccess(result);
        } catch (error) {
            onFetch(false);
            onSuccess();
            return onError(error);
        }
    }
}

export function fetchBanks() {
    return async dispatch => {
        function onSuccess(success) {
            dispatch({type: FETCH_BANK_SUCCESS, payload: success});
            return success;
        }

        function onError(error) {
            dispatch({type: ERROR_GENERATED, error});
            return error;
        }

        function onFetch(loading) {
            dispatch({type: LOADING_STATUS_CHANGED, payload: loading});
        }

        onFetch(true);
        try {
            const success = await axios.get(BANK_BASE_URL, {auth: getAuth().auth});
            onFetch(false);
            return onSuccess(success.data);
        } catch (error) {
            onFetch(false);
            return onError(error);
        }
    }

}



export function addBank(data) {
    return async dispatch => {
        function onSuccess(result) {
            dispatch(fetchBanks());
            return result;
        }
        onFetch(true);
        try {
            const result = await axios.post(BANK_BASE_URL, JSON.stringify(data), {
                auth: getAuth().auth,
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            onFetch(false);
            return onSuccess(result);
        } catch (error) {
            onFetch(false);
            onSuccess();
            return onError(error);
        }
    }
}
