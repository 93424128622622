import Grid from "@material-ui/core/Grid";
import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import FormControl from "@material-ui/core/FormControl";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";

export default function PaymentType({handleChange}) {
    const list = useSelector(state => state.payments.types);
    const [types, setTypes] = useState([]);
    const [type, setType] = useState(null);
    useEffect(() => {
        handleChange && handleChange(type);
    },[type])
    useEffect(() => {
        setTypes(list)
    }, [])
    return (
        <Grid container justify={"center"} spacing={3}>
            <Grid item xs={12}>
                <FormControl fullWidth>
                    <Autocomplete
                        fullWidth
                        value={type}
                        onChange={(event, newValue) => {
                            setType(newValue);
                        }}

                        options={types}
                        getOptionLabel={(option) => {
                            return option.name
                        }}
                        renderInput={(params) =>
                            <TextField {...params}
                                       margin={"dense"}
                                       fullWidth
                                       label="Type de paiement"
                                       variant="outlined"
                            />}
                    />
                </FormControl>
            </Grid>
        </Grid>
    )
}