import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import React from "react";
import {changeYear} from "../redux/actions/budgetActions";
import {useDispatch, useSelector} from "react-redux";
import {Grid} from "@material-ui/core";
import YearAddButton from "./YearAddButton";

export default function YearSelectOption({editable}) {
    const dispatch = useDispatch();
    const {year, years} = useSelector(state => state.budgets);
    const handleYearChange = (event) => {
        dispatch(changeYear(event.target.value));
    };

    return (
        <Grid container spacing={2}>
            <Grid item xs={7}>
                <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-helper-label">Année</InputLabel>
                    <Select
                        labelId="demo-simple-select-helper-label"
                        id="annee"
                        name="annee"
                        value={year}
                        onChange={handleYearChange}
                    >
                        {years.map(o => (<MenuItem value={o}>{o}</MenuItem>))}
                    </Select>
                </FormControl>
            </Grid>
            {editable && (<Grid item xs={5}>
                <YearAddButton/>
            </Grid>)}
        </Grid>)
}