import {Paper} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import Button from "@material-ui/core/Button";
import {fetchUsers, updatePassword, updateUser} from "../../redux/actions/userActions";

export default function Profile() {
    const dispatch = useDispatch();
    const {email} = useSelector(state => state.authentication);
    const {users, roles} = useSelector(state => state.admin);
    const [validPass, setValidPass] = useState(false)
    const [state, setState] = useState({
        nom: "", prenom: "", email: "", role: "USER"
    });
    useEffect(() => {
        setValidPass(state.pass === state.pass2 && state.pass?.length > 6)
    }, [state]);
    useEffect(() => {
        dispatch(fetchUsers());
    }, [])
    useEffect(() => {
        setState(users.find(o => o.email === email) || state);
    }, [email, users]);

    const handleChange = (event) => {
        setState({
            ...state, [event.target.name]: event.target.value
        });
    };
    const updateU = () => {
        dispatch(updateUser(email, state));
    }
    const changeP = () => {
        dispatch(updatePassword(email, state.pass));
    }
    return (
        <Paper>
            <h2>Profile</h2>
            <Grid container spacing={3} justify={"flex-end"}>
                <Grid item xs={6} spacing={3}>
                    <FormControl fullWidth>
                        <TextField
                            onChange={handleChange}
                            fullWidth
                            id={"nom"}
                            name={"nom"}
                            label={"Nom"}
                            value={state.nom}
                            variant="outlined"
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={6} spacing={3}>
                    <FormControl fullWidth>
                        <TextField
                            onChange={handleChange}
                            fullWidth
                            id={"prenom"}
                            name={"prenom"}
                            label={"Prenom"}
                            value={state.prenom}
                            variant="outlined"
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={6} spacing={3}>
                    <FormControl fullWidth>
                        <TextField
                            InputProps={{
                                readOnly: true,
                            }}
                            fullWidth
                            id={"email"}
                            name={"email"}
                            label={"Email"}
                            value={state.email}
                            variant="outlined"
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={6} spacing={3}>
                    <FormControl fullWidth>
                        <TextField
                            InputProps={{
                                readOnly: true,
                            }}
                            fullWidth
                            id={"role"}
                            name={"role"}
                            label={"Role"}
                            value={roles.find(x => x.id === state.role).label}
                            variant="outlined"
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={2} spacing={3}>
                    <Button size={"large"} fullWidth variant={"contained"} color={"primary"}
                            onClick={() => updateU()}
                    > Sauvgarder</Button>
                </Grid>
            </Grid>

            <Grid container spacing={3} justify={"flex-end"}>
                <Grid item xs={5} spacing={3}>
                    <FormControl fullWidth>
                        <TextField
                            type="password"
                            onChange={handleChange}
                            fullWidth
                            id={"pass"}
                            name={"pass"}
                            label={"Mot De passe"}
                            value={state.pass}
                            variant="outlined"
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={5} spacing={3}>
                    <FormControl fullWidth>
                        <TextField
                            type="password"
                            onChange={handleChange}
                            fullWidth
                            id={"pass2"}
                            name={"pass2"}
                            label={"Confirmation Mot De passe"}
                            value={state.pass2}
                            variant="outlined"
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={2} spacing={3}>
                    <Button size={"large"} fullWidth variant={"contained"} color={"primary"}
                            onClick={() => changeP()} disabled={!validPass}> Changer</Button>
                </Grid>
            </Grid>
        </Paper>)
}
