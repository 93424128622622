import {
    CREATE_PAYMENT_SUCCESS,
    CREATE_VIRMENT_SUCCESS,
    FETCH_PAYMENT_SUCCESS,
    FETCH_VIRMENT_SUCCESS
} from "../actions/payamentActions";

const initDepense = {
    types: [{
        name: "ORDINAIRE",
        value: "ordinaire",
        id: 1,
        op: true,
        opp: false,
        ov: true,
        ovp: false
    }, {
        id: 2,
        name: "PRÉLÈVEMENT AUTOMATIQUE",
        value: "prelevement",
        op: true,
        opp: false,
        ov: false,
        ovp: false
    }, {
        id: 3,
        name: "PERMANENT",
        value: "permanent",
        op: false,
        opp: true,
        ov: false,
        ovp: true
    }],
    ops: [],
    ovs: []
}
export const paymentReducer = (state = initDepense, action) => {
    switch (action.type) {
        case CREATE_PAYMENT_SUCCESS:
            return {
                ...state,
                ops: [...state.ops, action.payload],
            }
        case     FETCH_VIRMENT_SUCCESS:
            return {...state, ovs: action.payload}
        case FETCH_PAYMENT_SUCCESS: {
            return {...state, ops: action.payload}
        }
        case CREATE_VIRMENT_SUCCESS:
            return {
                ...state,
                ovs: [...state.ovs, action.payload],
            }
        default:
            return state
    }
}