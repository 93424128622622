import {FETCH_USERS_SUCCESS} from "../actions/constants";

const initState = {
    users: [],
    roles: [
        {
            label: "Super adminstrateur",
            id: "SUPER_ADMIN"
        },
        {
            label: "Utilisateur",
            id: "ADMIN"
        },
        {
            label: "Consultant",
            id: "USER"
        }
    ]
};
export const adminReducer = (state = initState, action) => {
    switch (action.type) {
        case FETCH_USERS_SUCCESS:
            return {
                ...state, users: action.payload
            }
        default:
            return state;
    }
};
